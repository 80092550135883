import { Dialog } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import url from "../../../config/axios";
// import EditNotification from "../../../components/NotificationSettingsPage/EditNotificationSettings";
import EditNotification from "../../../components/NotificationSettingsPage/EditNotification-Updated";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "../../../Style.style";
const initialCurrentData = {
  others: {
    displayName: "Others",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "",
      adminSite: "",
    },
    disabled: true,
  },
  register: {
    displayName: "Register",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  },
  orderConfirmation: {
    displayName: "Order Confirmation",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been confirmed",
      adminSite: "Order Confirmed",
    },
  },
  orderPending: {
    displayName: "Order Pending",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order is pending",
      adminSite: "Order Pending",
    },
  },
  orderProcessing: {
    displayName: "Order Processing",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order is being processed",
      adminSite: "Order Processing",
    },
  },
  orderReceived: {
    displayName: "Order Received",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been received",
      adminSite: "Order Received",
    },
  },
  newOrder: {
    displayName: "New Order",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been placed",
      adminSite: "Order placed",
    },
  },
  dealAdded: {
    displayName: "New Deal",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "A new deal placed",
      adminSite: "You placed a new deal",
    },
  },
  dealUpdated: {
    displayName: "Deal Updated",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Deal updated",
      adminSite: "Deal has been updated",
    },
  },
  couponAdded: {
    displayName: "New Coupon",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Coupon added",
      adminSite: "You added a coupon",
    },
  },
  couponUpdated: {
    displayName: "Coupon Updated",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Coupon updated",
      adminSite: "You updated a coupon",
    },
  },
  loyaltyPointsEarned: {
    displayName: "Added Loyalty points",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Loyalty points updated",
      adminSite: "You updated loyalty points",
    },
  },
};

function NotificationSettingsPage({ invokeLogout }) {
  // eslint-disable-next-line
  const [currentData, setCurrentData] = useState({ ...initialCurrentData });
  const [toEdit, setToEdit] = useState(null);
  let AdminData = JSON.parse(localStorage.getItem("Admin"));
  const [outlets, setOutlets] = useState([]);
  const [outletID, setOutletID] = useState("");

  const openEditPopup = (data) => {
    setToEdit(data);
  };
  /**
   * 
   * @param {any } modified modified object Ex: {
    displayName: "Register",
    type: "register",
    mediums: {
      email: true,
      system: false,
      mobile: true,
      sms: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  }
   */
  const closeEditPopup = (modified) => {
    setToEdit(null);
    let toSet = { ...currentData };
    let { type, ...others } = modified ?? {};
    if (!!type) {
      console.log("toSet is", modified);
      toSet[type] = {
        ...others,
      };
    }
    setCurrentData({ ...toSet });
  };
  const [inProgress, setInProgress] = useState(true);
  const getAllSocialLinks = (outletchainid) => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    url
      .get(
        `/v1/general-settings/notification-settings?outletChainID=${
          !AdminData[0].outletChainID ? outletchainid : outletChainID
        }`
      )
      .then((response) => {
        const preferences = response?.data?.data?.preferences ?? {};
        setCurrentData({ ...preferences });
        setInProgress(false);
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  //fetch data
  useEffect(() => {
    if (AdminData[0]?.isSubOutlet) {
      getAllSocialLinks();
    }
  }, []);

  useEffect(() => {
    if (!AdminData[0]?.isSubOutlet) {
      GetAllOutlets();
    }
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));

    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
          localStorage.setItem(
            "outlets",
            JSON.stringify(res.data.data[0]?.outletChainID)
          );
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="col-md-12 newSpace">
      {!AdminData[0].isSubOutlet && (
        <>
          <div className="input-group ">
            <select
              className=" newInputSelect w-100"
              style={{ height: "35px", border: "1px solid #E2E8F0" }}
              onChange={(e) => {
                setOutletID(e.target.value);
                getAllSocialLinks(e.target.value);
              }}
              id="exampleFormControlSelect1"
            >
              <option value="Select">Select Outlet</option>
              {outlets.map((outlet, i) => {
                return (
                  <option value={outlet.outletChainID} key={i}>
                    {outlet.outletName}
                  </option>
                );
              })}
            </select>
          </div>
          {outletID === "" ? (
            <p className="" style={{ color: "red" }}>
              Please select outlet
            </p>
          ) : null}
        </>
      )}

      {inProgress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <table className="table table-striped-cust">
            <thead>
              <tr>
                <th scope="col">Event</th>
                <th scope="col"></th>

                <th scope="col">Type</th>
                <th scope="col"></th>

                <th scope="col">Message</th>
                <th scope="col"></th>

                <th scope="col">Action</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(currentData).map((obj, i) => {
                let data = currentData[obj];
                let notificationTypes = data?.mediums ?? {};
                let allowedNotificationMediums = Object.keys(notificationTypes)
                  .filter((el) => !!notificationTypes[el])
                  .map((obj) => obj[0].toUpperCase() + obj.substring(1));
                console.log(
                  allowedNotificationMediums,
                  "allowed notificaition"
                );
                if (data?.disabled) {
                  return null;
                }
                return (
                  <tr key={i}>
                    <td style={{ color: "#1E293B" }} colSpan={2}>
                      {data.displayName}
                    </td>
                    <td style={{ color: "#1E293B" }} colSpan={2}>
                      {!!allowedNotificationMediums.length
                        ? allowedNotificationMediums.join(", ")
                        : "--"}
                    </td>
                    <td style={{ color: "#1E293B" }} colSpan={2}>
                      {data.messages?.adminSite}
                    </td>
                    <td>
                      <div
                        onClick={() => openEditPopup({ ...data, type: obj })}
                      >
                        <i
                          className="fa fa-pen"
                          style={{ color: colors.accent, paddingRight: "3px" }}
                        ></i>
                        <span style={{ color: colors.accent }}>Edit</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!!toEdit && (
            <Dialog open={true} onClose={closeEditPopup} fullWidth={true}>
              <EditNotification
                onClose={closeEditPopup}
                toEdit={toEdit}
                invokeLogout={invokeLogout}
                outletChainID={outletID}
              />
            </Dialog>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default NotificationSettingsPage;
