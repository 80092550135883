import "./catalog.css";
import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import url from "../../../config/axios";
import blank from "../../../assets/defaultImage.jpg";
import circle from "../../../assets/circlePlus.svg";
import Pagination from "@mui/material/Pagination";
import Editmodal from "../../../components/Inventory/EditModal";
import ViewProductNew from "../../../components/OutletAdminComponents/ViewProduct-Updated";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../../helpers/serializeToQueryStrings";
import CustomTable from "../../../components/CustomTable";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function IndexUpdated() {
  const classes = useStyles();
  const history = useHistory();
  const [catalog, setCatalog] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const admin = JSON.parse(localStorage.getItem("Admin"));
  // console.log(admin.isSubOutlet, "admin")
  const { search } = useLocation();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  var perPage = 10;
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      if (parsed) {
        getAllPaginatedProducts({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  useEffect(() => {
    getAllPaginatedProducts();
  }, []);

  const getAllPaginatedProducts = () => {
    try {
      let queryParams = { ...queryData };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      url
        .get(`/product/paginated/`, {
          params: {
            ...queryParams,
          },
        })
        .then(async (res) => {
          // console.log(res, "paginated");
          setCatalog(res.data.data.products);
          setPaginationData({
            ...paginationData,
            ...res?.data.data?.paginationData,
          });
          setLoading(false);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e, "errror of pagination  ");
    }
  };

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    console.log(propName);
    console.log(propValue);
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/catalog-outlet${queryString}`);
    setQueryData({ ...query });
  };
  const setViewDetails = (product) => {
    setViewModal(!viewModal);
    setSelectedProduct(product);
  };

  const SearchByProduct = (value) => {
    console.log(value, "value search");
    if (value === "") {
      getAllPaginatedProducts();
    } else {
      url
        .get(
          `/product/search/?outletChainID=${admin[0].outletChainID}&name=${value}`
        )
        .then(async (res) => {
          // console.log(res, "response:");
          if (res.data.Message === "Success") {
            setCatalog(res.data.data);
          } else {
            setCatalog([]);
          }
        });
    }
  };

  return (
    <div className="row">
      <CustomTable
        title="Catalog"
        HeaderData={[
          {
            ColumnName: " Name",
            style: {
              paddingLeft: "20px",
            },
          },
          {
            ColumnName: " Price",
            style: {
              textAlign: "center",
            },
          },
          {
            ColumnName: " Brand",
            style: {
              textAlign: "left",
              paddingLeft: "15px",
            },
          },
          {
            ColumnName: " Category",
            style: {
              textAlign: "left",
              justifyContent: "center",
            },
          },
          {
            ColumnName: " Action",
            style: {
              textAlign: "center",
              width: "40%",
            },
          },
        ]}
        search={SearchByProduct}
        Searchlabel="Search catalog"
        RowsStyle={{ justifyContent: "space-evenly" }}
      >
        <tbody>
          {catalog?.map((cat, i) => {
            return (
              <tr key={i}>
                <td colSpan="1">
                  <div
                    className="d-flex"
                    style={{
                      color: "#1E293B",
                      alignItems: "center ",
                      gap: "3px",
                    }}
                  >
                    <img
                      img
                      alt="yo1"
                      src={cat.image ? cat.image : blank}
                      className="img-fluid "
                      width="40"
                      style={{
                        borderRadius: "5px",
                        color: "#1E293B",
                      }}
                    />
                    {cat.name}
                  </div>
                </td>
                <td
                  style={{
                    display: "flex",
                    color: "#1E293B",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "left",
                  }}
                >
                  {cat?.price ? `$ ${cat.price}` : `-`}
                </td>
                <td
                  style={{
                    paddingLeft: "10px",
                    display: "flex",
                    color: "#1E293B",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    textAlign: "left",
                  }}
                >
                  {cat?.brand ? cat.brand.name : "N/A"} <br /> <span></span>
                </td>
                <td
                  style={{
                    display: "flex",
                    color: "#1E293B",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  {cat?.category.length > 0 ? cat?.category[0]?.name : "N/A"}
                </td>

                <td style={{ width: "40%" }}>
                  <div
                    className="d-flex justify-content-center"
                    style={{ gap: "5px", flexWrap: "wrap" }}
                  >
                    {admin[0].isSubOutlet ? (
                      <button
                        className="btn globalButton"
                        onClick={() => {
                          setSelectedProduct(cat);
                          setOpenAddModal(true);
                        }}
                      >
                        <img alt="dashboard" src={circle} /> Add to inventory
                      </button>
                    ) : (
                      <button
                        className="btn CatalogButton"
                        // style={{ width: "70%" }}
                        onClick={() => {
                          console.log("working");
                          setSelectedProduct(cat);
                          setOpenAddModal(true);
                        }}
                      >
                        <img alt="dashboard" src={circle} /> Add to inventory
                      </button>
                    )}
                    <button
                      style={{
                        borderColor: "#FFA382",
                        backgroundColor: "white",
                        color: "#FFA382",
                      }}
                      onClick={() => {
                        setViewDetails(cat);
                        setOpenViewModal(true);
                      }}
                      className="btn btn-transparent CatalogButton"
                    >
                      <i className="fa fa-eye"></i> View Product
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </CustomTable>
      {loading ? null : (
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              shape="square"
              className={classes.root}
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      )}
      {openViewModal && (
        <ViewProductNew
          selectedProduct={selectedProduct}
          setViewModal={setOpenViewModal}
        />
      )}
      {openAddModal && (
        <Editmodal
          closeModal={setOpenAddModal}
          product={selectedProduct}
          catalog={true}
        />
      )}
    </div>
  );
}
