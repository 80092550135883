import React from "react";
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/system";

export default function CustomSelectPrimary({
  label,
  inputLabel,
  options,
  value,
  setValue,
}) {
  const theme = useTheme();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [searchInput, setSearchInput] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState(null);
  React.useEffect(() => {
    if (options) {
      resetFilterItems();
      setLoading(false);
    }
  }, [options]);

  React.useEffect(() => {
    if (!loading) {
      if (filteredItems) {
        if (searchInput !== "") {
          setFilteredItems(
            filteredItems.filter((item) => {
              console.log(item.name.toLowerCase(), "Item");
              if (item.name.toLowerCase().includes(searchInput.toLowerCase())) {
                return { ...item };
              }
            })
          );
        } else {
          resetFilterItems();
        }
      }
    }
  }, [searchInput]);
  const resetFilterItems = () => {
    console.log(options, "Options in components");
    setFilteredItems(options);
  };
  const handleSelect = (option) => {
    if (setValue) {
      console.log(option, "Options are in comp");
      setValue(option);
    }
    setDropdownOpen(false);
  };
  return (
    <Wrapper theme={theme}>
      <label>{label}</label>
      <div className="select-container">
        <div
          className="select-btn"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          {value ?? inputLabel ?? label}{" "}
          <span>
            <ArrowDropDownIcon />
          </span>
        </div>
        {!loading ? (
          <div className={`dropdown-container ${!dropdownOpen && "collapsed"}`}>
            <div className="search-product">
              <input
                type="text"
                placeholder="Search.."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <ul className={`select-options`}>
              {filteredItems
                ? filteredItems.map((option) => (
                    <li
                      key={option.value}
                      className={`option ${
                        option.value === value && "selected"
                      }`}
                      onClick={() => {
                        label === "Country"
                          ? handleSelect(
                              `${option.name + `,` + option.isoCode}`
                            )
                          : label === "State"
                          ? handleSelect(
                              `${
                                option.name +
                                "," +
                                option.isoCode +
                                `,` +
                                option.countryCode
                              }`
                            )
                          : handleSelect(`${option.name}`);
                      }}
                    >
                      {option.name}
                    </li>
                  ))
                : options.map((option) => (
                    <li
                      key={option.value}
                      className={`option ${
                        option.value === value && "selected"
                      }`}
                      onClick={() => {
                        label === "Country"
                          ? handleSelect(
                              `${option.name + `,` + option.isoCode}`
                            )
                          : label === "State"
                          ? handleSelect(
                              `${
                                option.name +
                                "," +
                                option.isoCode +
                                `,` +
                                option.countryCode
                              }`
                            )
                          : handleSelect(`${option.name}`);
                      }}
                    >
                      {option.name}
                    </li>
                  ))}
            </ul>
          </div>
        ) : (
          <div
            className={`loading-container dropdown-container ${
              !dropdownOpen && "collapsed"
            }`}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .select-container {
    position: relative;
    .select-btn {
      position: relative;
      display: block;
      width: 100%;
      height: 50px;
      border: 1px solid #e2e8f0;
      background: transparent;
      font-size: 0.938rem;
      box-sizing: border-box;
      padding: 12px 16px;
      border-radius: 5px;
      cursor: pointer;
      span {
        position: absolute;
        right: 15px;
        top: 60%;
        transform: translateY(-50%);
        svg {
          font-size: 2.5rem;
          color: #747474;
        }
      }
    }
    .loading-container {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown-container {
      position: absolute;
      left: 0px;
      top: 60px;
      background-color: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      z-index: 3;
      width: 100%;
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      transition: max-height 0.3s ease-out;
      /* width */
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b4b4b4a9;
        border-radius: 5px;
        cursor: pointer;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b4b4d5;
        border-radius: 5px;
      }
      .search-product {
        box-sizing: border-box;
        padding: 5px;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #e2e8f0;
          background: transparent;
          font-size: 0.938rem;
          box-sizing: border-box;
          padding: 12px 16px;
          border-radius: 5px;
        }
      }
      .select-options {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0px;
        li {
          width: 100%;
          padding: 12px 16px;
          background-color: white;
          transition: background-color 0.2s ease-out;
          cursor: pointer;
          &:hover {
            background-color: #f7f7f7;
          }
        }
        li.selected {
          background-color: ${(props) => props.theme.palette?.primary?.main};
          color: white;
        }
      }
    }
    .dropdown-container.collapsed {
      max-height: 0px;
    }
  }
`;
