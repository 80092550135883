import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import url from "../../config/axios";
import { useDispatch } from "react-redux";
import {
  getMessageSessionThunk,
  resetMessageSessionsAction,
  selectWholeMessageSessionState,
} from "../../redux/slices/messageSessionSlice";

import SessionSearchModalComponent from "./SessionSearchModal";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
  resetSocketStateAction,
  selectSocketState,
  SOCKET_EVENTS,
  // setSocketAsReconnectAction,
  setSocketAsConnetedAction,
  setSocketAsDisconnectedAction,
} from "../../redux/slices/socketSlice";
import { initializeSocketStateThunk } from "../../redux/slices/socketSlice";
import EmptySessionComponent from "./EmptySession";

const SessionSearchComponent = () => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletID, setOutletID] = useState("");
  const [admin, setAdmin] = useState(false);
  const dispatch = useDispatch();
  const socket = useSelector(selectSocketState);
  const [loaded, setLoaded] = useState(false);

  let AdminData = JSON.parse(localStorage.getItem("Admin"));
  const { sessions, inProgress, error } = useSelector(
    selectWholeMessageSessionState
  );

  useEffect(
    () => {
      if (!AdminData[0]?.isSubOutlet) {
        dispatch(getMessageSessionThunk({ initialAttempt: true }));
        setLoaded(true);

        return () => dispatch(resetMessageSessionsAction());
      }
    },

    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (!AdminData[0]?.isSubOutlet) {
      GetAllOutlets();
    }
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));

    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
          localStorage.setItem(
            "outlets",
            JSON.stringify(res.data.data[0]?.outletChainID)
          );
        }
      })
      .catch((e) => console.log(e));
  };

  // useEffect(() => {
  //   const reloadCount = sessionStorage.getItem("reloadCount");
  //   if (reloadCount < 2) {
  //     sessionStorage.setItem("reloadCount", String(reloadCount + 1));
  //     window.location.reload();
  //   } else {
  //     sessionStorage.removeItem("reloadCount");
  //   }
  // }, []);

  const rejoinSocket = () => {
    dispatch(initializeSocketStateThunk());
  };
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          paddingLeft: "2em",
        }}
      >
        <div className="col-md-8">
          <div
            className="input-group"
            onClick={() => setSearchModalOpen(true)}
            style={{
              width: "50%",
              cursor: "pointer",
            }}
          >
            <input
              type="text"
              className="form-control table-search"
              placeholder="Search by user's name or email"
              aria-label="Input group example"
              aria-describedby="btnGroupAddon"
            />
            <div
              className="input-group-prepend"
              style={{
                cursor: "pointer",
              }}
            >
              <div
                className="input-group-text table-search-icon"
                id="btnGroupAddon"
              >
                <i className="fas fa-search"></i>
              </div>
            </div>
          </div>
        </div>
        {!AdminData[0].isSubOutlet && (
          <div className="col-md-3">
            <div className="input-group ">
              <select
                className=" newInputSelect w-100"
                onChange={(e) => {
                  localStorage.setItem(
                    "outlets",
                    JSON.stringify(e.target.value)
                  );
                  dispatch(getMessageSessionThunk({ initialAttempt: true }));
                  setOutletID(e.target.value);
                  rejoinSocket();
                }}
                id="exampleFormControlSelect1"
              >
                <option value="Select">Select Outlet</option>
                {outlets.map((outlet, i) => {
                  return (
                    <option value={outlet.outletChainID} key={i}>
                      {outlet.outletName}
                    </option>
                  );
                })}
              </select>
            </div>
            {outletID === "" ? (
              <p className="" style={{ color: "red" }}>
                Please select outlet
              </p>
            ) : null}
          </div>
        )}
      </div>

      {!error && !inProgress && !Object.keys(sessions)?.length && loaded && (
        <EmptySessionComponent />
      )}
      {searchModalOpen && (
        <SessionSearchModalComponent
          onClose={() => setSearchModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default SessionSearchComponent;
