import "./OutletChainAdmin.css";
import React, { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import url from "../../config/axios";
import { useEffect } from "react";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Popup from "reactjs-popup";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import Fuse from "fuse.js";
import CustomTable from "../../components/CustomTable";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function Outlets() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [outlet, setOutlets] = useState([]);
  const { search } = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [outletId, setOutletId] = useState("");
  const [searchDataValues, setSearchDataValues] = useState([]);

  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    try {
      ref.current.close();
      if (message.text === "Success") {
        history.push("/outlets");
      }
    } catch (e) {
      //ignore
    }
  };

  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const moveToEdit = (outlet) => {
    localStorage.setItem("outlet", JSON.stringify(outlet));
    history.push("/edit-outlet");
  };
  const GetAllOutlets = async () => {
    try {
      let queryParams = { ...queryData };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      const AllOutlets = await url.get(
        `/oc/all/?consumerId=${adminID[0].consumerId}`,
        {
          params: {
            ...queryParams,
          },
        }
      );
      setOutlets(AllOutlets.data.data.data);
      setPaginationData({
        ...paginationData,
        ...AllOutlets?.data.data?.paginationData,
      });
      setLoading(false);
    } catch (e) {}
  };

  const searchData = (data) => {
    const fuse = new Fuse(outlet, {
      keys: ["outletName", "adminEmail"],
    });
    const result = fuse.search(data);
    const searchData = result.map((item) => item.item);

    setSearchDataValues(searchData);
  };

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    console.log(propName);
    console.log(propValue);
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/outlets${queryString}`);
    setQueryData({ ...query });
  };
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      if (parsed) {
        GetAllOutlets({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );
  useEffect(() => {
    GetAllOutlets();
  }, []);
  const newSpace = {
    margin: "0",
    paddingTop: "2rem",
    height: "100%",
    background: "#fff",
    borderRadius: "5px",
  };
  const deleteOutlet = (outletId) => {
    url
      .delete(`/oc/?outletChainID=${outletId}`)
      .then(async (res) => {
        // console.log(res, 'res')
        if (res.data.Message === "Failure") {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          closeTooltip();
        } else {
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          closeTooltip();
          GetAllOutlets();
        }
      })
      .catch((e) => console.log(e));
  };
  const ToShow = searchDataValues.length > 0 ? searchDataValues : outlet;
  return (
    <div className="row">
      <CustomTable
        button={true}
        title="Outlets"
        ButtonTitle="Add Outlet"
        HeaderData={[
          {
            ColumnName: " Sr. No",
            style: { textAlign: "left", width: "14%" },
          },
          {
            ColumnName: "Name",
            style: {
              textAlign: "left",
              width: "14%",
            },
          },
          {
            ColumnName: "Admin",
            style: {
              textAlign: "left",
              width: "14%",
            },
          },
          {
            ColumnName: "Inventory",
            style: {
              width: "14%",
              textAlign: "left",
            },
          },
          {
            ColumnName: "Created On",
            style: {
              textAlign: "left",
              width: "14%",
            },
          },
          {
            ColumnName: "Status",
            style: { textAlign: "center", width: "14% " },
          },
          {
            ColumnName: "Action",
            style: {
              textAlign: "center",
              width: "14%",
              // marginLeft: "1px",
            },
          },
        ]}
        search={searchData}
        Searchlabel="Search Outlet"
        Buttonfunction={() => history.push("/add-outlet")}
        RowsStyle={{ justifyContent: "space-evenly" }}
      >
        <tbody>
          {ToShow.map((outlet, i) => (
            <tr key={i}>
              <td style={{ width: "14%", textAlign: "left" }}>{i + 1}</td>
              <td style={{ width: "14%", textAlign: "left" }}>
                {outlet.outletName}
              </td>
              <td style={{ width: "14%", textAlign: "left" }}>
                {" "}
                {outlet.adminName} <br /> <span>{outlet.adminEmail}</span>
              </td>
              <td style={{ width: "14%", textAlign: "left" }}>
                {outlet?.inventory ?? "-"}
              </td>
              <td style={{ width: "14%", textAlign: "left" }}>
                {moment(outlet.createdAt).format("D MMM YYYY, h:mm A")}
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "14%",
                }}
              >
                {" "}
                {outlet.isActive ? (
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: "rgba(12, 214, 0, 0.08)",
                      height: "25px",
                      textAlign: "center",
                      borderRadius: "13px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "400",
                        textAlign: "center",
                        fontSize: "13px",
                        paddingTop: "4px",
                        color: "#0CD600",
                      }}
                    >
                      Active
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: " rgba(255, 109, 109, 0.1)",
                      height: "25px",
                      textAlign: "center",
                      borderRadius: "13px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "400",
                        textAlign: "center",
                        fontSize: "13px",
                        paddingTop: "4px",
                        color: "#FF6D6D",
                      }}
                    >
                      Inactive
                    </p>
                  </div>
                )}
              </td>
              <td style={{ width: "14%" }}>
                <div className="d-flex justify-content-center">
                  <i
                    style={{
                      color: "#FFA382",
                      paddingRight: "10px",
                    }}
                    onClick={() => moveToEdit(outlet)}
                    className="fas fa-pen"
                  ></i>
                  <i
                    onClick={() => {
                      setOutletId(outlet.outletChainID);
                      openTooltip();
                    }}
                    className="fas fa-trash deleteIcon"
                  ></i>
                </div>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTable>
      {loading ? null : (
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              shape="square"
              className={classes.root}
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      )}
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this outlet ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteOutlet(outletId);
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
    </div>
  );
}
