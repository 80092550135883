import { useState, useEffect } from "react";
import url from "../../config/axios";
import baseURLStandalone from "../../config/axios";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import blank from "../../assets/defaultImage.jpg";
import { colors } from "../../Style.style";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VariantPage from "../OutletAdminComponents/variants";
import InputPrimary from "../../atoms/InputPrimary/Index2";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OutlinedInput from "@mui/material/OutlinedInput";
import { removeErrors } from "../../helpers/ErrorHandling";
import {
  FormCustom,
  ItemChip,
  ItemCustom,
  SelectCustom,
  FormLabelCustom,
} from "../../atoms/CustomMUISelect";
import axios from "axios";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function EditModal({ closeModal, product, catalog, GetAllInventory }) {
  const theme = useTheme();

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [brand, setBrand] = useState("");
  const [stockQty, setStockQty] = useState(0);
  const [originalPrice, setOriginalPrice] = useState("");
  const [activeStatus, setActiveStatus] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});
  const [track, setTrack] = useState(false);
  const [variantFlow, setVariantFlow] = useState("no-variants");
  const [variants, setVariants] = useState([]);
  const [validation, setValidation] = useState({});
  const [outName, setOutName] = useState([]);
  const [selOutlet, setSelOutlet] = useState([]);

  const admin = JSON.parse(localStorage.getItem("Admin"));
  useEffect(() => {
    GetAllOutlets();
  }, []);
  const selectOnlyOne = async (Value) => {
    try {
      let obj = [];

      if (Value.length === 0) {
        setOutName([]);
        setSelOutlet(obj);
      } else {
        let latesValue = Value.filter((value, i) => {
          let filterThis = value !== outName[i];
          return filterThis;
        });
        setOutName(
          typeof value === "string" ? latesValue.split("split") : latesValue
        );
        var splitName = latesValue[0]?.split("split", 3);
        obj.push({ outletChainID: splitName[0], name: splitName[1] });

        removeErrors("OutErr", validation, setValidation);

        setSelOutlet(obj);
      }
      // console.log(splitName)
    } catch (e) {
      console.log(e, "coming on selectopnly one function");
    }
  };
  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        setOutlets(res.data.data);
      })
      .catch((e) => console.log(e));
  };
  const validateForm = () => {
    let data = { ...validation };
    if (!stockQty) {
      data = { ...data, StockErr: "Quantity is required" };
    } else {
      let { StockErr, ...others } = data;
      data = { ...others };
    }

    if (variantFlow === "variants") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].price === "") {
          data = { ...data, VPErr: "Variant Price is required" };
        } else {
          let { VPErr, ...others } = data;
          data = { ...others };
        }
        if (variants[i].weight === "") {
          data = { ...data, VWErr: "Variant Weight is required" };
        } else {
          let { VWErr, ...others } = data;
          data = { ...others };
        }
      }
    } else if (variantFlow === "no-variants") {
      if (!originalPrice) {
        data = { ...data, PriceErr: "Price is required" };
      } else {
        let { PriceErr, ...others } = data;
        data = { ...others };
      }
    }
    if (!admin[0].isSubOutlet && catalog) {
      if (selOutlet.length !== 0) {
        let { OutErr, ...others } = data;
        data = { ...others };
        setValidation({ ...data });
      } else {
        data = { ...data, OutErr: "Outlet is required" };
        setValidation({ ...data });
      }
    }
    setValidation({ ...data });
    return data;
  };
  const PercentStyle = {
    position: "absolute",
    marginTop: "-35px",
    marginLeft: "5px",
  };
  useEffect(() => {
    console.log(product.productID, "The product is here");
    if (product.product) {
      setStockQty(product.quantity);
      setOriginalPrice(product.originalPrice);
      setName(product.product.name);
      setBrand(product.product.brand[0]?.name);
      setImage(product.product.image);
      setActiveStatus(product.status);
      product.quantity > 0 ? setTrack(true) : setTrack(false);
      if (product?.variants?.length === 0) {
        setVariantFlow("no-variants");
      } else {
        setVariantFlow("variants");
      }
    } else {
      setStockQty(product.quantity);
      setOriginalPrice(product?.price);
      setName(product.name);
      setBrand(!product.brand ? "" : product?.brand[0]?.name);
      setImage(product.image);
      setActiveStatus(product.status);
      product.quantity > 0 ? setTrack(true) : setTrack(false);
      if (!product.variants) {
        setVariantFlow("no-variants");
      } else if (product?.variants?.length === 0) {
        setVariantFlow("no-variants");
      } else {
        setVariantFlow("variants");
      }
    }
  }, [product]);
  const MyRadio = styled((props) => <Radio color="default" {...props} />)({
    "&.Mui-checked": {
      color: "#FFA382",
    },
  });
  const addInventory = () => {
    // console.log(admin, 'admin:')
    const Error = validateForm();
    console.log(Error, "Error");
    if (Object.keys(Error).length === 0) {
      let multichainID = selOutlet[0]?.outletChainID;
      let body = {
        outletChainID: admin[0].isSubOutlet
          ? admin[0].outletChainID
          : multichainID,
        consumerId: admin[0].consumerId,
        productID: product.productID,
        quantity: stockQty,
        originalPrice: variantFlow === "variants" ? 0 : parseInt(originalPrice),
        status: activeStatus,
        variants: variantFlow === "variants" ? variants : [],
      };
      console.log(body, "body");
      url
        .post(`/inventory/`, body)
        .then(async (res) => {
          // console.log(res, 'res')
          updateInventoryStatus();
          if (res.data.Message === "Error") {
            alert(res.data.data);
          } else {
            closeModal(false);
            updateProduct();
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const updateInventoryStatus = () => {
    let multichainID = selOutlet[0]?.outletChainID;
    console.log("MultiChain OutletId: ", multichainID);
    url
      .put(
        `/inventory/?productID=${product.product.productID}&outletChainID=${
          admin[0].isSubOutlet ? admin[0].outletChainID : multichainID
        }`,
        {
          status: activeStatus,
        }
      )
      .then(async (res) => {
        console.log("status updated");
      });
  };

  const updateInventory = () => {
    console.log(product, "This is product data from catalog");
    const Error = validateForm();
    console.log(Error, "Error");
    if (Object.keys(Error).length === 0) {
      let body = {
        outletChainID: product.outletChainID,
        consumerId: product.consumerId,
        productID: product?.product?.productID,
        quantity: stockQty,
        originalPrice: variantFlow === "variants" ? 0 : originalPrice,
        status: activeStatus,
        variants: variantFlow === "variants" ? variants : [],
      };
      // console.log(body, "body");
      url
        .put(
          `/inventory/?productID=${product.product.productID}&outletChainID=${product.outletChainID}`,
          body
        )
        .then(async (res) => {
          updateProduct();
          console.log(res, "res");
          if (res.data.Message === "Error") {
            alert(res.data.data);
          } else {
            GetAllInventory();
            updateInventoryStatus();
            closeModal(false);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const updateProduct = () => {
    const standaloneURL = "https://standalone-backend.staging.bleaum.dev";
    try {
      axios
        .put(`${standaloneURL}/product/?productID=${product.productID}`, {
          ...productDetails,
          variants: variantFlow === "variants" ? variants : [],
          price: variantFlow === "variants" ? 0 : originalPrice,
          quantity: stockQty,
        })
        .then((res) => {
          console.log("Here is my product response: ", res);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const styles = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
    marginTop: "4rem",
    paddingTop: "4px",
    overflowY: "scroll",
    borderRadius: "5px",
  };
  const title = {
    fontSize: "22px",
    fontWeight: "500",
    padding: "1rem 1rem",
  };

  const text1 = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1E293B",
  };

  const text2 = {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1E293B",
  };

  const mark = {
    color: "#898989",
    background: "none",
  };

  const productDetails = {
    backgroundColor: "#E2E8F0",
    borderRadius: "7px",
  };
  const title2 = {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "700",
    color: "#192746",
  };
  const statusStyle = {
    color: "#747474",
    fontWeight: "500",
    fontSize: "14px",
    fontFamily: "Roboto",
    width: "100%",
  };
  const sliderColor = {
    backgroundColor: "#FFA382",
  };
  const modalBody = {
    padding: "1.2em",
  };
  const headingStyle = {
    color: "#1E293B",
    fontWeight: "700",
    fontSize: "20px",
  };
  const modelNewContent = {
    height: "auto",
  };
  const handleVariantChange = (e) => {
    setVariantFlow(e.target.value);
    if (e.target.value === "variants") {
      setVariantFlow("variants");
    } else {
      setVariantFlow("no-variants");
    }
  };

  const handleVariants = (e) => {
    console.log("variants data: ", e);
    setVariants(e);
  };

  return (
    <div className="modal" style={styles}>
      <div className="mdl modal-dialog modal-dialog-centered">
        <div className="modal-content" style={modelNewContent}>
          <div className="modal-header" style={title}>
            <div className="d-flex " style={{ justifyContent: "space-evenly" }}>
              <div
                style={{
                  height: "25px",
                  marginLeft: "-16px",
                  backgroundColor: "#FFA382",
                  marginTop: "5px",
                }}
              >
                <span style={{ color: "#FFA382" }}>.</span>
              </div>
              <span
                style={{
                  marginLeft: "5px",
                  marginTop: "5px",
                  color: "#FFA382",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Edit Inventory
              </span>
            </div>
            <HighlightOffRoundedIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                closeModal(false);
              }}
            />
          </div>
          <div style={modalBody}>
            <div
              className="d-flex justify-content-start p-3"
              style={productDetails}
            >
              <img
                src={image ? image : blank}
                alt=""
                width="40px"
                height="40px"
              />
              <span className="mx-3">
                <p style={headingStyle} className="m-0 p-0">
                  {name}
                </p>
                <p style={text2} className="m-0 p-0">
                  <mark style={mark}>Brand:</mark> {brand ? brand : "N/A"}
                </p>
              </span>
            </div>
            {!admin[0].isSubOutlet && catalog && (
              <div style={{ width: "97%" }}>
                <div className="row">
                  <div className="col-md-12  mt-3">
                    <p
                      className="m-0"
                      style={{
                        color: theme.palette.gray.extraDark,
                        fontWeight: "600",
                      }}
                    >
                      Select Store
                    </p>
                    <div className="">
                      <FormCustom>
                        <SelectCustom
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={outName}
                          onChange={(e) => selectOnlyOne(e.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              style={{ color: "black" }}
                              label="Select Outlet(s)"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <ItemChip
                                  key={value}
                                  label={`${value.split("split")[1]}`}
                                />
                              ))}
                            </Box>
                          )}
                          IconComponent={() => <KeyboardArrowDownIcon />}
                          MenuProps={MenuProps}
                        >
                          {outlets.map((outlet, i) => (
                            <ItemCustom
                              key={i}
                              value={
                                outlet.outletChainID +
                                "split" +
                                outlet.outletName
                              }
                              style={getStyles(outlet, outName, theme)}
                            >
                              {outlet.outletName}
                            </ItemCustom>
                          ))}
                        </SelectCustom>
                      </FormCustom>
                    </div>
                  </div>
                </div>
                <div
                  className="validation-help"
                  style={{
                    marginTop: "3px",
                  }}
                >
                  {validation.OutErr}
                </div>
              </div>
            )}
            <p style={title2} className="my-3">
              Inventory Details
            </p>
            <div className="row">
              <div className="col-md-12">
                <InputPrimary
                  label={"Quantity in Stock"}
                  value={stockQty}
                  pattern="[0-9]*"
                  onInput={(e) => {
                    if (isNaN(e.target.value)) {
                      e.target.value = "";
                    } else {
                      e.target.value = Math.abs(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    setStockQty(e.target.value);
                    // setPhoneNoError("")
                  }}
                />
                {validation.StockErr && (
                  <p
                    className="validation-help"
                    style={{
                      marginTop: "3px",
                    }}
                  >
                    {validation.StockErr}
                  </p>
                )}
              </div>
              <div className="col-md-12 mt-2">
                <span className="solo-chain">Track Inventory? </span>
              </div>
              <div className="col-md-6 switch-col">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={track}
                    onChange={() => setTrack(!track)}
                  />
                  <span
                    className="slider round"
                    style={track ? sliderColor : null}
                  ></span>
                </label>
              </div>
              {!track && (
                <span style={{ color: "red", paddingTop: "13px" }}>
                  Enable track inventory
                </span>
              )}
            </div>
            {track && (
              <div>
                <div>
                  <p style={title2} className="my-3">
                    Billing Details
                  </p>

                  <div>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={variantFlow}
                        name="radio-buttons-group"
                        onChange={(e) => handleVariantChange(e)}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <FormLabelCustom
                            value="variants"
                            control={<MyRadio />}
                            label="Variants"
                          />
                          <FormLabelCustom
                            checked={
                              variantFlow === "no-variants" ? true : false
                            }
                            value="no-variant"
                            control={<MyRadio />}
                            label="No Variant"
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {variantFlow !== "variants" && (
                    <div
                      style={{
                        marginBottom: "1em",
                        width: "100%",
                      }}
                    >
                      <div className="">
                        <InputPrimary
                          label={"Original Price"}
                          type="number"
                          name="price"
                          placeholder="$ 25.00"
                          value={originalPrice}
                          pattern="[0-9]*"
                          onInput={(e) => {
                            if (
                              e.target.value?.toString()?.split(".")[1]
                                ?.length > 2
                            ) {
                              let value =
                                e.target.value.toString().split(".")[0] +
                                "." +
                                e.target.value
                                  .toString()
                                  .split(".")[1]
                                  .substring(0, 2);

                              e.target.value = value;
                            }
                          }}
                          onChange={(e) => {
                            setOriginalPrice(e.target.value);
                          }}
                        />
                      </div>
                      {originalPrice !== "" && originalPrice && (
                        <span style={PercentStyle}>$</span>
                      )}
                      <div className="validation-help">
                        {validation.PriceErr}
                      </div>
                    </div>
                  )}
                  {variantFlow === "variants" && (
                    <VariantPage
                      handleVariants={handleVariants}
                      variantsData={product?.variants}
                      validation={validation}
                    />
                  )}
                  <div className="">
                    <p
                      style={statusStyle}
                      className={variantFlow === "variants" ? "mt-5" : ""}
                    >
                      Status
                    </p>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-md-8 switch-col">
                        <label className="switch  switch-demo">
                          <input
                            type="checkbox"
                            checked={activeStatus}
                            onChange={() => setActiveStatus(!activeStatus)}
                          />
                          <span
                            className="slider round"
                            style={activeStatus ? sliderColor : null}
                          ></span>
                          <span
                            className="slider-text"
                            style={
                              activeStatus
                                ? {
                                    paddingLeft: "81px",
                                    color: "#FFA382",
                                    fontWeight: "500",
                                  }
                                : {
                                    paddingLeft: "81px",
                                    color: theme.palette.gray.dark,
                                    fontWeight: "500",
                                  }
                            }
                          >
                            {activeStatus ? "Show" : "Disabled"}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-end "
                  style={{ gap: "5px" }}
                >
                  <button
                    className="btn  "
                    style={{ width: "15%" }}
                    onClick={() => {
                      closeModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-cannaby"
                    style={{
                      margin: "0",
                      backgroundColor: colors.accent,
                      width: "15%",
                    }}
                    onClick={catalog === true ? addInventory : updateInventory}
                  >
                    {catalog ? "Publish" : "Update"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
