/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import "./deals.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import CustomTable from "../../components/CustomTable";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const [outlets, setOutlets] = useState([]);
  const [deals, setDeals] = useState([]);
  // const [sendDeal, setSendDeal] = useState(null)
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const [selectOutlet, setSelectOutletError] = useState("");
  const [outletID, setOutletID] = useState("");

  var perPage = 10;
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("Outlets", res);
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          console.log("No outlet");
          openNotFoundPopUp();
        }
      })
      .catch((e) => console.log(e));
  };

  //clientSide search
  useEffect(
    () => {
      if (searchTerm?.length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.name.toLowerCase().includes(searchTerm?.trim().toLowerCase())
        );
        setDeals(filtered);
        // if (filtered.length === 0) {
        //   openNotFoundPopUp();
        // }
      } else {
        setDeals(unfiltered);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  const adminID = JSON.parse(localStorage.getItem("Admin"));

  useEffect(
    () => {
      GetAllOutlets();
      if (parsed) {
        GetAllDeals({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/deals${queryString}`);
    setQueryData({ ...query });
  };

  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllDeals();
    } else {
      ref.current.close();
    }
  };
  const [dealId, setDealId] = useState(null);
  const deleteDeal = () => {
    url.delete(`/deal/?dealId=${dealId}`).then(async (res) => {
      // console.log(res, 'Coupon Deleted')
      if (res.data.Message === "Success") {
        // openTooltip();
        closeTooltip(true);
      } else {
        alert(res.data.data);
      }
    });
  };
  // adminID[0].isSubOutlet
  const GetAllDeals = async () => {
    if (adminID[0].isSubOutlet) {
      url
        .get(
          `/v1/deals/paginated-for-admin?consumerId=${
            adminID[0].consumerId
          }${`&outletChainID=${adminID[0].outletChainID}`}`
        )
        .then(async (res) => {
          if (res.data.Message === "Success") {
            let modified = res.data.data?.data?.reverse() ?? [];
            setUnfiltered(modified);
            setDeals(modified);
            setPaginationData({
              ...paginationData,
              ...res?.data?.data?.paginationData,
            });
            setLoading(false);
          } else {
            openNotFoundPopUp();
            setDeals([]);
          }
        })
        .catch((e) => console.log(e));
    } else {
      try {
        url
          .get(`/v1/deals/all/?consumerId=${adminID[0].consumerId}`)
          .then(async (res) => {
            console.log("Deals Response: ", res);
            if (res.data.Message === "Success") {
              let modified = res.data?.data?.data.reverse() ?? [];
              setUnfiltered(modified);
              setDeals(modified);
              setPaginationData({
                ...paginationData,
                ...res.data.data?.paginationData,
              });
              setLoading(false);
            } else {
              console.log("Inelse clause");
              openNotFoundPopUp();
            }
          })
          .catch((e) => console.log(e));
      } catch (e) {
        console.log(e, "error");
      }
    }
  };
  function backgroundColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "rgb(251, 255, 194)";
        break;
      case "New":
        result = "rgba(108, 149, 255, 0.1)";
        break;
      case "Disabled":
        result = "rgba(255, 109, 109, 0.08)";
        break;
      case "Delivered":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Enabled":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Dispatched":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "#FFC700";
        break;
      case "New":
        result = "blue";
        break;
      case "Disabled":
        result = "#FF6D6D";
        break;
      case "Enabled":
        result = "#0CD600";
        break;
      case "Delivered":
        result = "#0CD600";
        break;
      case "Dispatched":
        result = "#0CD600";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  const editDeal = (data) => {
    localStorage.setItem("dealEdit", JSON.stringify(data));
    history.push("/edit-deal");
  };
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const GetDealsByOutlet = (outletId) => {
    console.log(outletId, "outletId");
    setOutletID(outletId);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    if (outletId !== "Select") {
      url
        .get(
          `/v1/deals/paginated-for-admin?consumerId=${
            adminID[0].consumerId
          }${`&outletChainID=${outletId}`}`
        )
        .then(async (res) => {
          if (res.data.Message === "Success") {
            let modified = res.data.data?.data?.reverse() ?? [];
            setUnfiltered(modified);
            setDeals(modified);
            setPaginationData({
              ...paginationData,
              ...res?.data?.data?.paginationData,
            });
            setLoading(false);
          } else {
            openNotFoundPopUp();
            setDeals([]);
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSelectOutletError("");
      GetAllDeals();
    }
  };
  const Buttonfunction = () => {
    history.push("/add-deal");
  };
  return (
    <div className="row">
      {" "}
      <div className="col-md-12">
        <CustomTable
          title="Deals"
          HeaderData={[
            {
              ColumnName: "Name",
              style: {
                textAlign: "left",
              },
            },
            {
              ColumnName: "Applicable to",
              style: {
                textAlign: "left",
              },
            },
            {
              ColumnName: "Value",
              style: {
                textAlign: "left",
              },
            },
            {
              ColumnName: "Expires On",
              style: {
                textAlign: "left",
              },
            },
            {
              ColumnName: "Status",
              style: {
                textAlign: "center",
              },
            },
            {
              ColumnName: "Action",
              style: {
                textAlign: "center",
              },
            },
          ]}
          outlets={outlets}
          Searchlabel="Search deal"
          search={setSearchTerm}
          GetDataByOutlet={GetDealsByOutlet}
          outletID={outletID}
          button={true}
          ButtonTitle="Add Deal"
          Buttonfunction={Buttonfunction}
          RowsStyle={{ justifyContent: "space-around" }}
          Alloutlets={true}
        >
          <tbody>
            {deals.length !== 0 ? (
              deals
                .slice((currentPage - 1) * perPage, currentPage * perPage)
                .map((deal, i) => {
                  //   console.log(coupon, 'coupon:');
                  return (
                    <tr key={i}>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {" "}
                          {deal.image ? (
                            <img
                              img
                              alt="yo1"
                              src={deal.image}
                              className="img-fluid "
                              width="40"
                              style={{ height: "40px" }}
                            />
                          ) : (
                            <div
                              className="image-thumbnail-div"
                              style={{
                                backgroundColor: assignColor(),
                                width: "40px",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                            >
                              <span>{deal?.name?.substring(0, 1)}</span>
                            </div>
                          )}
                          <span
                            style={{ paddingTop: "10px", color: "#1E293B" }}
                          >
                            {deal.name}
                          </span>
                        </div>
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <div
                          style={{
                            width: "150px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {!!deal.selectedCategories?.length ? (
                            <span
                              style={{ paddingTop: "10px", color: "#1E293B" }}
                            >
                              Selected Categories
                            </span>
                          ) : (
                            <span
                              style={{ paddingTop: "10px", color: "#1E293B" }}
                            >
                              Selected Products
                            </span>
                          )}
                        </div>
                      </td>
                      <td
                        style={{
                          paddingTop: "10px",
                          color: "#1E293B",
                          textAlign: "left",
                        }}
                      >
                        {deal.discountValue} %
                      </td>
                      <td
                        style={{
                          paddingTop: "10px",
                          color: "#1E293B",
                          textAlign: "left",
                        }}
                      >
                        {!!deal.neverExpires
                          ? "Never Expires"
                          : moment(deal.endDate).format("D MMM YYYY, h:mm A")}
                      </td>

                      <td style={{ paddingTop: "10px" }}>
                        <div
                          className="newClass"
                          id="deals"
                          style={{
                            backgroundColor: `${backgroundColor(
                              deal.status ? "Enabled" : "Disabled"
                            )} `,
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                              fontSize: "13px",
                              paddingTop: "4px",
                              color: checkColor(
                                deal.status ? "Enabled" : "Disabled"
                              ),
                            }}
                          >
                            {deal.status ? "Enabled" : "Disabled"}{" "}
                          </p>
                        </div>
                      </td>
                      <td
                        className="d-flex justify-content-center"
                        style={{ paddingTop: "10px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1em",
                            paddingTop: "5px",
                          }}
                        >
                          <i
                            className="fas fa-pen orangeColor"
                            onClick={() => {
                              editDeal(deal);
                            }}
                          ></i>
                          {/* onClick={() => moveToEdit(product)} */}
                          <i
                            className="fa fa-trash-alt"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              setDealId(deal.dealId);
                              openTooltip();
                            }}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <>
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    No deals found
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </CustomTable>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                shape="square"
                className={classes.root}
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <h4 className="popup-heading">Do you want to delete this deal ?</h4>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteDeal();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "green",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Deals not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
