import React from "react";
import "./AddInventory.css";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import blank from "../../assets/defaultImage.jpg";
import { useTheme } from "@emotion/react";
import Up from "../../assets/Up.svg";
import Down from "../../assets/Down.svg";
import InputPrimary from "../../atoms/InputPrimary/Index2";
export default function ViewProductNew({ setViewModal, selectedProduct }) {
  const styles = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
    marginTop: "4rem",
    paddingTop: "4px",
    overflowY: "scroll",
  };
  const detailStyles = {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px",
  };
  const effectStyle = {
    display: "flex",
    justifyContent: "flex-start",
    gap: "0.8rem",
    flexWrap: "wrap",
  };

  let num = 0;
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const firstLetterStyle = {
    height: "154px",
    width: "100%",
    marginBottom: "auto",
    borderRadius: "5px",
  };
  console.log(selectedProduct, "Selecteed product");
  const theme = useTheme();
  return (
    <div className="modal" style={styles}>
      <div className="mdl modal-dialog-centered">
        <div className="modal-content">
          <div className=" row">
            <div className="col-md-12">
              {" "}
              <div className="container">
                <div className="row" style={{ marginRight: "0px" }}>
                  <div
                    style={{
                      height: "25px",
                      marginLeft: "0px",
                      backgroundColor: theme.palette.primary.main,
                      marginTop: "10px",
                    }}
                  >
                    <span style={{ color: theme.palette.primary.main }}>.</span>
                  </div>
                  <div className={"col-md-3 d-flex justify-content-start"}>
                    <div
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "20px",
                        fontWeight: "600",
                        marginTop: "7px",
                      }}
                    >
                      Product Details
                    </div>
                  </div>
                  <div className="col-md-8 d-flex justify-content-end">
                    <HighlightOffRoundedIcon
                      style={{
                        cursor: "pointer",
                        marginTop: "7px",
                        marginRight: "-65px",
                      }}
                      onClick={() => setViewModal(false)}
                    />
                  </div>
                  {/* <div className="col-md-1">
                 
                </div> */}
                </div>
              </div>
              <hr />
            </div>
            <div className="modal-body">
              {/* Styling the product Box */}
              <div
                className="row"
                style={{
                  background: "#F4F7FC",
                  height: "210px",
                  borderRadius: "5px",
                }}
              >
                <div
                  className="col-md-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  {selectedProduct.image ? (
                    <div
                      style={{
                        height: "60px !important",
                        width: "100%",
                        marginBottom: "auto",
                      }}
                    >
                      <img
                        style={{ width: "90%" }}
                        alt=""
                        src={
                          selectedProduct.image ? selectedProduct.image : blank
                        }
                      />
                    </div>
                  ) : (
                    <div
                      className="image-thumbnail-div"
                      style={{
                        ...firstLetterStyle,
                        backgroundColor: assignColor(),
                      }}
                    >
                      <span
                        style={{
                          fontSize: "3rem",
                        }}
                      >
                        {selectedProduct?.name?.substring(0, 1)}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className="col-md-9"
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "22px",
                      color: theme.palette.gray.extraDark,
                      fontWeight: "600",
                    }}
                  >
                    {selectedProduct.name}
                  </span>
                  <span
                    className="marginBottom-Detail"
                    style={{
                      fontSize: "22px",
                      color: theme.palette.gray.extraDark,
                      fontWeight: "600",
                    }}
                  >
                    {selectedProduct.price}
                  </span>
                  <div
                    style={{
                      color: "#FFA382",
                      fontWeight: "600",
                      fontSize: "18px",
                      fontFamily: "'Roboto'",
                    }}
                  >
                    {selectedProduct.price
                      ? ` $ ${selectedProduct.price}`
                      : null}
                  </div>
                  <div className="marginBottom-Detail">
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#747474",
                        fontWeight: "400",
                      }}
                    >
                      Brand:{" "}
                      <span style={{ color: theme.palette.gray.extraDark }}>
                        {selectedProduct?.brandProduct
                          ? selectedProduct?.brandProduct
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p
                      className="marginBottom-Detail"
                      style={{
                        color: "#747474",
                        fontWeight: "400",
                      }}
                    >
                      Categories:{" "}
                      <span style={{ color: theme.palette.gray.extraDark }}>
                        {selectedProduct?.categoryProduct
                          ? selectedProduct?.categoryProduct
                          : "0%"}
                      </span>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "109px",
                      }}
                    >
                      <img
                        style={{
                          height: "15px",
                          marginTop: "10px",
                          marginRight: "7px",
                        }}
                        src={Down}
                      />
                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "500",
                          fontSize: "23px",
                        }}
                      >
                        {selectedProduct?.thc
                          ? selectedProduct?.thc + "%"
                          : "0%"}
                      </p>
                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "500",
                          fontSize: "22px",
                          marginTop: "0.1em",
                        }}
                      >
                        TBH
                      </p>
                    </div>
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "109px",
                      }}
                    >
                      <img
                        style={{
                          height: "15px",
                          marginTop: "10px",
                          marginRight: "7px",
                        }}
                        src={Up}
                      />

                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "500",
                          fontSize: "23px",
                        }}
                      >
                        {selectedProduct?.cbd
                          ? selectedProduct?.cbd + "%"
                          : "0%"}
                      </p>
                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "500",
                          fontSize: "22px",
                          marginTop: "0.1em",
                        }}
                      >
                        CBD{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Description and everything is below here */}
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "25px",
                  }}
                >
                  Short Description
                </h2>
                <p>
                  {selectedProduct?.shortDescription
                    ? selectedProduct?.shortDescription
                    : "N/A"}
                </p>
              </div>
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "25px",
                  }}
                >
                  Long Description
                </h2>
                <p>
                  {selectedProduct?.longDescription
                    ? selectedProduct?.longDescription
                    : "N/A"}
                </p>
              </div>
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "25px",
                  }}
                >
                  Packaging Details
                </h2>
                <div style={detailStyles}>
                  <div
                    style={{
                      width: "14.5rem",
                    }}
                  >
                    <InputPrimary
                      label={"Height"}
                      value={selectedProduct.height ?? 0}
                      pattern="[0-9]*"
                      onInput={(e) => {
                        if (isNaN(e.target.value)) {
                          e.target.value = "";
                        } else {
                          e.target.value = Math.abs(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "14.5rem",
                    }}
                  >
                    <InputPrimary
                      label={"Width"}
                      value={selectedProduct.width ?? 0}
                      pattern="[0-9]*"
                      onInput={(e) => {
                        if (isNaN(e.target.value)) {
                          e.target.value = "";
                        } else {
                          e.target.value = Math.abs(e.target.value);
                        }
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "14.5rem",
                    }}
                  >
                    <InputPrimary
                      label={"Weight"}
                      value={selectedProduct.weight ?? 0}
                      pattern="[0-9]*"
                      onInput={(e) => {
                        if (isNaN(e.target.value)) {
                          e.target.value = "";
                        } else {
                          e.target.value = Math.abs(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "15px",
                  }}
                >
                  Effects
                </h2>
                <div style={effectStyle}>
                  {selectedProduct.effects.length !== 0 ? (
                    selectedProduct.effects.map((i) => {
                      return (
                        <>
                          {" "}
                          <button
                            style={{
                              borderColor: theme.palette.primary.main,
                              backgroundColor: "white",
                              color: theme.palette.primary.main,
                              marginRight: "10px",
                            }}
                            className="btn btn-transparent"
                          >
                            {i}
                          </button>
                        </>
                      );
                    })
                  ) : (
                    <p>No Effects yet</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
