import styled from "@emotion/styled";
import React from "react";
import "./style.css";
import { colors } from "../../Style.style";
import { useHistory } from "react-router-dom";

const ContentContainer = ({ heading, button, route, children }) => {
  const history = useHistory();

  const Container = styled.div`
    padding-top: 0.05px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 8px;
    .headingRow {
      display: flex;
      justify-content: space-between;

      .heading-label {
        margin: 20px;
        margin-top: 30px;
        position: relative;
        font-size: 1.1rem;
        font-weight: bold;
        color: ${colors.accent};
        &::before {
          content: "";
          position: absolute;
          left: -20px;
          top: 0px;
          height: 100%;
          width: 5px;
          background-color: ${colors.accent};
        }
        &::after {
          content: "";
          position: absolute;
        }
      }
      button {
        margin-top: 20px;
        margin-right: 10px;
        background-color: #ffa382;
      }
    }

    hr {
      width: 100%;
      height: 1px;
      background-color: "#F1F5F9";
    }
    .content {
      padding: 20px;
    }
  `;
  return (
    <Container className="content-container">
      <div className="headingRow">
        <h1 className="heading-label">{heading}</h1>
        {button && (
          <button
            className="btn btn-primary border-0 "
            style={{
              height: "37px",
            }}
            onClick={() => {
              history.push(route);
            }}
          >
            <i className="fas fa-arrow-left"></i>
            Back
          </button>
        )}
      </div>

      <hr />
      <div className="content">{children}</div>
    </Container>
  );
};

export default React.memo(ContentContainer);
