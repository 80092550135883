import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/system";
const VariantPage = ({ handleVariants, variantsData, validation }) => {
  const theme = useTheme();

  const [inputFields, setInputFields] = useState([
    { uom: "g", price: "", weight: "" },
  ]);
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    console.log("Dynamic data: ", data);
    setInputFields(data);
    handleVariants(data);
    console.log("New field: ", inputFields);
  };

  useEffect(() => {
    if (variantsData?.length !== 0 && !!variantsData) {
      setInputFields(variantsData);
      handleVariants(variantsData);
    } else {
      handleVariants(inputFields);
    }
  }, []);

  const addFields = (e) => {
    e.preventDefault();
    let newfield = { uom: "g", price: "", weight: "" };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  return (
    <form
      style={{
        width: "100%",
        marginTop: "-23px",
      }}
    >
      {inputFields.map((input, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <div>
                <DeleteIcon
                  style={{
                    float: "right",
                    cursor: "pointer",
                    marginRight: "0.5em",
                    marginTop: "0.5em",
                    color: "#ff4848a3",
                  }}
                  onClick={() => removeFields(index)}
                />
              </div>
            )}

            <br />
            <div
              style={
                inputFields.length > 1
                  ? {
                      display: "flex",
                      marginTop: "1em",
                      paddingBottom: "1.5em",
                      borderBottom: "1px solid #E2E8F0",
                    }
                  : {
                      display: "flex",
                      marginTop: "1em",
                      paddingBottom: "1.0em",
                    }
              }
            >
              <div className="col-md-4">
                <label className="labelStyle">Variant Weight</label>
                <div className="form-group">
                  <input
                    type="number"
                    name="weight"
                    placeholder="Weight"
                    value={input.weight}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control"
                    pattern="[0-9]*"
                    id="exampleFormControlInput1"
                    onInput={(e) => {
                      if (
                        e.target.value?.toString()?.split(".")[1]?.length > 2
                      ) {
                        let value =
                          e.target.value.toString().split(".")[0] +
                          "." +
                          e.target.value
                            .toString()
                            .split(".")[1]
                            .substring(0, 2);

                        e.target.value = value;
                      }
                    }}
                  />
                </div>
                {inputFields[index].weight === "" && validation.VWErr && (
                  <p
                    style={{
                      color: theme.palette.action.danger,
                      marginTop: "5px",
                    }}
                  >
                    {validation.VWErr}
                  </p>
                )}
              </div>
              <div className="col-md-4">
                <label className="labelStyle">Variant Price</label>

                <div className="form-group">
                  <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={input.price}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control"
                    pattern="[0-9]*"
                    id="exampleFormControlInput1"
                    onInput={(e) => {
                      if (
                        e.target.value?.toString()?.split(".")[1]?.length > 2
                      ) {
                        let value =
                          e.target.value.toString().split(".")[0] +
                          "." +
                          e.target.value
                            .toString()
                            .split(".")[1]
                            .substring(0, 2);

                        e.target.value = value;
                      }
                    }}
                  />
                </div>
                {inputFields[index].price === "" && validation.VPErr && (
                  <p
                    style={{
                      color: theme.palette.action.danger,
                      marginTop: "5px",
                    }}
                  >
                    {validation.VPErr}
                  </p>
                )}
              </div>
              <div className="col-md-4">
                <label className="labelStyle"> Unit</label>

                <div className="form-group">
                  <input
                    type="text"
                    name="uom"
                    placeholder="uom"
                    value={input.uom}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control"
                    id="exampleFormControlInput1"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="variation_button"
        style={{
          float: "left",
          marginLeft: "1em",
          marginBottom: "0.5em",
        }}
      >
        <button
          onClick={addFields}
          style={{
            background: "none",
            border: "none",
            textDecoration: "underline",
            color: "#FFA382",
            outline: "none",
            cursor: "pointer",
          }}
        >
          {" "}
          <AddCircleIcon
            style={{
              position: "relative",
              top: "0.2em",
              right: "0.3em",
              fontSize: "1.7em",
            }}
          />{" "}
          Add Variant
        </button>
      </div>
    </form>
  );
};

export default VariantPage;
