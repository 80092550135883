import { useEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMessagesthunk,
  selectCurrentMessages,
  selectCurrentSessionInfo,
  selectCurrentSessionMeta,
} from "../../redux/slices/chatSessionSlice";
import ClientChatComponent from "./ClientChat";
import HostChatComponent from "./HostChat";
// import moment from "moment/moment";
import ChatLoaderComponent from "./ChatLoader";
import { Button } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import MessageTopic from "./MessageTopic";

const MessagesHolder = () => {
  const dispatch = useDispatch();
  const messages = useSelector(selectCurrentMessages);
  const currentSession = useSelector(selectCurrentSessionInfo);
  const { loading, hasMore } = useSelector(selectCurrentSessionMeta);
  // let datesGroup = {};
  // function resetDatesGroup() {
  //   datesGroup = {};
  // }
  // function getDateGroupToRender(date) {
  //   if (new Date(date).toString() !== "Invalid Date") {
  //     let formattedDate = moment(new Date(date).getTime()).format(
  //       "ddd, DD MMMM"
  //     );

  //     if (!datesGroup[formattedDate]) {
  //       datesGroup = { ...datesGroup, [formattedDate]: 1 };
  //       return formattedDate;
  //     }
  //   }
  //   return null;
  // }
  useEffect(
    () => {
      dispatch(fetchMessagesthunk(true));
    },
    //eslint-disable-next-line
    [currentSession]
  );
  // useEffect(
  //   () => {
  //     resetDatesGroup();
  //   },
  //   //eslint-disable-next-line
  //   [messages]
  // );
  return (
    <div
      className="custom-scrollbar"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column-reverse",
        flexGrow: 1,
        height: "55vh",
        width: "100%",
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      {Object.keys(messages).map((obj, index) => {
        const message = messages[obj];
        const content =
          message?.contentGroup[0]?.content ?? "Corrupted message";
        const time = message?.createdAt;
        // const dateFieldToRender = getDateGroupToRender(time);

        return (
          <Fragment key={index}>
            {/* to do for grouping messages */}
            {/* {dateFieldToRender && null} */}
            {message?.regardingTopicChange ? (
              <Fragment>
                {/* <MessageTopic topic={message?.topicInfo} /> */}
              </Fragment>
            ) : (
              <Fragment>
                {!!message?.fromAdmin ? (
                  <HostChatComponent content={content} time={time} />
                ) : (
                  <ClientChatComponent content={content} time={time} />
                )}
              </Fragment>
            )}
          </Fragment>
        );
      })}
      {hasMore && !loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
            }}
            endIcon={<ArrowUpward />}
            onClick={() => dispatch(fetchMessagesthunk(false))}
          >
            See more
          </Button>
        </div>
      )}
      {loading && <ChatLoaderComponent />}
    </div>
  );
};

export default MessagesHolder;
