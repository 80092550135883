/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import AddLoyaltypoints from "../../components/AddLoyalty/Addloyalty";
import { useHistory } from "react-router-dom";
import url from "../../config/axios";
import Popup from "../../components/Popup/index";

function Loyalty() {
  const ref = useRef();
  const history = useHistory();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  let AdminData = JSON.parse(localStorage.getItem("Admin"));
  const [message, setMessage] = useState({
    status: true,
    Message: "",
    action: "",
  });
  const [outlets, setOutlets] = useState([]);
  const [outletID, setOutletID] = useState("");
  const [formValidationErrors, setFormvalidationErrors] = useState({});
  const [days, setDays] = useState({
    MON: [],
    TUES: [],
    WED: [],
    THURS: [],
    FRI: [],
    SAT: [],
    SUN: [],
  });

  console.log(days, "Days");
  const Name = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  // Setting the days that are being passed from child to parent
  const setNewDays = (value, key) => {
    let tempDays = { ...days };
    tempDays[key] = value;
    setDays(tempDays);
  };
  // Api calls to be made in this file
  // <----------!!!!!------->
  // Get loyalty api
  useEffect(() => {
    if (AdminData[0].isSubOutlet) {
      getLoyalty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getLoyalty = async (outletChainID) => {
    try {
      const getLoyaltyPoint = await url.get(
        `/v1/loyalty?outletChainID=${outletChainID}`,
        {
          slots: days,
        }
      );

      let data = getLoyaltyPoint.data.data.loyalty.slots;
      console.log(
        getLoyaltyPoint.data.data.loyalty,
        "Data of getting from api"
      );
      setDays({ ...data });
    } catch (e) {
      console.log(e);
    }
  };
  // Update and add loyaltypoint api
  const addUpdateLoyalty = async () => {
    try {
      const addUpdate = await url.put(
        `/v1/loyalty/update-slots?outletChainID=${outletID}`,
        {
          slots: days,
        }
      );

      let data = addUpdate.data.data.loyalty.slots;
      setDays({ ...data });
      setMessage({
        status: true,
        Message: "Updated Successfully",
        action: "Loyaltypoints updated",
      });
      openTooltip();
      setFormvalidationErrors({});
      getLoyalty();
    } catch (e) {
      setMessage({
        status: false,
        Message: "Something went wrong",
        action: "Please enter correct details",
      });
      openTooltip();
      if (e?.response?.status === 422) {
        let data = { ...formValidationErrors };
        // Object.values
        if ("body.slots.MON" in e?.response?.data?.data) {
          data = {
            ...data,
            mon: "Invalid or conflicting time Slots in Monday",
          };
        } else {
          const { mon, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.TUES" in e?.response?.data?.data) {
          data = {
            ...data,
            tues: "Invalid or conflicting time Slots in Tuesday",
          };
        } else {
          const { tues, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.WED" in e?.response?.data?.data) {
          data = {
            ...data,
            wed: "Invalid or conflicting time Slots in Wednesday",
          };
        } else {
          const { wed, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.THURS" in e?.response?.data?.data) {
          data = {
            ...data,
            thurs: "Invalid or conflicting time Slots in Thurday",
          };
        } else {
          const { thurs, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.FRI" in e?.response?.data?.data) {
          data = { ...data, fri: "Invalid or conflictin time Slots in Friday" };
        } else {
          const { fri, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.SAT" in e?.response?.data?.data) {
          data = {
            ...data,
            sat: "Invalid or conflictin time Slots in Saturday",
          };
        } else {
          const { sat, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.SUN" in e?.response?.data?.data) {
          data = { ...data, sun: "Invalid or conflictin time Slots in Sunday" };
        } else {
          const { sun, ...others } = data;
          data = { ...others };
        }
        setFormvalidationErrors({ ...data });
        return data;
      }
      console.log(e?.response?.data?.data, "Error here");
    }
  };

  useEffect(() => {
    if (!AdminData[0]?.isSubOutlet) {
      GetAllOutlets();
    }
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));

    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
          localStorage.setItem(
            "outlets",
            JSON.stringify(res.data.data[0]?.outletChainID)
          );
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card ">
          <div
            className="row"
            style={{
              borderBottom: "2px solid #F1F5F9",
              paddingBottom: "10px",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
          >
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "2px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-5">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Loyalty points
                  </div>
                </div>
                <div className="col-md-5 ">
                  {!AdminData[0].isSubOutlet && (
                    <>
                      <div className="input-group ">
                        <select
                          className=" newInputSelect w-100"
                          style={{
                            height: "35px",
                            border: "1px solid #E2E8F0",
                          }}
                          onChange={(e) => {
                            localStorage.setItem(
                              "outlets",
                              JSON.stringify(e.target.value)
                            );
                            setOutletID(e.target.value);
                            getLoyalty(e.target.value);
                          }}
                          id="exampleFormControlSelect1"
                        >
                          <option value="Select">Select an outlet</option>
                          {outlets.map((outlet, i) => {
                            return (
                              <option value={outlet.outletChainID} key={i}>
                                {outlet.outletName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {outletID === "" ? (
                        <p className="" style={{ color: "red" }}>
                          Please select outlet
                        </p>
                      ) : null}
                    </>
                  )}
                </div>

                <div className="col-md-1 ">
                  <button
                    onClick={() => history.push("/Specializedloyalty")}
                    className="btn btn-primary border-0"
                    style={{ backgroundColor: "#FFA382" }}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Points
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Next component here */}
          <div
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderRadius: "2px",
              borderBottom: "2px solid #F1F5F9",
              paddingBottom: "10px",
              margin: "0",
              padding: "2rem",
              height: "100%",
              background: "#fff",
              backdropFilter: " blur(10px)",
            }}
          >
            {Object.keys(days).map((item, key) => {
              console.log(item);
              return (
                <AddLoyaltypoints
                  daySlots={days[item]}
                  days={item}
                  Name={Name}
                  index={key}
                  onChange={setNewDays}
                  validation={formValidationErrors}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="d-flex col-md-12"
        style={{
          justifyContent: "flex-end",
          marginTop: "5px",
          marginLeft: "11px",
        }}
      >
        <div style={{ borderRadius: "5px", padding: "6px" }}>
          {!AdminData[0].isSubOutlet ? (
            <button
              className="btn btn-primary border-0"
              style={{ backgroundColor: "#FFA382" }}
              onClick={() => {
                addUpdateLoyalty();
              }}
              disabled={outletID === "" ? true : false}
            >
              Update
            </button>
          ) : (
            <button
              className="btn btn-primary border-0"
              style={{ backgroundColor: "#FFA382" }}
              onClick={() => {
                addUpdateLoyalty();
              }}
            >
              Update
            </button>
          )}
        </div>
      </div>
      <Popup
        ref={ref}
        closeTooltip={closeTooltip}
        status={message.status}
        Message={message.Message}
        action={message.action}
      />
    </div>
  );
}

export default Loyalty;
