import { useState, useEffect, Fragment } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import InputPrimary from "../../atoms/InputPrimary/Index2";
import InputAreaPrimary from "../../atoms/InputAreaPrimary/Index";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox, CircularProgress, Dialog } from "@mui/material";

import url from "../../config/axios";
const initialFormData = {
  title: "",
  viaEmail: false,
  viaSms: false,
  viaMobile: false,
  viaSystem: false,
  highlights: "",
};

function SendNotificationModal({
  onClose,
  refData = { ...initialFormData },
  invokeLogout,
  headerTitle,
  notificationId,
}) {
  const handleClose = (isUpdated = false, data = null) => {
    onClose(isUpdated, data);
  };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [inProgress, setInProgress] = useState(false);
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);

  useEffect(
    () => {
      setFormData({
        ...initialFormData,
        ...refData,
      });
    },
    //eslint-disable-next-line
    []
  );

  const handleChangeFromEvent = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  /**
   *
   * @param {string} propName property to change
   * @param { any } value corresponding value
   */
  const handleChange = (propName, value) => {
    setFormData({ ...formData, [propName]: value });
  };

  const [formValidationErrors, setFormValidationsErrors] = useState({});
  const [triedBefore, setTriedBefore] = useState(false);
  const validateForm = () => {
    let data = { ...formValidationErrors };

    //manipulate title
    if (formData.title?.trim().length === 0) {
      data = {
        ...data,
        titleError: "Title of the notification event is required",
      };
    } else {
      let { titleError, ...others } = data;
      data = { ...others };
    }

    //manipulate highlights
    if (formData.highlights?.trim().length === 0) {
      data = {
        ...data,
        hError: "Highlights of the notification event is required",
      };
    } else {
      let { hError, ...others } = data;
      data = { ...others };
    }

    setFormValidationsErrors({ ...data });
    return data;
  };

  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );

  const updateNotficationModule = () => {
    setTriedBefore(true);
    let errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setInProgress(true);
      const body = {
        ...formData,
      };
      // console.log("Body is : ", body);
      url
        .put(
          `/v1/notifications/upsert-admin-notification${
            notificationId ? "?notificationId=" + notificationId : ""
          }`,
          body
        )
        .then((res) => {
          setInProgress(false);
          // console.log("Res is: ", res?.data?.data?.data);
          handleClose(true, res?.data?.data?.data ?? {});
        })
        .catch((e) => {
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
          console.error(e?.response);
          setInProgress(false);
        });
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={() => {
        if (!inProgress) {
          handleClose();
        }
      }}
    >
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
        }}
      >
        <div
          className="row"
          style={{
            padding: "1em 1.5em",
            height: "auto",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "25px",
              marginLeft: "-9px",
              backgroundColor: "#FFA382",
              marginTop: "4px",
            }}
          >
            <span style={{ color: "#FFA382" }}>.</span>
          </div>
          <div
            className="ml-2"
            style={{ color: "#FFA382", fontSize: "20px", fontWeight: "600" }}
          >
            {!notificationId ? "Compose Notification" : "Edit Notification"}
          </div>
          <CloseIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              if (!inProgress) {
                handleClose(false);
              }
            }}
          />
        </div>
        <div
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <div>
            <InputPrimary
              label="Title"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChangeFromEvent}
              placeholder="Title"
            />
            {formValidationErrors.titleError ? (
              <span className="validation-help">
                {formValidationErrors.titleError}
              </span>
            ) : (
              ""
            )}
          </div>

          <div style={{ marginTop: "5px" }}>
            <InputAreaPrimary
              label="Notification Message"
              type="text"
              name="highlights"
              rows={4}
              onChange={handleChangeFromEvent}
              placeholder="Write here"
              value={formData.highlights}
            />

            {formValidationErrors.hError ? (
              <span className="validation-help">
                {formValidationErrors.hError}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="group-comb" style={{ marginTop: "4px" }}>
            <div style={{ padding: "4px 13px" }}>
              <div className="labelStyle" style={{ marginLeft: "-5px" }}>
                Notification Type
              </div>

              <div
                className="row"
                style={{ alignItems: "center", marginTop: "0.5em" }}
              >
                <Checkbox
                  checked={formData.viaMobile}
                  value={formData.viaMobile}
                  onChange={() =>
                    handleChange("viaMobile", !formData.viaMobile)
                  }
                />
                <span>Mobile</span>
              </div>

              <div className="row" style={{ alignItems: "center" }}>
                <Checkbox
                  checked={formData.viaSystem}
                  value={formData.viaSystem}
                  onChange={() =>
                    handleChange("viaSystem", !formData.viaSystem)
                  }
                />
                <span>System</span>
              </div>
            </div>
            {formValidationErrors.checkError ? (
              <span className="validation-help">
                {formValidationErrors.checkError}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1em 1.5em",
            height: "auto",
            alignItems: "center",
          }}
        >
          {!isMultiChainAdmin && (
            <button
              style={{
                backgroundColor: "#FFA382",
                padding: "1em 4em",
                cursor: "pointer",
                border: "none",
                fontWeight: "500",
                borderRadius: "10px",
                color: "white",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                gap: "1em",
              }}
              onClick={() => updateNotficationModule()}
            >
              {inProgress && (
                <CircularProgress style={{ color: "white" }} size={20} />
              )}
              {inProgress
                ? "In progress..."
                : `${!notificationId ? "Send" : "Update and Resend"}`}
            </button>
          )}
          {isMultiChainAdmin && (
            <Fragment>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <Fragment>
                    <button
                      {...bindTrigger(popupState)}
                      style={{
                        backgroundColor: "#FFA382",
                        padding: "1em 4em",
                        cursor: "pointer",
                        border: "none",
                        fontWeight: "bold",
                        borderRadius: "10px",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1em",
                      }}
                    >
                      {inProgress && (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={20}
                        />
                      )}
                      {inProgress
                        ? "In progress..."
                        : `${!notificationId ? "Send" : "Update and Resend"}`}
                    </button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          updateNotficationModule();
                        }}
                      >
                        On Behalf of SoleChain Admin
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          updateNotficationModule(false);
                        }}
                      >
                        As MultiChain Admin
                      </MenuItem>
                    </Menu>
                  </Fragment>
                )}
              </PopupState>
            </Fragment>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default SendNotificationModal;
