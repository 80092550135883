import * as React from "react";
import MuiMenu from "@mui/material/Menu";
import { styled as muiStyled } from "@mui/material/styles";
import DatePicker from "../DatePicker/DatePicker";
import { IconButton } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import moment from "moment";

/**
  anchorEl<HtmlDomElement>: Anchor of the button/any html element where the menu popup will appeaer
  menuItems<Object[]>: [
    label: Menu Item Button Label,
    icon: Icon of the menu item,
    _rightLabel: secondary right label,
    onClick: Onclick function of the button
  ]
 */
const DateRangePopup = ({
  label,
  anchorEl,
  setAnchorEl,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  sortByTime,
  setSortByTime,
  customFormat,
}) => {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFrom = (value) => {
    const finalVal = customFormat
      ? moment(value, "ddd MMM DD yyyy HH:mm:ss").format(customFormat)
      : value;
    if (setFromDate) setFromDate(finalVal);
  };
  const handleTo = (value) => {
    const finalVal = customFormat
      ? moment(value, "ddd MMM DD yyyy HH:mm:ss").format(customFormat)
      : value;
    if (setFromDate) setToDate(finalVal);
  };
  const handleSortByTime = () => {
    if (setSortByTime) setSortByTime(sortByTime * -1);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <label>{label}</label>
      <div class="sort-direction">
        <IconButton onClick={handleSortByTime}>
          <SwapVertIcon />
        </IconButton>
      </div>
      <div className="input">
        <DatePicker
          label="From"
          value={
            customFormat
              ? moment(fromDate, customFormat).format(
                  "ddd MMM DD yyyy HH:mm:ss"
                )
              : fromDate
          }
          onChange={handleFrom}
        />
        <DatePicker
          label="To"
          value={
            customFormat
              ? moment(toDate, customFormat).format("ddd MMM DD yyyy HH:mm:ss")
              : toDate
          }
          onChange={handleTo}
        />
      </div>
    </Menu>
  );
};
const Menu = muiStyled(MuiMenu)(({ theme }) => ({
  "& label": {
    fontSize: "1.2rem",
    color: theme.palette.secondary.main,
  },
  "& .MuiList-root": {
    boxShadow: "-5px 4px 10px rgba(0, 0, 0, 0.11)",
    padding: "15px",
    position: "relative",
  },
  "& .sort-direction": {
    position: "absolute",
    right: "15px",
    top: "15px",
  },
  "& .input": {
    display: "flex",
    gap: "10px",
  },
}));
export default DateRangePopup;
