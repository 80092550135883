import url from "../../config/axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Popup from "reactjs-popup";
import "./privacy.css";
export default function Privacy() {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [Body, setBody] = useState("");
  const [created, setCreated] = useState("");
  // const [outletId, setOutletId] = useState("");
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();

  const queryParams = new URLSearchParams(window.location.search);
  let pageName = queryParams.get("id");

  const admin = JSON.parse(localStorage.getItem("Admin"));

  const addPage = () => {
    console.log("Check");
    // console.log(admin[0].isSubOutlet);
    let body = {
      pageName: pageName,
      consumerId: admin[0].consumerId,
      body: Body,
      title: title,
      subtitle: subtitle,
    };

    url
      .put(`/page?consumerId=${admin[0].consumerId}&page=${pageName}`, body)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          openTooltip();
        } else {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          openTooltip();
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    function getPage() {
      console.log(admin[0].consumerId, pageName);
      url
        .get(`/page/id?consumerId=${admin[0].consumerId}&page=${pageName}`)
        .then(async (res) => {
          if (res.data.Message === "Success") {
            if (res.data.data.length === 0) {
              console.log(res.data.data, "DATAS");
              setTitle("");
              setSubtitle("");
              setBody("");
            } else {
              console.log(res.data.data.updatedAt, "Data of page");
              setTitle(res.data.data.title);
              setSubtitle(res.data.data.subtitle);
              setBody(res.data.data.body);
              setCreated(res.data.data.updatedAt);
            }
          }
        });
    }
    getPage();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-md-12">
          <div className="card card-pro" id="nopad">
            <div className="row" style={{ borderRadius: "7px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="row"
                      style={{
                        borderBottom: "2px solid #F1F5F9",
                        paddingBottom: "4px",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          marginLeft: "0px",
                          backgroundColor: "#FFA382",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ color: "#FFA382" }}>.</span>
                      </div>
                      <div className="col-md-4 d-flex">
                        <div
                          style={{
                            color: "#FFA382",
                            fontSize: "20px",
                            paddingTop: "10px",
                          }}
                        >
                          {pageName === "terms"
                            ? "Terms and condition"
                            : "Privacy Policy"}
                        </div>
                      </div>
                      <div className="col-md-6 filters-bar">
                        <div className="dropdown"></div>
                        <div className="dropdown">
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <a className="dropdown-item" href="/">
                              Action
                            </a>
                            <a className="dropdown-item" href="/">
                              Another action
                            </a>
                            <a className="dropdown-item" href="/">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-1  d-flex"
                        style={{
                          paddingTop: "10px",
                        }}
                      >
                        <button
                          className="btn btn-primary border-0 "
                          style={{
                            backgroundColor: "#FFA382",
                            marginBottom: "5px",
                            marginLeft: "60px",
                            alignSelf: "center",
                          }}
                          onClick={() => {
                            history.push("/page");
                          }}
                        >
                          <i className="fas fa-arrow-left"></i>
                          Back
                        </button>
                      </div>
                    </div>

                    <div className="group-comb" style={{ marginTop: "2em" }}>
                      <div style={{ borderRadius: "2px" }}>
                        <label>Title </label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="group-comb">
                      <div style={{ borderRadius: "2px" }}>
                        <label>Subtitle </label>
                        <input
                          type="text"
                          value={subtitle}
                          onChange={(e) => setSubtitle(e.target.value)}
                          className="form-control"
                          placeholder="Subtitle"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="group-comb">
                      <label>Body </label>

                      <div
                        style={{
                          borderRadius: "4px",
                          background: "white",
                          margin: "0px",
                          border: "1px solid #c6c6c6",
                          padding: "5px 15px",
                        }}
                      >
                        <div>
                          <CKEditor
                            row={"20"}
                            editor={ClassicEditor}
                            data={Body}
                            onReady={(editor) => {
                              editor.editing.view.change((writer) => {
                                writer.setStyle(
                                  "height",
                                  "400px",
                                  editor.editing.view.document.getRoot()
                                );
                              });
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setBody(data);
                              // console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                            config={{
                              toolbarLocation: "bottom",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "flex-end",
                        paddingBottom: "4px",
                        marginTop: "-8px",
                      }}
                    >
                      <button
                        type="button"
                        onClick={addPage}
                        className="btn btn-cannaby"
                        style={{ backgroundColor: " #FFA382" }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "green",
                fontSize: "70px",
              }}
              // style={{
              //   color: "green",
              //   fontSize: "60px",
              //   border: "10px solid green",
              //   borderRadius: "50px",
              //   padding: "10px",
              // }}
              class="fa fa-check"
            ></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Page updated successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "red",
                fontSize: "70px",
              }}
              className="fa fa-ban"
            ></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary"
            >
              Try Again
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
