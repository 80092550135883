/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { addDays, subDays } from "date-fns";
import { useHistory } from "react-router-dom";
import { differenceInHours } from "date-fns/esm";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import MultipleSelectPrimary from "../../atoms/MultipleSelectPrimary/index.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";
import PopUp from "../../components/Popup";
import {
  FormCustom,
  ItemChip,
  ItemCustom,
  SelectCustom,
} from "../../atoms/CustomMUISelect";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddDeal() {
  const history = useHistory();
  const [activeStatus, setActiveStatus] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [dealName, setDealName] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [selOutlet, setSelOutlet] = useState([]);
  const [selProduct, setSelProduct] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [appliesTo, setAppliesTo] = useState("Selected Products");
  const [neverExpires, setNeverExpires] = useState(false);
  const [specificDates, setSpecificDates] = useState(true);
  const theme = useTheme();
  const [inProgress, setInProgress] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [triedBefore, setTriedbefore] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [outName, setOutName] = useState([]);
  const [outletID, setOutletID] = useState("");
  const [options, setOptions] = useState(null);
  const [optionCat, setOptionCat] = useState(null);
  const [errorDeal, setDealError] = useState("Something went wrong");
  const [message, setMessage] = useState({
    status: true,
    Message: "",
    action: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [validationMessages, setvalidationMessages] = React.useState({
    name: "",
    discountValue: "",
  });
  const DealDetail = {
    color: "#FFA382",
    fontSize: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingLeft: "5px",
    fontWeight: "600",
  };
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (message.status === true) {
      history.push("/deals");
    } else {
      ref.current.close();
    }
  };
  useEffect(() => {
    GetAllOutlets();
    GetAllProducts();
    if (adminID[0].isSubOutlet) {
      GetAllCategories();
    } else {
      //
    }
  }, []);

  const handleChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleChange1 = (newValue) => {
    setEndDate(newValue);
  };

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("All outlets", res.data);
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    if (outlets?.length === 0) {
      GetAllOutlets();
    }
    if (outletID) {
      setSelCategory([]);
      setSelProduct([]);
      setProducts([]);
      setCategories([]);
      setSelProduct([]);
      setSelCategory([]);
      GetAllProducts();
    }
    if (!!adminID[0].isSubOutlet && !outletID) {
      setOutletID(adminID[0]?.outletChainID);
    }
  }, [outletID]);
  useEffect(() => {
    GetAllProducts();
  }, [selOutlet]);
  const GetAllProducts = () => {
    if (!adminID[0].isSubOutlet) {
      url
        .get(`/inventory/?outletChainID=${selOutlet[0]?.outletChainID}`)
        .then(async (res) => {
          console.log("Products", res.data.data);
          if (res.data.Message === "Success") {
            setProducts(res.data.data.slice(0, 100));
          } else {
            // alert(res.data.data);
            setProducts([]);
          }
        });
      GetCatetgoriesByOutlet();
    } else {
      url
        .get(`/inventory/?outletChainID=${adminID[0].outletChainID}`)
        .then(async (res) => {
          console.log("Products", res.data.data);
          if (res.data.Message === "Success") {
            //   setProducts(res.data.data.slice(0, 100));
            setProducts(res.data.data.slice(0, 100));
          } else {
            alert(res.data.data);
            setProducts([]);
          }
        });
    }
  };
  const GetCatetgoriesByOutlet = () => {
    console.log("Outlet ID", selOutlet);
    let catArr = [];
    for (let i = 0; i < selOutlet.length; i++) {
      url
        .get(
          `/v2/admin/categories/specific-outlet-categories?${`OutletId=${selOutlet[i].outletChainID}`}`
        )
        .then(async (res) => {
          console.log("Categories", res?.data?.toSend?.data?.categories);
          if (res.data.toSend.Message === "Success") {
            catArr.push(res?.data?.toSend?.data?.categories);
            setCategories(...catArr);
          } else {
            // alert(res.data.data);
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const GetAllCategories = () => {
    url
      .get(
        `/v2/admin/categories/specific-outlet-categories?${`OutletId=${adminID[0].outletChainID}`}`
      )
      .then(async (res) => {
        console.log("Categories", res.data.data);
        if (res.data.toSend.Message === "Success") {
          setCategories(res?.data?.toSend?.data?.categories);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const fileUpload = () => {
    setTriedbefore(true);
    let errors = validateForm() ?? {};
    if (Object.keys(errors).length === 0) {
      console.log("Here");
      setInProgress(true);
      try {
        addDeal("");
        // }
      } catch (e) {
        console.log("error", e);
        addDeal("");
      }
    }
  };

  const selectedOutlets = async (outl) => {
    setOutName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? outl.split("split") : outl
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < outl.length; i++) {
      var splitted = outl[i].split("split", 3);
      obj.push({ outletChainID: splitted[0], name: splitted[1] });
      setSelOutlet(obj);
    }
    // console.log(effect, 'effects');
  };

  const addDeal = (blob) => {
    console.log("Deal");
    if (dealName === "") {
      setvalidationMessages({ name: "Deal name is required" });
    } else if (discountValue === "") {
      setvalidationMessages({ discountValue: "Discount value is required" });
    } else {
      const admin = JSON.parse(localStorage.getItem("Admin"));
      console.log(!adminID[0].isSubOutlet);
      const selectedOne = adminID[0].isSubOutlet
        ? [
            {
              outletChainID: adminID[0].outletChainID,
              name: adminID[0].outletName,
            },
          ]
        : selOutlet;
      let body = {
        consumerId: admin[0].consumerId,
        name: dealName,
        image: blob,
        selectedOutlets: selectedOne,
        discountValue: discountValue,
        selectedProducts: appliesTo === "Selected Products" ? selProduct : [],
        selectedCategories: !(appliesTo === "Selected Products")
          ? selCategory
          : [],
        applyToAllOutlets: applyToAll,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        neverExpires: neverExpires,
        status: activeStatus,
      };
      //sanity
      Object.keys(body).forEach((el) => {
        if (!el) {
          delete body[el];
        }
      });
      // console.log(selectedOne, "body");
      url
        .patch(`/v1/deals`, body)
        .then(async (res) => {
          // console.log(res, 'deal add:')
          if (res.data.Message === "Success") {
            setMessage({
              status: true,
              Message: "Created Successfully",
              action: "Deals created",
            });
            openTooltip();
          } else {
            setMessage({
              status: false,
              Message: "Something went wrong",
              action: "Enter correct details",
            });
            openTooltip();
          }
        })
        .catch((e) => {
          console.log(e.repsonse);
          setDealError(e?.response?.data?.data?.message);
          setMessage({
            status: false,
            Message: "Something went wrong",
            action: errorDeal,
          });
          openTooltip();
        });
      setInProgress(false);
    }
  };
  const [dateRelatedErrors, setDateRelatedErrors] = useState({});
  useEffect(() => {
    // console.log("**********Incoming", { startDate, endDate });
    let data = { ...dateRelatedErrors };
    //manipualte start date
    if (new Date(startDate).toString() === "Invalid Date") {
      data = { ...data, start: "Invalid date" };
    } else if (new Date(startDate) < subDays(new Date(), 1)) {
      data = { ...data, start: "Must be a date today or in the future" };
    } else if (new Date(startDate) > new Date(endDate)) {
      data = { ...data, start: "Happening after the date of being end" };
    } else {
      const { start, ...others } = data;
      data = { ...others };
    }
    //manipulate end date
    if (new Date(endDate).toString() === "Invalid Date") {
      data = { ...data, end: "Invalid date" };
    } else if (new Date(startDate) > new Date(endDate)) {
      data = { ...data, end: "Happening before the date of being started" };
    } else if (differenceInHours(new Date(endDate), new Date()) < 1) {
      data = { ...data, end: "Must end in future (After an hour at least)" };
    } else {
      let { end, ...others } = data;
      data = { ...others };
    }
    // console.log("*************Manipulated date is: ", data);
    setDateRelatedErrors({ ...data });
  }, [startDate, endDate]);

  const [formValidationErrors, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (dealName.trim().length === 0) {
      data = { ...data, dealError: "Name of the deal is required" };
    } else {
      let { dealError, ...others } = data;
      data = { ...others };
    }
    //validate discount value: discountError
    if (discountValue.trim().length === 0) {
      data = { ...data, discountError: "Discount value is required" };
    } else if (discountValue <= 0 || discountValue >= 100) {
      data = {
        ...data,
        discountError: "Please a choose a postive value within 100",
      };
    } else {
      let { discountError, ...others } = data;
      data = { ...others };
    }
    if (appliesTo === "Selected Products") {
      console.log(selProduct);
      if (selProduct.length === 0) {
        data = { ...data, selectError: "Select at least one product" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    } else {
      if (selCategory.length === 0) {
        data = { ...data, selectError: "Select at least one category" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    }
    setFormValidationErrors({ ...data });
    return data;
  };
  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
  }, [
    discountValue,
    dealName,
    selCategory,
    selProduct,
    appliesTo,
    triedBefore,
  ]);
  useEffect(() => {
    if (products.length > 0) {
      setOptions(
        products.map((item) => ({
          value: item.productID,
          label: item.product.name,
        }))
      );
    }
  }, [products]);
  useEffect(() => {
    if (categories.length > 0) {
      setOptionCat(
        categories.map((item) => ({
          value: item.categoryID,
          label: item.name,
        }))
      );
    }
  }, [categories]);

  // For there products
  useEffect(() => {
    if (selected.length > 0) {
      setSelProduct(
        selected.map((item) => ({
          productID: item.value,
          name: item.label,
        }))
      );
    }
  }, [selected]);
  // For the selecting Categories
  useEffect(() => {
    if (selectedCategories.length > 0) {
      setSelCategory(
        selectedCategories.map((item) => ({
          categoryID: item.value,
          name: item.label,
        }))
      );
    }
  }, [selectedCategories]);
  return (
    <div>
      <div className="row" style={{ borderRadius: "13px" }}>
        <div className="col-md-12">
          <div className="card" id="nopad1">
            <div className="col-md-12">
              <form className="row">
                <div className="col-md-12">
                  <div
                    className="row d-flex justify-content-between"
                    style={{
                      borderBottom: "2px solid #F1F5F9",
                      paddingBottom: "10px",
                    }}
                  >
                    <div className="d-flex jusitfy-content-between">
                      <div
                        style={{
                          height: "25px",
                          marginLeft: "-2px",
                          backgroundColor: "#FFA382",
                          marginTop: "10px ",
                        }}
                      >
                        <span style={{ color: "#FFA382" }}>.</span>
                      </div>
                      {/* <div className="d-flex justify-content-between w-75 border"> */}
                      <div
                        className="d-flex"
                        style={{ width: "100px", marginLeft: "10px" }}
                      >
                        <div
                          style={{
                            color: "#FFA382",
                            fontSize: "17px",
                            marginTop: "10px",
                            fontWeight: "600",
                          }}
                        >
                          Add Deals
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-7"> </div>
                    <div className="col-md-1  d-flex justify-content-end ml-auto">
                      <button
                        className="btn btn-primary border-0 "
                        style={{
                          backgroundColor: "#FFA382",
                          marginBottom: "5px",
                          height: "37px",
                        }}
                        onClick={() => {
                          history.push("/Special-Event");
                        }}
                      >
                        <i className="fas fa-arrow-left"></i>
                        Back
                      </button>
                    </div>
                    {/* </div> */}
                    <br></br>
                  </div>
                </div>

                {!adminID[0].isSubOutlet && (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 group-comb mt-3">
                        <div className="">
                          <p
                            className="m-0"
                            style={{
                              color: theme.palette.gray.dark,
                              fontWeight: "600",
                            }}
                          >
                            Select Store
                          </p>
                          <FormCustom>
                            <SelectCustom
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                              multiple
                              value={outName}
                              onChange={(e) => selectedOutlets(e.target.value)}
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  style={{ color: "black" }}
                                  label="Select Outlet(s)"
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => (
                                    <ItemChip
                                      key={value}
                                      label={`${value.split("split")[1]}`}
                                    />
                                  ))}
                                </Box>
                              )}
                              IconComponent={() => <KeyboardArrowDownIcon />}
                              MenuProps={MenuProps}
                            >
                              {outlets.map((outlet, i) => (
                                <ItemCustom
                                  key={i}
                                  value={
                                    outlet.outletChainID +
                                    "split" +
                                    outlet.outletName
                                  }
                                  style={getStyles(outlet, outName, theme)}
                                >
                                  {outlet.outletName}
                                </ItemCustom>
                              ))}
                            </SelectCustom>
                          </FormCustom>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12" style={{ paddingTop: "5px" }}>
                  <div style={DealDetail}>Deal Details</div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 group-comb">
                      <label className="labelStyle">Deal Name</label>
                      <div>
                        <input
                          type="text"
                          value={dealName}
                          onChange={(e) => setDealName(e.target.value)}
                          className="form-control borderStyle"
                          placeholder="Deal Name"
                        />
                        {formValidationErrors.dealError && (
                          <span className="emsg">
                            {formValidationErrors.dealError}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 group-comb">
                      <label className="labelStyle">Discount value</label>
                      <div>
                        <input
                          type={"number"}
                          value={discountValue}
                          onKeyPress={(e) => {
                            var charCode =
                              typeof e.which == "undefined"
                                ? e.keyCode
                                : e.which;
                            var charStr = String.fromCharCode(charCode);

                            if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
                          }}
                          onChange={(e) => {
                            let val = parseInt(Math.abs(e.target.value));
                            if (val < 100) {
                              setDiscountValue(val + "");
                            } else {
                              setDiscountValue("");
                            }
                          }}
                          className="form-control borderStyle"
                          id="exampleFormControlInpu1"
                          placeholder="10 %"
                          min="0"
                          max="99"
                        />

                        {discountValue !== "" ? (
                          <span
                            style={{
                              position: "absolute",
                              top: "31px",
                              left: `${
                                Math.pow((discountValue + "").length, 3) + 40
                              }px`,
                            }}
                          >
                            %
                          </span>
                        ) : (
                          ""
                        )}
                        {formValidationErrors.discountError && (
                          <span className="emsg">
                            {formValidationErrors.discountError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    {/* <label className="labelStyle">Applies to</label> */}

                    <div>
                      <CustomSelectPrimary
                        label="Applies To"
                        options={[
                          {
                            label: "Select Products",
                            value: "Selected Products",
                          },
                          {
                            label: "Select Categories",
                            value: "Selected Categories",
                          },
                        ]}
                        value={appliesTo}
                        setValue={setAppliesTo}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <div>
                      {appliesTo === "Selected Products" && (
                        <MultipleSelectPrimary
                          label="Pick Products"
                          inputLabel="Select Products"
                          options={options}
                          setSelected={setSelected}
                          selected={selected}
                        />
                      )}

                      {appliesTo === "Selected Categories" && (
                        <MultipleSelectPrimary
                          label="Pick Categories"
                          options={optionCat}
                          selected={selectedCategories}
                          setSelected={setSelectedCategories}
                        />
                      )}
                      {/* <input type="text" value={outletName} onChange={(e) => setOutletName(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name" /> */}
                      {formValidationErrors.selectError && (
                        <span className="emsg">
                          {formValidationErrors.selectError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <h5
                    style={{
                      color: "#FFA382",
                      fontSize: "15px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      paddingLeft: "5px",
                    }}
                  >
                    Validity
                  </h5>
                  <br />
                </div>

                <div className="col-md-12 validity-form">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      checked={neverExpires}
                      onChange={(e) => {
                        setNeverExpires(!neverExpires);
                        setSpecificDates(neverExpires);
                      }}
                      className="form-check-input "
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label checkmark"
                      for="exampleCheck1"
                    >
                      Never Expires
                    </label>
                  </div>
                  <div className="form-check sds">
                    <input
                      type="checkbox"
                      checked={specificDates}
                      onChange={(e) => {
                        setSpecificDates(!specificDates);
                        setNeverExpires(specificDates);
                      }}
                      className="form-check-input"
                      id="exampleCheck2"
                    />
                    <label
                      className="form-check-label checkmark"
                      for="exampleCheck2"
                    >
                      Specific Dates
                    </label>
                  </div>
                </div>

                {neverExpires ? (
                  ""
                ) : (
                  <div className="col-md-12 date-start-end">
                    {!neverExpires ? (
                      <div className="row data-time-parent">
                        <div className="col-md-6">
                          <div className="group-comb">
                            <label className="labelStyle">Start Date</label>
                            <div
                              className="form-group borderStyle"
                              style={{ borderRadius: "0px !important" }}
                            >
                              <div className="row">
                                <div className="col-md-12">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DateTimePicker
                                      style={{ width: "100%" }}
                                      value={startDate}
                                      onChange={handleChange}
                                      minDate={new Date()}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={startDate}
                                          onChange={(e) =>
                                            handleChange(
                                              new Date(e.target.value)
                                            )
                                          }
                                          error={!!dateRelatedErrors.start}
                                          helperText={dateRelatedErrors.start}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="group-comb">
                            <label className="labelStyle">End Date</label>
                            <div className="form-group borderStyle">
                              <div className="row">
                                <div className="col-md-12">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DateTimePicker
                                      style={{ width: "100%" }}
                                      value={endDate}
                                      onChange={handleChange1}
                                      minDate={addDays(startDate, 1)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={endDate}
                                          onChange={(e) =>
                                            handleChange1(e.target.value)
                                          }
                                          error={!!dateRelatedErrors.end}
                                          helperText={dateRelatedErrors.end}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
                <div className="col-md-8">
                  <br />
                  <h5 className="orangeColor" style={{ fontSize: "15px" }}>
                    Status
                  </h5>
                </div>
                <div className="col-md-8 switch-col">
                  <label className="switch  switch-demo">
                    <input
                      type="checkbox"
                      checked={activeStatus}
                      onChange={() => setActiveStatus(!activeStatus)}
                    />
                    <span className="slider round"></span>
                    <span
                      className="slider-text"
                      style={
                        activeStatus
                          ? { paddingLeft: "81px", color: "#FFA382" }
                          : { paddingLeft: "81px", color: "#7C7C7C" }
                      }
                    >
                      {activeStatus ? "Enabled" : "Disabled"}
                    </span>
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-end border-0">
            <button
              type="button"
              onClick={() => fileUpload()}
              style={{ backgroundColor: "#FFA382" }}
              disabled={
                (Object.keys(dateRelatedErrors).length !== 0 &&
                  !neverExpires) ||
                inProgress
              }
              className="btn  btn-cannaby"
            >
              {inProgress ? "Creating..." : "Create Deal"}
            </button>
          </div>
        </div>
      </div>
      <PopUp
        ref={ref}
        closeTooltip={closeTooltip}
        status={message.status}
        Message={message.Message}
        action={message.action}
      />
    </div>
  );
}
