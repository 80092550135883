/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import bin from "../../assets/bin.svg";
import blank from "../../assets/defaultImage.jpg";
import Dollar from "../../assets/Dollar.svg";
import people from "../../assets/people.svg";
import truck from "../../assets/truck.svg";
import url from "../../config/axios";
import Graphs from "../../assets/Graph.png";
import Chart from "chart.js/auto";
import "./dashboard.css";
import {
  getLineDataFromGenericDateData,
  getTotalOccurencesFromLineData,
  getBarDataFromGenericDateData,
} from "./helpers/get-line-data.helpers";

export default function Index() {
  const [totalRegistrations, setTotalRegistrations] = useState("");
  const [totalOrders, setTotalOrders] = useState("");
  const [totalRevenue, setTotalRevenue] = useState("");
  const [totalProducts, setTotalProducts] = useState("");
  const [productsByRevenue, setProductsByRevenue] = useState([]);
  const [productsByCount, setProductsByCount] = useState([]);
  useEffect(() => {
    getDashboardData();
  }, []);

  const [usersSeries, setUserSeriers] = useState({ l12m: null });
  const [ordersSeriers, setOrdersSeries] = useState({ l7d: null });
  const [revenueSeries, setRevenueSeries] = useState({ l7d: null });
  const getDashboardData = async () => {
    await url
      .get("/dashboard")
      .then((res) => {
        console.log("res dashboard:", res.data);
        if (res.data.Message === "Success") {
          const dataToParse = res.data?.data ?? {};
          setUserSeriers({ ...usersSeries, ...dataToParse.usersSeries });
          setOrdersSeries({ ...ordersSeriers, ...dataToParse.ordersSeriers });
          setRevenueSeries({ ...revenueSeries, ...dataToParse.revenueSeries });
          setTotalOrders(dataToParse.totalOrders);
          setTotalRegistrations(dataToParse.totalRegistrations);
          setTotalProducts(dataToParse.totalProducts);
          setTotalRevenue(dataToParse.totalRevenue);
          setProductsByRevenue(
            res.data.data.topSellingByRevenue.filter((item) => {
              return item.productDetails !== undefined;
            })
          );
          setProductsByCount(
            res.data.data.topSellingByCount.filter((item) => {
              return item.productDetails !== undefined;
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <>
      <div className="row">
        <div className="col-md-3 padding-0" style={{ paddingLeft: "15px" }}>
          <div
            className="card-dashboard d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                className="card-text"
                style={{ textAlignt: "left", fontWeight: "500" }}
              >
                Total Registrations
              </div>
              <br />
              <div className="card-dash-text">{totalRegistrations} </div>{" "}
            </div>
            <div className="new-Iconbox">
              <img src={people} />
            </div>
          </div>
        </div>
        <div className="col-md-3 padding-0" style={{ paddingLeft: "15px" }}>
          <div
            className="card-dashboard d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                className="card-text"
                style={{ textAlignt: "left", fontWeight: "500" }}
              >
                Total Orders
              </div>
              <br />

              <div className="card-dash-text">{totalOrders} </div>
            </div>
            <div className="new-Iconbox">
              <img src={truck} />
            </div>
          </div>
        </div>
        <div className="col-md-3 padding-0" style={{ paddingLeft: "15px" }}>
          <div
            className="card-dashboard d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                className="card-text"
                style={{ textAlign: "left", fontWeight: "500" }}
              >
                Total Revenue
              </div>
              <br />
              <div className="card-dash-text">
                {"$"}
                {(Math.round(totalRevenue * 100) / 100).toFixed(2)}{" "}
              </div>
            </div>
            <div className="new-Iconbox">
              <img src={Dollar} />
            </div>
          </div>
        </div>
        <div className="col-md-3 padding-0" style={{ paddingLeft: "15px" }}>
          <div
            className="card-dashboard d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                className="card-text"
                style={{ textAlignt: "left", fontWeight: "500" }}
              >
                Total Products
              </div>
              <br />

              <div className="card-dash-text">{totalProducts} </div>
            </div>
            <div className="new-Iconbox">
              <img src={bin} />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <div className="card-dashboard">
            <div className="">
              <h6 className="" style={{ color: "#192746" }}>
                USER REGISTRATIONS
              </h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "15px 0",
                }}
              >
                <div className="dashboard-total-count">
                  {getTotalOccurencesFromLineData(usersSeries["l12m"])}
                </div>
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    color: "#797979",
                  }}
                >
                  Yearly
                </div>
              </div>
            </div>

            <div>
              {getTotalOccurencesFromLineData(usersSeries["l12m"]) === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <img className="dashImage" src={Graphs} />
                  <div className="dashDiv">
                    <h4 className="dashHeading">
                      Start building your dashboard!
                    </h4>
                    <span className="dashText">
                      Before we can create any chart, we’ll first need some data
                      here
                    </span>
                  </div>
                </div>
              ) : (
                <Line
                  className="temp-chart"
                  data={getLineDataFromGenericDateData(
                    usersSeries["l12m"],
                    "Last Year"
                  )}
                  options={options}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <div className="card-dashboard">
            <div className="">
              <h6 className="" style={{ color: "#192746" }}>
                TOTAL ORDERS
              </h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "15px 0",
                }}
              >
                <div className="dashboard-total-count">
                  {Math.round(
                    getTotalOccurencesFromLineData(ordersSeriers["l7d"])
                  )}
                </div>
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    color: "#797979",
                  }}
                >
                  Last Week
                </div>
              </div>
            </div>
            {getTotalOccurencesFromLineData(ordersSeriers["l7d"]) === 0 ? (
              <>
                <img className="dashImage" src={Graphs} />
                <div className="dashDiv">
                  <h4 className="dashHeading">
                    Start building your dashboard!
                  </h4>
                  <span className="dashText">
                    Before we can create any chart, we’ll first need some data
                    here
                  </span>
                </div>
              </>
            ) : (
              <Bar
                data={getBarDataFromGenericDateData(
                  ordersSeriers["l7d"],
                  "Last Week"
                )}
                options={options}
              />
            )}
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="card-dashboard">
            <div className="">
              <h6 className="" style={{ color: "#192746" }}>
                TOTAL REVENUE
              </h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "15px 0",
                }}
              >
                <div className="dashboard-total-count">
                  ${" "}
                  {Math.round(
                    getTotalOccurencesFromLineData(revenueSeries["l7d"])
                  ).toFixed(2)}
                </div>
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    color: "#797979",
                  }}
                >
                  Last Week
                </div>
              </div>
            </div>

            {getTotalOccurencesFromLineData(revenueSeries["l7d"]) === 0 ? (
              <>
                <img className="dashImage" src={Graphs} />
                <div className="dashDiv">
                  <h4 className="dashHeading">
                    Start building your dashboard!
                  </h4>
                  <span className="dashText">
                    Before we can create any chart, we’ll first need some data
                    here
                  </span>
                </div>
              </>
            ) : (
              <Bar
                data={getBarDataFromGenericDateData(
                  revenueSeries["l7d"],
                  "Last Week"
                )}
                options={options}
              />
            )}
          </div>
        </div>
      </div>

      <div className="row mt-3 mb-3">
        <div className="col-md-6">
          <div className="card-chart" style={{ height: "100%" }}>
            <div className="d-flex flex-row">
              <h6 className="mr-auto p-2" style={{ color: "#192746" }}>
                TOP SELLING PRODUCTS BY REVENUE
              </h6>
            </div>

            {productsByRevenue.slice(0, 5).map((revenue, i) => (
              <>
                <div className="pt-2 d-flex flex-row" key={i}>
                  <img
                    className="p-2 image-avatar"
                    alt="img"
                    src={
                      revenue.productDetails?.image
                        ? revenue.productDetails.image
                        : blank
                    }
                  />
                  <p className="p-2">
                    {revenue.productDetails?.name}
                    <br />
                    <span>{revenue.productDetails?.brand}</span>
                  </p>
                  <div className="value-container ml-auto p-2">
                    <h6 className="value">
                      ${parseFloat(revenue.total + "").toFixed(2)} <br />
                    </h6>
                    <p className="value-label">REVENUE</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-chart" style={{ height: "100%" }}>
            <div className="d-flex flex-row">
              <h6 className="mr-auto p-2" style={{ color: "#192746" }}>
                TOP SELLING PRODUCTS BY COUNT
              </h6>
            </div>

            {productsByCount.slice(0, 5).map((revenue, i) => {
              return (
                <>
                  <div key={i} className="pt-2 d-flex flex-row flex-wrap">
                    <img
                      className="p-2 image-avatar"
                      alt="img"
                      src={
                        revenue.productDetails?.image
                          ? revenue.productDetails.image
                          : blank
                      }
                    />
                    <p className="p-2">
                      {revenue.productDetails?.name}
                      <br />
                      <span>{revenue.productDetails?.brand}</span>
                    </p>
                    <div className="value-container ml-auto p-2">
                      <h6 className="value">
                        {revenue.occurrence} <br />
                        <p className="value-label">ORDERS</p>
                      </h6>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
