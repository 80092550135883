import React from "react";
import { Route, Switch } from "react-router-dom";
import Inventory from "../Inventory/Inventory";
import OutletChainAdmin from "../OutletChainAdmin/Outlet-Updated";
import AddOutletChain from "../../components/OutletChainAdminComponents/AddOutlet-Updated";
import EditOutletChain from "../../components/OutletChainAdminComponents/EditOutlet-Updated";
import NotFound from "../NotFound";
import CatalogOutlet from "../OutletAdminPages/Catalog/index-updated";
import InventoryOutlet from "../OutletAdminPages/Inventory/Index-updated";
import Deals from "../Deals/index.v1";
import OrderDetail from "../Order/OrderDetail";
import AddDeal from "../Deals/AddDeal-Updated";
import EditDeal from "../Deals/EditDeal-updated";
import Discounts from "../Discounts/Discount-Updated";
import DiscountsOutlet from "../OutletAdminPages/discounts/coupons";
import AddMissing from "../OutletAdminPages/AddMissingProduct";
import Users from "../Users/index.v2";
import UserDetails from "../Users/userDetails";
import AddMembership from "../Membership/AddMembership";
import EditMembership from "../Membership/EditMembership";
import Membership from "../Membership/";
import ExcelSample from "../ExcelSample";
import AddCoupons from "../Discounts/AddCoupon-Updated.jsx";
import EditCoupon from "../Discounts/EditCoupon-Updated";
import Banner from "../Banner/Index-updated";
import AddBannerV1 from "../Banner/AddBannerV1";
import OrderUpdated from "../Order/Order-Updated";
import EditBannerV1 from "../Banner/EditBannerV1";
import CreatePage from "../PrivacyPage/AddPrivacy";
import AllPage from "../PrivacyPage/Privacy";
import Setting from "../Settings/Setting";
import GeneralSettingsPage from "../GeneralSettings";
import AddSetting from "../Settings/AddSetting";
import LoyaltyPoints from "../LoyaltyPoints/Index-updated";
import AddPoints from "../LoyaltyPoints/AddPoints";
import NotificationsIndex from "../Notifications/index-updated";
import SpecializePoint from "../LoyaltyPoints/Specialpoint";
import SpecialCoupon from "../specialCoupon/SpecialCoupon";
import AddSpecialCoupon from "../specialCoupon/Add-SpecialCoupon";
import Dashboard1 from "../Dashboard/indexV1";
import EditSpecial from "../specialCoupon/Edit-Special";
import Categories from "../../components/Categories/Categories.v2";
import Brands from "../../components/Brands/Brands.v2";
import ChatPage from "../Chat";
import Logout from "../Logout/Logout";
import { ResetPasswordComponent } from "../../components/BasicInfoPage/ResetPassword";

const isAdmin = JSON.parse(localStorage.getItem("Admin"));
export default function Routes() {
  // console.log(isAdmin[0], 'isAdmin');
  return (
    <Switch>
      <Route exact path="/outlets" component={OutletChainAdmin} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/dashboard" component={Dashboard1} />
      <Route exact path="/user-detail" component={UserDetails} />
      <Route exact path="/add-outlet" component={AddOutletChain} />
      <Route exact path="/edit-outlet" component={EditOutletChain} />
      <Route exact path="/inventory" component={Inventory} />
      <Route exact path="/order" component={OrderUpdated} />
      {localStorage.getItem("singleOrder") && (
        <Route exact path="/order-detail" component={OrderDetail} />
      )}
      <Route exact path="/inventory-outlet" component={InventoryOutlet} />
      <Route exact path="/catalog-outlet" component={CatalogOutlet} />
      <Route exact path="/deals" component={Deals} />

      <Route exact path="/banner" component={Banner} />
      <Route exact path="/add-banner" component={AddBannerV1} />
      <Route exact path="/edit-banner" component={EditBannerV1} />
      <Route exact path="/add-deal" component={AddDeal} />
      <Route exact path="/edit-deal" component={EditDeal} />
      <Route exact path="/discounts" component={Discounts} />
      <Route exact path="/add-coupon" component={AddCoupons} />
      <Route exact path="/edit-coupon" component={EditCoupon} />
      <Route exact path="/add-missing" component={AddMissing} />
      <Route exact path="/add-membership" component={AddMembership} />
      <Route exact path="/edit-membership" component={EditMembership} />
      <Route exact path="/membership" component={Membership} />
      <Route exact path="/excel" component={ExcelSample} />
      <Route exact path="/add-page" component={CreatePage} />
      <Route exact path="/chat" component={ChatPage} />
      <Route exact path="/page" component={AllPage} />
      <Route exact path="/categories" component={Categories}></Route>
      <Route exact path="/brands" component={Brands}></Route>
      <Route
        exact
        path="/general-settings"
        component={GeneralSettingsPage}
      ></Route>
      <Route
        exact
        path="/ResetPassword"
        component={ResetPasswordComponent}
      ></Route>
      <Route exact path="/add-setting" component={AddSetting} />
      <Route exact path="/setting" component={Setting} />
      <Route exact path="/loyalty-points" component={LoyaltyPoints}></Route>
      <Route exact path="/loyalty-add-points" component={AddPoints}></Route>
      <Route exact path="/my-notifications" component={NotificationsIndex} />
      <Route exact path="/Special-Event" component={SpecialCoupon} />
      <Route exact path="/Add-SpecialEvent" component={AddSpecialCoupon} />
      <Route exact path="/Edit-SpecialEvent" component={EditSpecial} />
      <Route exact path="/Specializedloyalty" component={SpecializePoint} />
      <Route exact path="/logout" component={Logout} />

      <Route component={NotFound} />
    </Switch>
  );
}
