import * as React from "react";

import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText, Typography } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";

/**
  anchorEl<HtmlDomElement>: Anchor of the button/any html element where the menu popup will appeaer
  menuItems<Object[]>: [
    label: Menu Item Button Label,
    icon: Icon of the menu item,
    _rightLabel: secondary right label,
    onClick: Onclick function of the button
  ]
 */
const PopupMenu = ({ anchorEl, setAnchorEl, menuItems }) => {
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {menuItems.map((item) => (
        <MenuItem onClick={item.onClick ?? null}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText>{item.label}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {item._rightLabel}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
const Menu = muiStyled(MuiMenu)(({ theme }) => ({
  "& .MuiList-root": {
    padding: "0px",
    boxShadow: "-5px 4px 10px rgba(0, 0, 0, 0.11)",
  },
  "& .MuiMenuItem-root": {
    padding: "10px 16px",
    borderBottom: "1px solid rgba(149, 155, 151, 0.15);",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFFFFF",
    },
  },
}));
export default PopupMenu;
