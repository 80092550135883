import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import Popup from "reactjs-popup";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { useTheme } from "@emotion/react";
import CustomTable from "../../components/CustomTable";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function Order() {
  const classes = useStyles();
  const theme = useTheme();
  const [orders, setOrders] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const history = useHistory();
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [loading, setLoading] = useState(true);
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const { search } = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [unfiltered, setUnfiltered] = useState([]);
  const [outletID, setOutletID] = useState("");
  const [sortByAlpha, setSortByAlpha] = useState(0);
  const [sortByPrice, setSortByPrice] = useState(0);
  const [sortByTime, setSortByTime] = useState(0);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    // setSearchTerm("");
  };
  const admin = JSON.parse(localStorage.getItem("Admin"));
  console.log(admin);
  useEffect(() => {
    if (admin[0].isSubOutlet) {
      getAllOrders();
    } else {
      GetAllOutlets();
    }
  }, []);
  // This function is for getting data for solechain
  const getAllOrders = () => {
    try {
      let queryParams = {
        ...queryData,
        sortByAlpha,
        sortByPrice,
        sortByTime,
        fromDate,
        toDate,
      };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      url
        .get(`v1/orders/aggregated?outletChainID=${admin[0].outletChainID}`, {
          params: {
            ...queryParams,
          },
        })
        .then(async (res) => {
          console.log(res.data, "res:");
          if (res.data.Message === "Success") {
            setUnfiltered(res.data.data.orders);
            setOrders(res.data.data.orders);
            setPaginationData({
              ...paginationData,
              ...res?.data?.data?.paginationData,
            });
            setLoading(false);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  // This funciton is getting outlets data for multi chain
  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            openNotFoundPopUp();
          } else {
            setOutlets(res.data.data);
          }
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    if (admin[0].isSubOutlet) {
      getAllOrders();
    } else {
      GetOrdersByOutlet(outletID);
    }
  }, [sortByAlpha, sortByPrice, sortByTime, fromDate, toDate]);
  // In this function we get orders by outlet
  const GetOrdersByOutlet = (outletId) => {
    setOutletID(outletId);
    if (outletId !== "select") {
      let queryParams = {
        ...queryData,
        sortByAlpha,
        sortByPrice,
        sortByTime,
        fromDate,
        toDate,
      };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      url
        .get(`/v1/orders/aggregated?outletChainID=${outletId}`, {
          params: {
            ...queryParams,
          },
        })
        .then(async (res) => {
          console.log("Order Outlet:", res);
          if (res.data.Message === "Success") {
            if (res.data.data.length === 0) {
              openNotFoundPopUp();
            } else {
              // const sortedArray = res.data.data.orders.sort((a, b) =>
              //   moment(b.createdAt).diff(a.createdAt)
              // );

              setUnfiltered(res.data.data.orders);
              setOrders(res.data.data.orders);
              setPaginationData({
                ...paginationData,
                ...res?.data?.data?.paginationData,
              });
              setLoading(false);
            }
          } else {
            openNotFoundPopUp();
          }
        })
        .catch((e) => console.log(e));
    }
  };
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  //clientSide search
  useEffect(
    () => {
      if (searchTerm?.length !== 0) {
        let filtered = unfiltered.filter((element) =>
          // element.orderID ||
          element?.customer?.name
            ?.toLowerCase()
            .includes(searchTerm?.trim().toLowerCase())
        );
        setOrders(filtered);
        // if (filtered.length === 0) {
        //   openNotFoundPopUp();
        // }
      } else {
        setOrders(unfiltered);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        getAllOrders({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/order${queryString}`);
    setQueryData({ ...query });
  };

  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());

    if (
      (value + "").trim().length === 0 &&
      prevSearchTerm.trim().length !== 0
    ) {
      handleChange("search", "", true);
    }
  };

  function backgroundColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "rgb(251, 255, 194)";
        break;
      case "New":
        result = "rgba(108, 149, 255, 0.1)";
        break;
      case "Cancelled":
        result = "rgba(255, 109, 109, 0.08)";
        break;
      case "Delivered":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Completed":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Dispatched":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "#FFC700";
        break;
      case "New":
        result = "blue";
        break;
      case "Cancelled":
        result = "brown";
        break;
      case "Completed":
        result = "#0CD600";
        break;
      case "Delivered":
        result = "#0CD600";
        break;
      case "Dispatched":
        result = "#0CD600";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  return (
    <div className="row">
      <CustomTable
        title="Orders"
        outlets={!admin[0].isSubOutlet ? outlets : null}
        outletID={!admin[0].isSubOutlet ? outletID : null}
        GetDataByOutlet={GetOrdersByOutlet}
        HeaderData={[
          {
            ColumnName: "Order ID",
            style: {
              textAlign: "left",
            },
          },
          {
            ColumnName: "Customer",
            style: {
              textAlign: "left",
            },
          },
          {
            ColumnName: "Price",
            style: {
              textAlign: "left",
            },
          },
          {
            ColumnName: "Purchased On",
            style: {
              textAlign: "left",
            },
          },
          {
            ColumnName: "Status",
            style: {
              textAlign: "center",
            },
          },
          {
            ColumnName: "Action",
            style: {
              textAlign: "center",
            },
          },
        ]}
        Searchlabel="Search Order"
        RowsStyle={{ justifyContent: "space-evenly" }}
        search={handleSearchTermChange}
        sort={true}
        // anchorEl={filterPopupMenuAnchor}
        // setAnchorEl={setFilterPopupMenuAnchor}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        sortByTime={sortByTime}
        setSortByTime={setSortByTime}
        // anchorElPopup={sortPopupMenuAnchor}
        // setAnchorElPopup={setSortPopupMenuAnchor}
        // handleOpenSortPopupMenu={handleOpenSortPopupMenu}
        // handleOpenFilterPopupMenu={handleOpenFilterPopupMenu}
        setSortByAlpha={setSortByAlpha}
        setSortByPrice={setSortByPrice}
      >
        <tbody>
          {orders.map((order, i) => {
            console.log(order.status, "coupon:");
            return (
              <tr key={i}>
                <td style={{ textAlign: "left", marginLeft: "10px" }}>
                  <span
                    style={{
                      color: theme.palette.gray.extraDark,
                      fontWeight: "600",
                    }}
                  >
                    # {order.orderID}{" "}
                  </span>
                </td>
                <td
                  style={{
                    color: theme.palette.gray.extraDark,
                    textAlign: "left",
                  }}
                >
                  {order.customer.name}
                  <br />
                  <span
                    style={{
                      color: theme.palette.gray.extraDark,
                      textAlign: "left",
                      fontSize: "10px",
                    }}
                  >
                    {order.customer.email}
                  </span>
                </td>
                <td
                  style={{
                    color: theme.palette.gray.extraDark,
                    textAlign: "left",
                  }}
                >
                  $ {order.finalPrice}
                </td>
                <td
                  style={{
                    color: theme.palette.gray.extraDark,
                    textAlign: "left",
                  }}
                >
                  {moment(order.createdAt).format("D MMM YYYY, h:mm A")}
                </td>
                {/* <td className="status">
                            <span>{deal.name}</span>
                          </td> */}
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={checkColor(order.status)}
                >
                  {/* {order.status} */}
                  <div
                    style={{
                      width: "50%",
                      backgroundColor: `${backgroundColor(order.status)}`,
                      height: "25px",
                      textAlign: "center",
                      borderRadius: "13px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "400",
                        textAlign: "center",
                        fontSize: "13px",
                        paddingTop: "4px",
                        color: checkColor(order.status),
                      }}
                    >
                      {order.status}
                    </p>
                  </div>
                </td>
                <td style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn  newView"
                    onClick={() => {
                      localStorage.setItem(
                        "singleOrder",
                        JSON.stringify(order)
                      );
                      localStorage.setItem(
                        "orderID",
                        JSON.stringify(order._id)
                      );
                      localStorage.setItem("OrderOutletID", order?.outletID);
                      history.push(`/order-detail`);
                    }}
                  >
                    <i className="fas fa-eye"></i>
                    <u>View Details </u>
                  </button>
                  {/* onClick={() => moveToEdit(product)} */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </CustomTable>

      {/* Custom table will be implemented here */}

      {loading ? null : (
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              shape="square"
              className={classes.root}
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      )}

      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Orders not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
