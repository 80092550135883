import react, { useState, useEffect, useRef, Fragment } from "react";
import url from "../../config/axios";
import EditModal from "./EditModal";
import { useHistory } from "react-router-dom";

import Popup from "reactjs-popup";
import blank from "../../assets/defaultImage.jpg";
import AddModal1 from "./AddModal-Updated";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import VariantsComponent, {
  getPriceByVariant,
  getQuantityFromVariant,
} from "../../pages/OutletAdminPages/Inventory/Variants";
import CusomSearch from "../../atoms/CustomSearch/index";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
function InventoryList() {
  const classes = useStyles();
  const history = useHistory();
  const [outlets, setOutlets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);
  const [unfiltered, setUnfiltered] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [outletID, setOutletID] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [productId, setProductId] = useState("");
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const [loading, setLoading] = useState(true);

  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  useEffect(() => {
    GetAllOutlets();
  }, []);

  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  let num = 0;
  const admin = JSON.parse(localStorage.getItem("Admin"));
  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  useEffect(() => {
    if (outletID.length) {
      GetAllInventory();
    }
  }, [outletID]);

  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef?.current?.open();

  const refDelete = useRef();
  const openDeleteTooltip = () => refDelete.current.open();
  const closeDeleteTooltip = (success = false) => {
    if (success) {
      refDelete.current.close();
    } else {
      refDelete.current.close();
    }
  };

  useEffect(() => {
    if (outletID.length) {
      GetAllInventory();
    }
  }, [outletID]);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));

    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const deleteInventory = () => {
    url
      .delete(`/inventory/?productID=${productId}&outletChainID=${outletID}`)
      .then(async (res) => {
        if (res.data.Message === "Failure") {
          closeDeleteTooltip();
        } else {
          closeDeleteTooltip();
        }
        GetAllInventory();
      });
  };
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/inventory${queryString}`);
      setQueryData({ ...query });
    }
  };

  const GetAllInventory = () => {
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`v2/admin/inventories/paginated?outletChainID=${outletID}`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        console.log(res);
        if (res.data.Message === "Success") {
          setUnfiltered(res.data.data.inventories);
          setInventory(res.data.data.inventories);
          setTotalPage(res.data.data.inventories.length);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
          setLoading(false);
          setminPro(page * 50 - 50 + 1);
        } else {
          setInventory([]);
        }
      });
  };

  const updateLocalData = (invent, i) => {
    // const index = inventory.findIndex((inv) => inv.productId === invent.productId);
    let obj = invent;
    const new_obj = { ...obj, featuredProduct: !obj.feafeaturedProduct };
    // console.log(new_obj, 'boj');
    let newArr = [...inventory]; // copying the old datas array
    newArr[i] = new_obj; // replace e.target.value with whatever you want to change it to
    setInventory(newArr);
  };

  const updateLocalDataStatus = (invent, i) => {
    // const index = inventory.findIndex((inv) => inv.productId === invent.productId);
    let obj = invent;
    const new_obj = { ...obj, status: !obj.status };
    // console.log(new_obj, 'boj');
    let newArr = [...inventory]; // copying the old datas array
    newArr[i] = new_obj; // replace e.target.value with whatever you want to change it to
    setInventory(newArr);
  };

  const updateInventory = (outletId, productId, featuredOne) => {
    console.log(outletId, productId, featuredOne);
    url
      .put(`/inventory/?productID=${productId}&outletChainID=${outletId}`, {
        featuredProduct: featuredOne,
      })
      .then(async (res) => {
        // console.log("updated", res.data);
        if (res.data.Message === "Success") {
          GetAllInventory(outletId);
        } else {
          openNotFoundPopUp();

          // alert(res.data.data);
        }
      });
  };

  const updateInventoryStatus = (outletId, productId, status) => {
    console.log(outletId, productId, status);
    url
      .put(`/inventory/?productID=${productId}&outletChainID=${outletId}`, {
        status: status,
      })
      .then(async (res) => {
        // console.log("updated", res.data);
        if (res.data.Message === "Success") {
          GetAllInventory(outletId);
        } else {
          openNotFoundPopUp();

          // alert(res.data.data);
        }
      });
  };

  const SearchByProduct = (value) => {
    if (value === "Select") {
      getAllPaginatedInventory();
    } else {
      url
        .get(`/inventory/search/?outletChainID=${outletID}&name=${value}`)
        .then(async (res) => {
          console.log(res, "response:");
          if (res.data.Message === "Success") {
            setUnfiltered(res.data.data);
            setInventory(res.data.data);
          } else {
            setInventory([]);
            // alert(res.data.data);
          }
        });
    }
  };
  // ClientSide search
  useEffect(
    () => {
      if (searchValue?.length !== 0) {
        console.log(unfiltered, "Unfiltered");
        let filtered = unfiltered.filter((element) =>
          element?.product?.name
            ?.toLowerCase()
            ?.includes(searchValue?.trim().toLowerCase())
        );

        setInventory(filtered);
      } else {
        setInventory(unfiltered);
      }
    },
    // eslint-disable-next-line
    [searchValue]
  );
  const getAllPaginatedInventory = () => {
    url
      .get(`inventory/paginated/?outletChainID=${outletID}&page=${page}`)
      .then(async (res) => {
        // console.log(res.data, "res:");
        if (res.data.Message === "Success") {
          setUnfiltered(res.data.data);
          setInventory(res.data.data);
        }
      });
  };
  useEffect(() => {
    // setInventory([])
  }, [openModal]);

  // openAddModal && openTooltip();
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card car-pro">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "5px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-4 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Inventory
                  </div>
                </div>
                <div className={outletID === "" ? "col-md-3" : "col-md-3"}>
                  <div className="input-group ml-1 ">
                    <select
                      className=" newInputSelect w-100"
                      style={{ height: "35px", border: "1px solid #E2E8F0" }}
                      onChange={(e) => {
                        // GetAllInventory(e.target.value);
                        setOutletID(e.target.value);
                        // console.log(e.target.value)
                      }}
                      id="exampleFormControlSelect1"
                    >
                      <option value="Select">Select an outlet</option>
                      {outlets.map((outlet, i) => {
                        return (
                          <option value={outlet.outletChainID} key={i}>
                            {outlet.outletName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {outletID === "" ? (
                    <p
                      className="ml-3"
                      style={{ color: "red", textAlign: "left" }}
                    >
                      Please select outlet
                    </p>
                  ) : null}
                </div>

                <div>
                  <CusomSearch
                    label="Search Product"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    width="100%"
                  />
                </div>

                <div className="ml-2">
                  <button
                    style={{ margin: "0" }}
                    className="btn  globalButton"
                    onClick={() => {
                      if (outletID !== "") {
                        setOpenAddModal(true);
                      }
                    }}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Product
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <table className="table invtable table-striped-cust">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>

                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Stock</th>
                      <th scope="col">Price/Item</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Category</th>
                      <th scope="col">Featured Product</th>
                      <th scope="col">Publish Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventory.map((inv, index) => {
                      console.log(
                        inv?.product?.brand[0]?.name,
                        "this is inventory"
                      );
                      return (
                        <tr key={index}>
                          <td colSpan="6">
                            <div className="d-flex">
                              <img
                                alt="yo1"
                                src={
                                  inv?.product?.image
                                    ? inv?.product?.image
                                    : blank
                                }
                                className="img-fluid"
                                width="40"
                                style={{ borderRadius: "5px" }}
                              />
                              <span
                                style={{ maxWidth: "250px", color: "#1E293B" }}
                              >
                                {inv?.product?.name}
                              </span>
                            </div>
                          </td>
                          <td style={{ color: "#1E293B" }}>
                            {inv?.variants?.length > 1
                              ? "---"
                              : inv?.variants?.length === 1
                              ? getQuantityFromVariant(inv?.variants[0], inv)
                              : inv.quantity}
                          </td>
                          <td style={{ color: "#1E293B" }}>
                            {inv?.variants?.length ? (
                              inv?.variants?.length > 1 ? (
                                <VariantsComponent
                                  variants={inv?.variants}
                                  masterProductName={
                                    inv?.product?.name ?? "N/A"
                                  }
                                  rootObject={inv}
                                />
                              ) : (
                                getPriceByVariant(inv?.variants[0])
                              )
                            ) : (
                              ` $ ${Number(inv.originalPrice).toLocaleString(
                                "fullwide",
                                { useGrouping: false }
                              )}`
                            )}
                          </td>
                          <td style={{ color: "#1E293B" }}>
                            {inv?.product?.brand[0]?.name
                              ? inv?.product?.brand[0]?.name
                              : "N/A"}
                          </td>
                          <td className="">
                            <span style={{ color: "#1E293B" }}>
                              {inv?.product?.category &&
                              inv?.product?.category[0]?.status === true
                                ? inv?.product?.category[0]?.name
                                : "N/A"}
                            </span>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={inv.featuredProduct}
                                onChange={() => {
                                  updateLocalData(inv, index);
                                  updateInventory(
                                    inv.outletChainID,
                                    inv.productID,
                                    !inv.featuredProduct
                                  );
                                }}
                              />
                              <span
                                className="slider round"
                                style={
                                  inv.featuredProduct
                                    ? { backgroundColor: "#FFA382" }
                                    : null
                                }
                              ></span>
                            </label>
                          </td>
                          <td className="status">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={inv.status}
                                onChange={() => {
                                  updateLocalDataStatus(inv, index);
                                  updateInventoryStatus(
                                    inv.outletChainID,
                                    inv.productID,
                                    !inv.status
                                  );
                                }}
                              />
                              <span
                                className="slider round"
                                style={
                                  inv.status
                                    ? { backgroundColor: "#FFA382" }
                                    : null
                                }
                              ></span>
                            </label>
                            {/* {inv.status ? (
                            <span>Published</span>
                          ) : (
                            <span style={{ color: 'red' }}>Not Published</span>
                          )} */}
                          </td>
                          <td>
                            <div className="d-flex">
                              <i
                                style={{ color: "#FFA382" }}
                                className="fas fa-pen"
                                onClick={() => {
                                  setSelectedProduct(inv);
                                  setOpenModal(true);
                                }}
                              ></i>
                              <i
                                onClick={() => {
                                  setProductId(inv.productID);
                                  openDeleteTooltip();
                                }}
                                className="fas fa-trash deleteIcon"
                              ></i>
                            </div>

                            {/* onClick={() => moveToEdit(product)} */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                className={classes.root}
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>

      {openModal && (
        <EditModal
          closeModal={setOpenModal}
          product={selectedProduct}
          GetAllInventory={GetAllInventory}
          openModal={setOpenSuccess}
          searchProduct={SearchByProduct}
          searchValue={searchValue}
        />
      )}
      {openAddModal && (
        <AddModal1
          closeAddModal={setOpenAddModal}
          GetAllInventory={GetAllInventory}
          openModal={setOpenSuccess}
        />
      )}
      <Popup ref={refDelete} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this product ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteInventory();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeDeleteTooltip}
          >
            No
          </button>
        </div>
      </Popup>
    </div>
  );
}
export default InventoryList;
