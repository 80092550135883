import React, { useState, useEffect } from "react";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { styled } from "@mui/material/styles";
import SearchBar from "../SearchBar/SearchBar-Updated";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import blank from "../../assets/defaultImage.jpg";
import Box from "@mui/material/Box";
// import VariantPage from "./variants";
import InputPrimary from "../../atoms/InputPrimary/Index2";
import VariantPage from "../../components/OutletAdminComponents/variants";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { removeErrors } from "../../helpers/ErrorHandling";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import url from "../../config/axios";
import axios from "axios";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  FormCustom,
  ItemChip,
  ItemCustom,
  SelectCustom,
  FormLabelCustom,
} from "../../atoms/CustomMUISelect";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddInventory({ closeAddModal, GetAllInventory }) {
  const theme = useTheme();
  const [singleProductDetails, setSingleProductDetails] = useState(null);
  const [stockQty, setStockQty] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [selectOutlet, setSelectOutlet] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [track, setTrack] = useState(true);
  const [activeStatus, setActiveStatus] = useState(false);
  const [variantFlow, setVariantFlow] = useState("no-variants");
  const [variants, setVariants] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selOutlet, setSelOutlet] = useState([]);
  const [outName, setOutName] = useState([]);
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [validation, setValidation] = useState({});
  const MyRadio = styled((props) => <Radio color="default" {...props} />)({
    "&.Mui-checked": {
      color: "#FFA382",
    },
  });
  useEffect(() => {
    GetAllOutlets();
  }, []);
  const handleVariantChange = (e) => {
    setVariantFlow(e.target.value);
    if (e.target.value == "variants") {
      setVariantFlow("variants");
    } else {
      setVariantFlow("no-variants");
    }
  };
  const handleVariants = (e) => {
    console.log("variants data: ", e);
    setVariants(e);
  };
  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));

    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        setOutlets(res.data.data);
      })
      .catch((e) => console.log(e));
  };
  const validateForm = () => {
    let data = { ...validation };
    if (!stockQty) {
      data = { ...data, StockErr: "Quantity is required" };
    } else {
      let { StockErr, ...others } = data;
      data = { ...others };
    }
    if (variantFlow === "variants") {
      for (let i = 0; i < variants.length; i++) {
        if (variants[i].price === "") {
          data = { ...data, VPErr: "Variant Price is required" };
        } else {
          let { VPErr, ...others } = data;
          data = { ...others };
        }
        if (variants[i].weight === "") {
          data = { ...data, VWErr: "Variant Weight is required" };
        } else {
          let { VWErr, ...others } = data;
          data = { ...others };
        }
      }
    } else if (variantFlow === "no-variants") {
      if (!originalPrice) {
        data = { ...data, PriceErr: "Price is required" };
      } else {
        let { PriceErr, ...others } = data;
        data = { ...others };
      }
    }

    setValidation({ ...data });
    return data;
  };

  const addInventory = () => {
    let multichainID = selOutlet[0]?.outletChainID;
    const Error = validateForm();
    if (Object.keys(Error).length === 0) {
      let body = {
        outletChainID: admin[0].isSubOutlet
          ? admin[0].outletChainID
          : multichainID,
        consumerId: admin[0].consumerId,
        productID: singleProductDetails.productID,
        quantity: stockQty,
        originalPrice: variantFlow === "variants" ? 0 : parseInt(originalPrice),
        status: activeStatus,
        variants: variantFlow === "variants" ? variants : [],
      };
      console.log(body, "body");
      url
        .post(`/inventory/`, body)
        .then(async (res) => {
          if (res.data.Message === "Error") {
            alert(res.data.data);
          } else {
            closeAddModal(false);
            GetAllInventory();
            // updateProduct();
          }
        })
        .catch((e) => console.log(e));
    }
  };

  // const updateProduct = () => {
  //   const standaloneURL = "https://standalone-backend.staging.bleaum.dev";
  //   try {
  //     axios
  //       .put(
  //         `${standaloneURL}/product/?productID=${singleProductDetails.productID}`,
  //         {
  //           ...productDetails,
  //           variants: variantFlow === "variants" ? variants : [],
  //           price: variantFlow === "variants" ? 0 : originalPrice,
  //           quantity: stockQty,
  //         }
  //       )
  //       .then((res) => {
  //         console.log("Here is my product response: ", res);
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 5,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#FFA382",
        borderTop: "3.5px solid #FFA382",
      },
    },
    [`&.${stepConnectorClasses.disabled}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "white",
        borderTop: "3.5px solid #CACACA",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#FFA382",
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      borderWidth: 0.2,
      borderRadius: 1,
      backgroundColor: theme.palette.mode === "dark" ? " #FFA382" : "#eaeaf0",
      border: 0,
    },
  }));

  const styles = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
    marginTop: "4rem",
    paddingTop: "4px",
    overflowY: "scroll",
    borderRadius: "5px",
  };

  const title = {
    fontSize: "22px",
    fontWeight: "500",
    alignItems: "center",
    padding: "1rem 1rem",
  };
  const StepperStyle = {
    backgroundColor: "#F4F7FC",
    height: "10vh",
    marginTop: "5px",
    paddingTop: "50px",
    paddingBottom: "70px",
    paddingRight: "100px",
    paddingLeft: "100px",
    marginLeft: "17px",
    marginRight: "17px",
    borderRadius: "5px",
  };
  const headingStyle = {
    color: "#1E293B",
    fontWeight: "700",
    fontSize: "20px",
  };
  const BodyStyle = {
    backgroundColor: "#F4F7FC",
  };
  const iconDivStyle = {
    color: "#192746",
  };
  const cancelStyle = {
    color: "#747474",
    backgroundColor: "#E2E8F0",
    border: "none",
    margin: "12px",
    height: "40px",
    borderRadius: "5px",
    width: "15%",
  };
  const updateStyle = {
    color: "#FFFFFF",
    backgroundColor: "#FFA382",
    border: "none",
    margin: "12px",
    height: "40px",
    borderRadius: "5px",
    width: "15%",
  };

  const text1 = {
    fontSize: "16px",
    fontWeight: "600",
    color: "#1E293B",
  };

  const text2 = {
    fontSize: "12px",
    fontWeight: "400",
    // color: "#1F6355",
  };
  const PercentStyle = {
    position: "absolute",
    marginTop: "-35px",
    marginLeft: "5px",
  };
  const mark = {
    color: "#898989",
    background: "none",
    marginLeft: "-2px",
  };
  const statusStyle = {
    color: "#747474",
    fontWeight: "bold",
    fontSize: "14px",
    fontWeight: "500",
  };
  const productDetails = {
    backgroundColor: "#E2E8F0",
    borderRadius: "7px",
  };
  const title2 = {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "700",
    color: "#192746",
  };
  const sliderColor = {
    backgroundColor: "#FFA382",
  };
  const modelContent = admin[0].isSubOutlet
    ? {
        height: "38rem",
      }
    : { height: "41rem" };
  const modelNewContent = {
    height: "auto",
  };

  const handleNext = () => {
    console.log(singleProductDetails, "Single products");
    if (selOutlet.length !== 0) {
      setStockQty(singleProductDetails.quantity);
      setOriginalPrice(singleProductDetails.price);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      let data = { ...validation };
      let { OutErr, ...others } = data;
      data = { ...others };
      setValidation({ ...data });
    } else {
      let data = { ...validation };
      data = { ...data, OutErr: "Outlet is required" };
      setValidation({ ...data });
    }
  };
  const selectOnlyOne = async (Value) => {
    try {
      let obj = [];

      if (Value.length === 0) {
        setOutName([]);
        setSelOutlet(obj);
      } else {
        let latesValue = Value.filter((value, i) => {
          let filterThis = value !== outName[i];
          return filterThis;
        });
        setOutName(
          typeof value === "string" ? latesValue.split("split") : latesValue
        );
        var splitName = latesValue[0]?.split("split", 3);
        obj.push({ outletChainID: splitName[0], name: splitName[1] });

        removeErrors("OutErr", validation, setValidation);

        setSelOutlet(obj);
      }
      // console.log(splitName)
    } catch (e) {
      console.log(e, "coming on selectopnly one function");
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  function getSteps() {
    return ["Select Your Product", "Submit Billing & Inventory Details"];
  }
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    width: 25,
    color: "white",
    fontWeight: "600",

    height: 25,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#FFA382",
      color: "black",
      fontWeight: "600",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#FFA382",
      color: "black",
      fontWeight: "600",
    }),
  }));
  const steps = getSteps();
  function ColorlibStepIcon(props) {
    const { active, completed, className, disabled, text } = props;
    const icons = {
      1: 1,
      2: 2,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active, disabled, text }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  return (
    <div className="modal" style={styles}>
      <div className="mdl modal-dialog-centered">
        <div
          className="modal-content"
          style={activeStep === 0 ? modelContent : modelNewContent}
        >
          <div className="modal-header" style={title}>
            <div className="d-flex " style={{ justifyContent: "space-evenly" }}>
              <div
                style={{
                  height: "25px",
                  marginLeft: "-16px",
                  backgroundColor: "#FFA382",
                  marginTop: "5px",
                }}
              >
                <span style={{ color: "#FFA382" }}>.</span>
              </div>
              <span
                style={{
                  marginLeft: "5px",
                  marginTop: "5px",
                  color: "#FFA382",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Add Product To Inventory
              </span>
            </div>
            <HighlightOffRoundedIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                closeAddModal(false);
              }}
            />
          </div>
          <Box sx={{ width: "100%", marginTop: "8px" }}>
            <div style={StepperStyle}>
              <Stepper
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>

            {activeStep === 0 ? (
              <div>
                <div
                  className="row align-items-center"
                  style={{ padding: "3px", marginLeft: "10px" }}
                >
                  {!admin[0].isSubOutlet && (
                    <div style={{ width: "97%" }}>
                      <div className="row">
                        <div className="col-md-12  mt-3">
                          <p
                            className="m-0"
                            style={{
                              color: theme.palette.gray.extraDark,
                              fontWeight: "600",
                            }}
                          >
                            Select Store
                          </p>
                          <div className="">
                            <FormCustom>
                              <SelectCustom
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={outName}
                                onChange={(e) => selectOnlyOne(e.target.value)}
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    style={{ color: "black" }}
                                    label="Select Outlet(s)"
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <ItemChip
                                        key={value}
                                        label={`${value.split("split")[1]}`}
                                      />
                                    ))}
                                  </Box>
                                )}
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                MenuProps={MenuProps}
                              >
                                {outlets.map((outlet, i) => (
                                  <ItemCustom
                                    key={i}
                                    value={
                                      outlet.outletChainID +
                                      "split" +
                                      outlet.outletName
                                    }
                                    style={getStyles(outlet, outName, theme)}
                                  >
                                    {outlet.outletName}
                                  </ItemCustom>
                                ))}
                              </SelectCustom>
                            </FormCustom>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="validation-help ml-0">
                    {validation.OutErr}
                  </div>
                  {/* <div className="col-10">
                    <p className="m-0">
                      Search and select your product to add it to your inventory
                    </p>
                  </div> */}
                  <div className=" mt-3" style={{ width: "97%" }}>
                    {" "}
                    <div className="row ">
                      <div className="col-md-12 ">
                        <SearchBar
                          placeholder="Search Product"
                          setSingleProductDetails={setSingleProductDetails}
                        />
                      </div>
                    </div>
                  </div>
                  {singleProductDetails && (
                    <>
                      <div className="row align-items-center size-text ">
                        <div className="col-md-12">
                          {/* <img src={emptyImg} className="mx-auto d-block" />{" "} */}
                          <div
                            className="row  p-3  mr-4 rounded text-white sel-product"
                            style={BodyStyle}
                          >
                            <div className="col-md-3">
                              <img
                                src={
                                  singleProductDetails?.image
                                    ? singleProductDetails?.image
                                    : blank
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-md-9 ">
                              <div className="row">
                                <div className="col-md-12">
                                  <p style={headingStyle}>
                                    {" "}
                                    {singleProductDetails.name}
                                  </p>
                                </div>
                                <div className="col-md-5 second-row">
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color: theme.palette.gray.dark,
                                    }}
                                  >
                                    Brand:{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: theme.palette.gray.extraDark,
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {singleProductDetails?.brand
                                      ? singleProductDetails?.brand?.name
                                      : "N/A"}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "16px",

                                      color: theme.palette.gray.dark,
                                    }}
                                  >
                                    Categories:{" "}
                                  </span>
                                  <span
                                    style={{
                                      color: theme.palette.gray.extraDark,
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {" "}
                                    {singleProductDetails.category.length
                                      ? singleProductDetails.category[0].name
                                      : ""}
                                  </span>
                                </div>
                                <div className="col-md-5 second-row"></div>
                                <div className="col-md-2"></div>

                                <div
                                  className="col-md-5 percent"
                                  style={iconDivStyle}
                                >
                                  <i className="fas fa-sort-down mr-2" />
                                  <span
                                    style={{
                                      color: theme.palette.gray.extraDark,
                                      fontWeight: "500",
                                      fontSize: "23px",
                                    }}
                                  >
                                    {singleProductDetails.thc
                                      ? singleProductDetails.thc + "%"
                                      : "N/A "}
                                    TBH
                                  </span>
                                </div>
                                <div
                                  className="col-md-5 percent"
                                  style={iconDivStyle}
                                >
                                  <i className="fas fa-sort-up mr-2" />
                                  <span
                                    style={{
                                      color: theme.palette.gray.extraDark,
                                      fontWeight: "500",
                                      fontSize: "23px",
                                    }}
                                  >
                                    {singleProductDetails.cbd
                                      ? singleProductDetails.cbd + "%"
                                      : "N/A "}
                                    CBD
                                  </span>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-12">
                                  <p className="prod-p">
                                    {singleProductDetails.shortDescription}{" "}
                                    {singleProductDetails.longDescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "-5px",
                          }}
                        >
                          <button
                            onClick={() => {
                              closeAddModal(false);
                            }}
                            style={cancelStyle}
                          >
                            Cancel
                          </button>
                          <button
                            style={updateStyle}
                            onClick={() => {
                              handleNext();
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-12">
                  <div
                    className="d-flex justify-content-start p-3 my-1 mt-3"
                    style={productDetails}
                  >
                    <img
                      src={
                        singleProductDetails.image
                          ? singleProductDetails.image
                          : blank
                      }
                      alt="product"
                      width="40px"
                      height="40px"
                    />
                    <span className="mx-3">
                      <p style={text1} className="m-0 p-0">
                        {singleProductDetails.name}
                      </p>
                      <p style={text2} className="m-0 p-0">
                        <mark style={mark}>Brand: </mark>{" "}
                        {singleProductDetails?.brand
                          ? singleProductDetails?.brand?.name
                          : "N/A"}
                      </p>
                    </span>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <p style={title2} className="my-3">
                    Inventory Details
                  </p>
                  {track && (
                    <div className="">
                      <InputPrimary
                        label={"Quantity in Stock"}
                        placeholder={"Quantity in Stock"}
                        value={stockQty}
                        pattern="[0-9]*"
                        onInput={(e) => {
                          if (isNaN(e.target.value)) {
                            e.target.value = "";
                          } else {
                            e.target.value = Math.abs(e.target.value);
                          }
                        }}
                        onChange={(e) => {
                          setStockQty(e.target.value);
                          removeErrors("StockErr", validation, setValidation);
                        }}
                      />
                      {validation.StockErr && (
                        <p
                          style={{
                            color: theme.palette.action.danger,
                            marginTop: "3px",
                          }}
                        >
                          {validation.StockErr}
                        </p>
                      )}
                    </div>
                  )}
                  <span className="solo-chain">Track Inventory? * </span>
                  <div className="row mt-2">
                    <div className="col-md-4 switch-col">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={track}
                          onChange={() => setTrack(!track)}
                        />
                        <span
                          className="slider round"
                          style={track ? sliderColor : null}
                        ></span>
                      </label>
                    </div>
                    {!track && (
                      <span style={{ color: "red", paddingTop: "13px" }}>
                        Enable track inventory
                      </span>
                    )}

                    <div className="col-md-2"></div>
                  </div>
                  {/* )} */}
                </div>
                {track && (
                  <div className="col-md-12">
                    <p style={title2} className="my-3">
                      Billing Details
                    </p>

                    <div>
                      <div>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={variantFlow}
                            name="radio-buttons-group"
                            onChange={(e) => handleVariantChange(e)}
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <FormLabelCustom
                                value="variants"
                                control={<MyRadio />}
                                label="Variants"
                              />
                              <FormLabelCustom
                                value="no-variants"
                                control={<MyRadio />}
                                label="No Variant"
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    {variantFlow !== "variants" && (
                      <div
                        style={{
                          marginBottom: "1em",
                          width: "100%",
                        }}
                      >
                        <InputPrimary
                          label={"Original Price"}
                          type="text"
                          name="price"
                          placeholder="$ 25.00"
                          value={originalPrice}
                          pattern="[0-9]*"
                          onInput={(e) => {
                            if (
                              e.target.value?.toString()?.split(".")[1]
                                ?.length > 2
                            ) {
                              let value =
                                e.target.value.toString().split(".")[0] +
                                "." +
                                e.target.value
                                  .toString()
                                  .split(".")[1]
                                  .substring(0, 2);

                              e.target.value = value;
                            }
                          }}
                          onChange={(e) => {
                            setOriginalPrice(e.target.value);
                            removeErrors("PriceErr", validation, setValidation);
                          }}
                        />
                        {originalPrice !== "" && originalPrice && (
                          <span style={PercentStyle}>$</span>
                        )}
                        {validation.PriceErr && (
                          <p
                            style={{
                              color: theme.palette.action.danger,
                              marginTop: "3px",
                            }}
                          >
                            {validation.PriceErr}
                          </p>
                        )}
                      </div>
                    )}
                    {variantFlow === "variants" && (
                      <VariantPage
                        handleVariants={handleVariants}
                        variantsData={singleProductDetails?.variants}
                        validation={validation}
                      />
                    )}

                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-md-12">
                        <h5 style={statusStyle}>Status</h5>
                      </div>
                      <div className="col-md-8 switch-col">
                        <label className="switch  switch-demo">
                          <input
                            type="checkbox"
                            checked={activeStatus}
                            onChange={() => setActiveStatus(!activeStatus)}
                          />
                          <span
                            className="slider round"
                            style={activeStatus ? sliderColor : null}
                          ></span>
                          <span
                            className="slider-text"
                            style={
                              activeStatus
                                ? {
                                    paddingLeft: "81px",
                                    color: "#FFA382",
                                    fontWeight: "500",
                                  }
                                : {
                                    paddingLeft: "81px",
                                    color: "gray",
                                    fontWeight: "500",
                                  }
                            }
                          >
                            {activeStatus ? "Show" : "Disabled"}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          onClick={() => {
                            handleBack();
                          }}
                          style={cancelStyle}
                        >
                          Back
                        </button>
                        <button
                          style={updateStyle}
                          onClick={() => addInventory()}
                        >
                          Publish
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
}
