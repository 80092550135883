import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";

const initialState = {
  //{_id: {contentGroup: [{contentType: "TEXT", content: string }]}}
  sendBox: {},
  sending: false,
  error: null,
};

const sendBoxSlice = createSlice({
  name: "sendBox",
  initialState,
  reducers: {
    startSendingAction: (state) => {
      state.sending = true;
      state.error = null;
    },
    // {sessionID: string, content: string}
    setMessageAction: (state, action) => {
      state.sendBox = {
        ...state.sendBox,
        [action?.payload?.sessionID]: action.payload?.content,
      };
    },
    clearSendBoxAction: (state, action) => {
      if (state.sendBox[action.payload]) {
        state.sendBox = { ...state.sendBox, [action.payload]: "" };
      }
      state.sending = false;
      state.error = null;
    },
    clearSendBoxErrorAction: (state) => {
      state.error = null;
    },
    finishSendingWithErrorAction: (state) => {
      state.error = null;
      state.sending = false;
    },
  },
});
export const {
  startSendingAction,
  finishSendingWithErrorAction,
  clearSendBoxAction,
  clearSendBoxErrorAction,
  setMessageAction,
} = sendBoxSlice.actions;
export const selectCurrentSendBoxContent = (sessionId) => (state) =>
  state?.sendBox?.sendBox[sessionId];

export const selectSendBoxMeta = (state) => ({
  loading: state?.sendBox?.sending,
  error: state?.sendBox?.error,
});

export const sendMessageThunk = () => (dispatch, getState) => {
  dispatch(startSendingAction());
  const sessionInfo = getState()?.chatSession?.session;
  const sessionID = sessionInfo?._id;
  const userID = sessionInfo?.userID;
  const content = getState()?.sendBox?.sendBox[sessionID];
  url
    .post(
      `/v1/messages?outletchainid=${JSON.parse(
        localStorage.getItem("outlets")
      )}&fromAdmin=true`,
      {
        userID,
        sessionID,
        contentGroup: [
          {
            content: content,
            contentType: "TEXT",
          },
        ],
      }
    )
    .then(() => {
      dispatch(clearSendBoxAction(sessionID));
    })
    .catch((e) => {
      dispatch(finishSendingWithErrorAction(e.response?.data?.data?.message));
    });
};

export default sendBoxSlice.reducer;
