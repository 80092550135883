import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import InputPrimary from "../../atoms/InputPrimary/Index2";
import PhoneNumberInput from "../../atoms/PhoneNumberInput/PhoneNumberInputAnik";
import Cropper from "react-easy-crop";
import { useSelector } from "react-redux";
import Slider from "@mui/material/Slider";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";

function ProfilePopup({
  show,
  setShow,
  admin,
  error,
  imageSelector,
  editImage,
  cropProperties,
  validationMessages,
  fileUpdate,
  name,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
  setShowProfile,
  removeErrors,
}) {
  const history = useHistory();
  const theme = useTheme();
  const imageUploadRef = React.useRef();
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);

  return (
    <Wrapper theme={theme} show={show}>
      <button className="close-icon" onClick={() => setShow(false)}>
        <CloseIcon />
      </button>
      <div className="image-container">
        {editImage ? (
          <img
            alt="yo4"
            src={editImage}
            className="img-fluid profile-image"
            style={inlineStyles.thumbnailImage}
          />
        ) : (
          <div className="image-thumbnail-div" style={inlineStyles.textImage}>
            <span style={{ fontSize: "40px" }}>
              {admin[0]?.adminName?.substring(0, 1)}
            </span>
          </div>
        )}
        <input
          type="file"
          id="image1"
          className="edit-image"
          visbility="hidden"
          // disabled={!editAdmin}
          onChange={imageSelector}
          name="Image1"
          ref={imageUploadRef}
        />
        <button
          className="edit-image"
          onClick={() => imageUploadRef.current.click()}
        >
          <i className="fas fa-pen"></i> Edit Profile Picture
        </button>
        <ImageCropper {...cropProperties} />
      </div>
      <div className="info">
        <div>
          <InputPrimary
            label="Name"
            // disabled={!editAdmin}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              removeErrors("name");
            }}
          />
          {validationMessages?.name ? (
            <span className="validation-help" style={{ marginLeft: "10px" }}>
              {validationMessages?.name}
            </span>
          ) : (
            ""
          )}
        </div>

        <PhoneNumberInput
          label="Phone"
          phone={phone}
          setPhone={setPhone}
          maxLength={15}
          pattern="[0-9]*"
        />
        {validationMessages?.phone ? (
          <span className="validation-help" style={{ marginLeft: "10px" }}>
            {validationMessages?.phone}
          </span>
        ) : (
          ""
        )}
        <InputPrimary
          label="Email"
          type="email"
          value={email}
          // disabled={!editAdmin}
          disabled="true"
          onChange={(e) => setEmail(e.target.value)}
        />
        {validationMessages?.email ? (
          <span className="validation-help" style={{ marginLeft: "10px" }}>
            {validationMessages?.email}
          </span>
        ) : (
          ""
        )}
        {!admin[0].isSubOutlet && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="edit-image"
              onClick={() => {
                setShowProfile(false);
                history.push("/ResetPassword");
              }}
              style={{ color: "#FFA382" }}
            >
              Reset Password?
            </button>
          </div>
        )}
        {error && <p style={{ color: theme.palette.action.danger }}>{error}</p>}
        <Button
          sx={{ backgroundColor: theme.palette.primary.main + "!important" }}
          color="primary"
          onClick={() => fileUpdate()}
          variant="contained"
        >
          Update
        </Button>
      </div>
    </Wrapper>
  );
}
function ImageCropper({
  showCrop,
  showCroppedImage,
  onCropComplete,
  cropDialogOpen,
  setCropDialogOpen,
  cropImage,
}) {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    showCrop && (
      <Dialog
        fullScreen={fullScreen}
        open={cropDialogOpen}
        fullWidth={true}
        onClose={() => setCropDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          className="App"
          style={{
            padding: "1em",
            width: "auto",
            height: "auto",
          }}
        >
          <div className="crop-container">
            <Cropper
              image={cropImage}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="controls">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              // classes={{ root: "slider" }}
            />
          </div>
          <div style={{ margin: "0 auto", width: "fit-content" }}>
            <Button
              sx={{
                backgroundColor: theme.palette.gray.main + "!important",
                color: theme.palette.gray.dark + "!important",
                width: "180px",
                marginRight: "20px",
              }}
              color="primary"
              onClick={() => setCropDialogOpen(false)}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: theme.palette.primary.main + "!important",
                width: "180px",
              }}
              color="primary"
              onClick={showCroppedImage}
              variant="contained"
            >
              Crop
            </Button>
          </div>
        </div>
      </Dialog>
    )
  );
}
const Wrapper = styled.div`
  position: fixed;
  right: 15px;
  top: 15px;
  bottom: 15px;
  width: ${(props) => (props.show ? "333px" : "0px")};
  box-shadow: -8px 0px 30px rgba(0, 0, 0, 0.13);
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 0.2s ease-out;
  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    border: none;
    outline: none;
    background: transparent;
    height: 30px;
    width: 30px;
    background-color: ${(props) => props.theme.palette.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.2s ease-out;
    svg {
      color: white;
      font-size: 1rem;
    }
    &:hover {
      background-color: ${(props) => props.theme.palette.primary.hover};
      svg {
        color: white;
      }
    }
  }
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    .edit-image {
      border: none;
      outline: none;
      background: transparent;
      color: ${(props) => props.theme.palette.primary.main};
      text-decoration: underline;
      i {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
const inlineStyles = {
  thumbnailImage: {
    width: "150px",
    height: "150px",
    objectFit: "cover",
  },
  textImage: {
    backgroundColor: "green",
    width: "150px",
    height: "150px",
    borderRadius: "60px",
  },
};
export default ProfilePopup;
