export const removeErrors = (REMOVE, state, setState) => {
  let data = { ...state };
  data[REMOVE] = "";
  setState(data);
};
// This is a helper function for selecting only one outlet

// const selectOnlyOne = async (Value) => {
//   try {
//     console.log(Value, "The value");
//     let latesValue = Value.filter((value, i) => {
//       let filterThis = value !== outName[i];
//       return filterThis;
//     });
//     setOutName(
//       typeof value === "string" ? latesValue.split("split") : latesValue
//     );
//     let obj = [];
//     var splitName = latesValue[0].split("split", 3);
//     obj.push({ outletChainID: splitName[0], name: splitName[1] });
//     console.log(obj, "changed outlet");
//     setSelOutlet(obj);
//     // console.log(splitName)
//   } catch (e) {
//     console.log(e, "coming on selectopnly one function");
//   }
// };
// This is for selecting only one
// const selectOnlyOne = async (Value) => {
//   try {
//     let obj = [];

//     if (Value.length === 0) {
//       setOutName([]);
//       setSelOutlet(obj);
//     } else {
//       let latesValue = Value.filter((value, i) => {
//         let filterThis = value !== outName[i];
//         return filterThis;
//       });
//       setOutName(
//         typeof value === "string" ? latesValue.split("split") : latesValue
//       );
//       var splitName = latesValue[0]?.split("split", 3);
//       obj.push({ outletChainID: splitName[0], name: splitName[1] });

//       removeErrors("OutErr", validation, setValidation);

//       setSelOutlet(obj);
//     }
//     // console.log(splitName)
//   } catch (e) {
//     console.log(e, "coming on selectopnly one function");
//   }
// };
