import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
const Searchfield = (props) => {
  return (
    <Mysearch
      {...props}
      placeholder={props.placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={props.Search ? props.Search : null}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
const Mysearch = muiStyled(TextField)(({ width, marginLeft }) => ({
  "& .MuiOutlinedInput-root": {
    border: "1px solid #E2E8F0",
    borderRadius: "5px",
    height: "35px",
    width: width ? width : "100%",
    marginLeft: marginLeft ? marginLeft : "",
  },
  "& .MuiInputLabel-root": {
    top: "-10px !important",
    marginLeft: marginLeft ? marginLeft : "",
  },
}));
export default Searchfield;
