import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

const InputPrimary = ({ label, placeholder, disabled = false, ...props }) => {
  const theme = useTheme();
  return (
    <Container theme={theme} disabled={disabled}>
      <label>{label}</label>
      <input placeholder={placeholder} disabled={disabled} {...props} />
    </Container>
  );
};

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  input {
    display: block;
    width: 100%;
    outline: none;
    border: 1px solid #e2e8f0;
    background: ${(props) =>
      props.disabled ? props.theme.palette.gray.disabled : "transparent"};
    font-size: 0.938rem;
    box-sizing: border-box;
    padding: 12px 16px;
    border-radius: 5px;
  }
`;
export default InputPrimary;
