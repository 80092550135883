import React from "react";
import styled from "@emotion/styled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "../../Style.style";
import { textShrink } from "../../helpers/textShrink";

// onChange: (selectedItems) => { }

function MultipleSelectPrimary({
  label,
  inputLabel,
  options,
  setSelected,
  selected,
  onChange,
}) {
  /**
   * Selected items hold all the selected values from select list
   * Data type: List<Object>
   * {
      label: Select option text value
      value: Select option actual value
   }
   */
  const [filteredItems, setFilteredItems] = React.useState(null);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (options) {
      resetFilterItems();
      setLoading(false);
    }
    console.log("This is loading");
  }, [options, selected]);

  React.useEffect(() => {
    if (!loading) {
      if (filteredItems) {
        if (searchInput !== "") {
          setFilteredItems(
            filteredItems.filter((item) => {
              if (
                item.label.toLowerCase().includes(searchInput.toLowerCase()) ||
                item.value.includes(searchInput)
              ) {
                return { ...item };
              }
            })
          );
        } else {
          resetFilterItems();
        }
      }
    }
  }, [searchInput]);
  const resetFilterItems = () => {
    console.log(options, "Options in components");

    setFilteredItems(
      options.map((option) => {
        if (selected) {
          let _isSelected = selected.find((sel) => sel.value === option.value)
            ? true
            : false;
          return { ...option, selected: _isSelected };
        }
      })
    );
  };
  const handleSelect = (item) => {
    if (selected.find((sel) => sel.value === item.value)) {
      handleRemove(item.value);
    } else {
      setSelected([...selected, item]);
    }
  };
  const handleRemove = (value) => {
    console.log(value, "VALUE");
    setSelected(selected.filter((item) => item.value !== value));
  };
  return (
    <Container>
      <label>{label}</label>
      <div className="select-container">
        <div
          className="select-btn"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          {inputLabel ?? label}{" "}
          <span>
            <ArrowDropDownIcon />
          </span>
        </div>
        {!loading ? (
          <div className={`dropdown-container ${!dropdownOpen && "collapsed"}`}>
            <div className="search-product">
              <input
                type="text"
                placeholder="Search.."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <ul className={`select-options`}>
              {filteredItems &&
                filteredItems.map((item) => (
                  <li
                    key={item.id}
                    className={`option ${item?.selected && "selected"}`}
                    onClick={() => handleSelect(item)}
                  >
                    {item.label}
                  </li>
                ))}
            </ul>
          </div>
        ) : (
          <div
            className={`loading-container dropdown-container ${
              !dropdownOpen && "collapsed"
            }`}
          >
            <CircularProgress />
          </div>
        )}
      </div>
      {!loading && selected.length > 0 && (
        <div className="selected-items">
          {selected.map((item) => (
            <>
              {
                <span
                  className="select-item-tag"
                  data-value={item.value}
                  key={item.value}
                >
                  {textShrink(item.label, 20)}{" "}
                  <button onClick={() => handleRemove(item.value)}>
                    <CloseIcon />
                  </button>
                </span>
              }
            </>
          ))}
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .select-container {
    position: relative;
    .select-btn {
      position: relative;
      display: block;
      width: 100%;
      border: 1px solid #e2e8f0;
      background: transparent;
      font-size: 0.938rem;
      box-sizing: border-box;
      padding: 12px 16px;
      border-radius: 5px;
      cursor: pointer;
      span {
        position: absolute;
        right: 15px;
        top: 60%;
        transform: translateY(-50%);
        svg {
          font-size: 2.5rem;
          color: #747474;
        }
      }
    }
    .loading-container {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown-container {
      position: absolute;
      left: 0px;
      top: 60px;
      background-color: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      z-index: 3;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: max-height 0.3s ease-out;
      /* width */
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b4b4b4a9;
        border-radius: 5px;
        cursor: pointer;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b4b4d5;
        border-radius: 5px;
      }
      .search-product {
        box-sizing: border-box;
        padding: 5px;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #e2e8f0;
          background: transparent;
          font-size: 0.938rem;
          box-sizing: border-box;
          padding: 12px 16px;
          border-radius: 5px;
        }
      }
      .select-options {
        list-style-type: none;
        padding: 0;
        li {
          width: 100%;
          padding: 12px 16px;
          background-color: white;
          transition: background-color 0.2s ease-out;
          cursor: pointer;
          &:hover {
            background-color: #f7f7f7;
          }
        }
        li.selected {
          background-color: ${colors.accent};
          color: white;
        }
      }
    }
    .dropdown-container.collapsed {
      max-height: 0px;
    }
  }
  .selected-items {
    padding: 10px;
    padding-left: 0px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .select-item-tag {
      padding: 8px;
      color: white;
      background-color: ${colors.accent};
      border-radius: 4px;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      button {
        border: none;
        outline: none;
        background: transparent;
        margin: 0;
        padding: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: white;
          font-size: 1rem;
        }
      }
    }
  }
`;

export default MultipleSelectPrimary;
