import React from "react";
import { useDispatch } from "react-redux";
import ChatWindowComponent from "../../components/Chat/ChatWindow";
import SessionComponent from "../../components/Chat/Sessions";
import {
  getMessageSessionThunk,
  resetMessageSessionsAction,
} from "../../redux/slices/messageSessionSlice";
import { useSelector } from "react-redux";
import { selectWholeMessageSessionState } from "../../redux/slices/messageSessionSlice";
import EmptySessionComponent from "../../components/Chat/EmptySession";
import { selectCurrentSessionInfo } from "../../redux/slices/chatSessionSlice";
import SessionDisconnectedComponent from "../../components/Chat/SessionDisconnected";
import { selectIfSocketConnected } from "../../redux/slices/socketSlice";
import { useState } from "react";

const ChatPage = () => {
  const dispatch = useDispatch();
  const conected = useSelector(selectIfSocketConnected);
  const [loaded, setLoaded] = useState(false);
  let AdminData = JSON.parse(localStorage.getItem("Admin"));

  React.useEffect(
    () => {
      dispatch(getMessageSessionThunk({ initialAttempt: true }));
      setLoaded(true);

      return () => dispatch(resetMessageSessionsAction());
    },

    //eslint-disable-next-line
    []
  );
  const { sessions, inProgress, error } = useSelector(
    selectWholeMessageSessionState
  );
  const activeSession = useSelector(selectCurrentSessionInfo);
  console.log("Connected: ", conected);

  if (AdminData[0]?.isSubOutlet) {
    if (!conected && loaded) {
      return <SessionDisconnectedComponent />;
    }
    if (!error && !inProgress && !Object.keys(sessions)?.length && loaded) {
      return <EmptySessionComponent />;
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        gap: "0.5em",
        width: "100%",
        height: "85vh",
        justifyContent: "flex-start",
        backgroundColor: "transparent",
        borderRadius: "14px",
        flexWrap: "wrap",
      }}
    >
      {activeSession && (
        <div
          style={{
            display: "flex",
            width: "50%",
            borderRadius: "14px",
            backgroundColor: "white",
            // overflow: "hidden",
          }}
        >
          <ChatWindowComponent />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          borderRadius: "14px",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <SessionComponent />
      </div>
    </div>
  );
};

export default ChatPage;
