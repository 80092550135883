/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import SelectPrimary from "../../atoms/SelectPrimary";
import Success from "../../assets/Success1.png";
import Failure from "../../assets/failure.png";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";
import Section from "../../atoms/Section";
import MultipleSelectPrimary from "../../atoms/MultipleSelectPrimary";
import {
  FormCustom,
  ItemChip,
  ItemCustom,
  SelectCustom,
} from "../../atoms/CustomMUISelect";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddSpecialCoupon() {
  const history = useHistory();
  const theme = useTheme();
  const [activeStatus, setActiveStatus] = useState(false);
  const [allCoupon, setAllCoupon] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("Percentage");
  const [eventType, setEventType] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [outName, setOutName] = useState([]);
  const [selOutlet, setSelOutlet] = useState([]);
  const [outlets, setOutlets] = useState([]);
  // let appliesTo = "Entire Cart";
  const [appliesTo, setAppliesTo] = useState("Entire Cart");

  const [notificationText, setNotificationText] = useState("");
  const [workType, setWorkType] = useState("");
  let year = new Date().getFullYear();
  const [specialDate, setSpecialDate] = useState();
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selProduct, setSelProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const specialDaysArray = [
    { name: "Nurse", date: `${year}-03-18` },
    { name: "Firefighter", date: `${year}-05-05` },
    { name: "Veterans", date: `${year}-11-11` },
    { name: "Teachers", date: `${year}-03-18` },
  ];

  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (message.text === "Success") {
      history.goBack();
    } else {
      history.push("/Add-SpecialEvent");
      ref.current.close();
    }
  };
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  useEffect(() => {
    GetAllCoupon();
    GetAllOutlets();
  }, []);
  useEffect(() => {
    GetAllProducts();
  }, [selOutlet]);
  useEffect(() => {
    if (products.length > 0) {
      setOptions(
        products.map((item) => ({
          value: item.product.productID,
          label: item.product.name,
        }))
      );
    }
  }, [products]);
  useEffect(() => {
    if (selected.length > 0) {
      setSelProduct(
        selected.map((item) => ({
          productID: item.value,
          name: item.label,
        }))
      );
    }
  }, [selected]);
  const [triedBefore, setTriedBefore] = useState(false);
  const [inProgress, setInProgres] = useState(false);

  const [formValidationErrors, setFormValidationErrors] = useState({});
  useEffect(() => {
    setDiscountValue("");
  }, [couponType]);
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (notificationText.trim().length === 0) {
      data = {
        ...data,
        notificationTextError: "Notification text is required",
      };
    } else {
      let { notificationTextError, ...others } = data;
      data = { ...others };
    }
    // Validate couponCode: couponCode
    if (couponCode.trim().length === 0) {
      data = { ...data, couponCodeError: "Coupon Code is required" };
    } else {
      let { couponCodeError, ...others } = data;
      data = { ...others };
    }
    //Validate EventType: Event type
    if (eventType.trim().length === 0) {
      data = { ...data, eventTypeError: "Event type is required" };
    } else {
      let { eventTypeError, ...others } = data;
      data = { ...others };
    }
    //Validate Work Type: Work type
    if (eventType === "workAnniversary") {
      if (workType.trim().length === 0) {
        data = { ...data, workTypeError: "Work type is required" };
      } else {
        let { workTypeError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { workTypeError, ...others } = data;
      data = { ...others };
    }
    //validate discount value: discountError
    if (couponType === "Percentage") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0 || discountValue >= 100) {
        data = {
          ...data,
          discountError: "Please a choose a postive value within 100",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else if (couponType === "Amount") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0) {
        data = {
          ...data,
          discountError: "Positive discount value is required",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { discountError, ...others } = data;
      data = { ...others };
    }
    console.log(data, "Special Coupon");
    setFormValidationErrors({ ...data });
    return data;
  };
  const GetAllProducts = async () => {
    console.log(selOutlet);
    if (!adminID[0].isSubOutlet) {
      url
        .get(`/inventory/?outletChainID=${selOutlet[0]?.outletChainID}`)
        .then(async (res) => {
          // console.log("Products", res.data.data);
          if (res.data.Message === "Success") {
            setProducts(res.data.data.slice(0, 100));
          } else {
            // alert(res.data.data);
            setProducts([]);
          }
        });
    } else {
      url
        .get(`/inventory/?outletChainID=${adminID[0].outletChainID}`)
        .then(async (res) => {
          // console.log("Products", res.data.data);
          if (res.data.Message === "Success") {
            setProducts(res.data.data.slice(0, 100));
          } else {
            // alert(res.data.data);
            setProducts([]);
          }
        });
    }
  };

  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
  }, [discountValue, couponType, couponCode, triedBefore, appliesTo]);

  const GetAllCoupon = async () => {
    try {
      const res = await url.get(
        `/specialEvents/all/?consumerId=${adminID[0].consumerId}`
      );
      if (res.data.Message === "Success") {
        console.log(res.data.data, "to match");
        setAllCoupon(res.data.data);
      }
    } catch (e) {
      console.log(e, "Error");
    }
  };
  // checking if already exists or not
  const filteredArray = (I) => {
    try {
      let result = allCoupon.find((obj) => obj.eventType === I);
      if (result === undefined) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e, "Error");
    }
  };
  const filteredWorkArray = (I) => {
    try {
      let result = allCoupon.find((obj) => obj.workType === I);
      if (result === undefined) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e, "Error");
    }
  };

  const handleChange = async (e) => {
    if (e !== "") {
      let find = specialDaysArray.find((i) => {
        return i.name === e;
      });
      setWorkType(find.name);
      setSpecialDate(moment(find.date));
    } else {
      setWorkType("");
      setSpecialDate("");
    }
  };
  const addCoupon = () => {
    try {
      if (!adminID[0].isSubOutlet) {
        let formErrors = validateForm();

        if (Object.keys(formErrors).length === 0) {
          setTriedBefore(true);
          setInProgres(true);
          const selectedOne = adminID[0].isSubOutlet
            ? [
                {
                  outletChainID: adminID[0].outletChainID,
                  name: adminID[0].outletName,
                },
              ]
            : selOutlet;
          const admin = JSON.parse(localStorage.getItem("Admin"));
          const body = {
            consumerId: admin[0].consumerId,
            couponCode: couponCode,
            couponType: couponType,
            eventType: eventType,
            selectedOutlets: selectedOne,
            selectedProducts:
              appliesTo === "Selected Products" ? selProduct : [],
            eventDate: specialDate ?? new Date(),
            discountValue: Number.isNaN(+discountValue) ? "0" : discountValue,
            applyToAllOutlets: true,
            entireCart: appliesTo === "Entire Cart" ? true : false,
            workType: workType,
            notificationText,
            status: activeStatus,
            strict: true,
          };

          url
            .put(`/specialEvents/upsert`, body)
            .then(async (res) => {
              setInProgres(false);
              if (res.data.Message === "Success") {
                setMessage({
                  text: "Success",
                  status: true,
                  display: true,
                });
                openTooltip();
              } else {
                setMessage({
                  text: "Failed",
                  status: false,
                  display: true,
                });
                openTooltip();
              }
            })
            .catch((e) => {
              setMessage({
                text: "Failed",
                status: false,
                display: true,
              });
              openTooltip();
            });
        }
      } else {
        setTriedBefore(true);
        let formErrors = validateForm();
        const selectedOne = adminID[0].isSubOutlet
          ? [
              {
                outletChainID: adminID[0].outletChainID,
                name: adminID[0].outletName,
              },
            ]
          : selOutlet;
        if (Object.keys(formErrors).length === 0) {
          setInProgres(true);
          const admin = JSON.parse(localStorage.getItem("Admin"));
          const body = {
            consumerId: admin[0].consumerId,
            couponCode: couponCode,
            couponType: couponType,
            selectedOutlets: selectedOne,
            eventType: eventType,
            eventDate: specialDate ?? new Date(),
            selectedProducts:
              appliesTo === "Selected Products" ? selProduct : [],
            discountValue: Number.isNaN(+discountValue) ? "0" : discountValue,
            applyToAllOutlets: true,
            entireCart: appliesTo === "Entire Cart" ? true : false,
            workType: workType,
            notificationText,
            status: activeStatus,
            strict: true,
          };

          url.put(`/specialEvents/upsert`, body).then(async (res) => {
            setInProgres(false);
            if (res.data.Message === "Success") {
              setMessage({
                text: "Success",
                status: true,
                display: true,
              });
              openTooltip();
            } else {
              setMessage({
                text: "Failed",
                status: true,
                display: true,
              });
              openTooltip();
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const handleCouponCodeInput = (e) => {
    const val = e.target.value;
    // Check if value is letter
    if (/^[a-zA-Z0-9]*$/.test(val)) {
      setCouponCode(val.toUpperCase());
    }
  };
  const selectedOutlets = async (outl) => {
    console.log(outl, "Outiisufbiud");
    setOutName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? outl.split("split") : outl
    );
    let obj = [];
    for (let i = 0; i < outl.length; i++) {
      var splitted = outl[i].split("split", 3);
      obj.push({ outletChainID: splitted[0], name: splitted[1] });
      setSelOutlet(obj);
    }
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card" id="nopad1" style={{ borderRadius: "13px" }}>
            <div>
              {/* <div ></div> */}
              <div
                className="row d-flex justify-content-between"
                style={{
                  borderBottom: "2px solid #F1F5F9",
                  paddingBottom: "10px",
                }}
              >
                <div className="d-flex jusitfy-content-between">
                  <div
                    style={{
                      height: "25px",
                      marginLeft: "-2px",
                      backgroundColor: "#FFA382",
                      marginTop: "10px ",
                    }}
                  >
                    <span style={{ color: "#FFA382" }}>.</span>
                  </div>
                  {/* <div className="d-flex justify-content-between w-75 border"> */}
                  <div
                    className="d-flex"
                    style={{ width: "120px", marginLeft: "10px" }}
                  >
                    <div
                      style={{
                        color: "#FFA382",
                        fontSize: "1.1rem",
                        marginTop: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Special Days
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-7"> </div>
                <div className="col-md-1  d-flex justify-content-end ml-auto">
                  <button
                    className="btn btn-primary border-0 "
                    style={{
                      backgroundColor: "#FFA382",
                      marginBottom: "5px",
                      height: "37px",
                    }}
                    onClick={() => {
                      history.push("/Special-Event");
                    }}
                  >
                    <i className="fas fa-arrow-left"></i>
                    Back
                  </button>
                </div>
                {/* </div> */}
                <br></br>
              </div>
              {!adminID[0].isSubOutlet && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 group-comb mt-3">
                      <div className="">
                        <p
                          className="m-0"
                          style={{
                            color: theme.palette.gray.dark,
                            fontWeight: "600",
                          }}
                        >
                          Select Store
                        </p>
                        <FormCustom>
                          <SelectCustom
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={outName}
                            onChange={(e) => selectedOutlets(e.target.value)}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                style={{ color: "black" }}
                                label="Select Outlet(s)"
                              />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <ItemChip
                                    key={value}
                                    label={`${value.split("split")[1]}`}
                                  />
                                ))}
                              </Box>
                            )}
                            IconComponent={() => <KeyboardArrowDownIcon />}
                            MenuProps={MenuProps}
                          >
                            {outlets.map((outlet, i) => (
                              <ItemCustom
                                key={i}
                                value={
                                  outlet.outletChainID +
                                  "split" +
                                  outlet.outletName
                                }
                                style={getStyles(outlet, outName, theme)}
                              >
                                {outlet.outletName}
                              </ItemCustom>
                            ))}
                          </SelectCustom>
                        </FormCustom>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  color: "#FFA382",
                  fontSize: "1rem",
                  marginTop: "20px",
                  marginBottom: "20px",
                  paddingLeft: "5px",
                  fontWeight: "bold",
                }}
              >
                Basic information
              </div>

              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="group-comb">
                      <label
                        style={{
                          color: "#747474",
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          lineHeight: "1rem",
                          display: "block",
                          marginLeft: "5px",
                        }}
                      >
                        Coupon Code
                      </label>
                      <div>
                        <input
                          type="text"
                          value={couponCode}
                          onChange={handleCouponCodeInput}
                          className="form-control"
                          // id="exampleFormControlInput1"
                          placeholder="Coupon code"
                        />
                        {formValidationErrors.couponCodeError && (
                          <span className="emsg">
                            {formValidationErrors.couponCodeError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      eventType === "workAnniversary" ? "col-md-6" : "col-md-12"
                    }
                  >
                    <div className="group-comb">
                      <label style={{ paddingLeft: "0.4em" }}>Event Type</label>
                      <div>
                        <SelectPrimary
                          value={eventType}
                          onChange={(e) => setEventType(e.target.value)}
                          className="newInputSelect"
                          id="exampleFormControlSelect1"
                        >
                          <option value="">Select Event type</option>

                          {filteredArray("Birthday") ? (
                            <option value="Birthday">Birthday</option>
                          ) : null}
                          {filteredArray("platformAnniversary") ? (
                            <option value="platformAnniversary">
                              Platform Anniversary
                            </option>
                          ) : null}
                          <option value="workAnniversary">Special Day</option>
                        </SelectPrimary>

                        {formValidationErrors.eventTypeError && (
                          <span className="emsg">
                            {formValidationErrors.eventTypeError}
                          </span>
                        )}
                        {/* <input type="text" value={couponType} onChange={(e) => setCouponType(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="discount value" /> */}
                      </div>
                    </div>
                  </div>
                  {eventType === "workAnniversary" && (
                    <div className="col-md-6">
                      <div className="group-comb">
                        <label style={{ paddingLeft: "0.4em" }}>
                          Special Day
                        </label>
                        <div>
                          <SelectPrimary
                            value={workType}
                            onChange={(e) => {
                              handleChange(e.target.value);
                            }}
                            className="newInputSelect"
                            id="exampleFormControlSelect1"
                          >
                            <option value="">Select special day</option>
                            {specialDaysArray.map((i, key) => {
                              return filteredWorkArray(i.name) ? (
                                <option key={key} value={i.name}>
                                  {i.name} Day
                                </option>
                              ) : null;
                            })}
                          </SelectPrimary>

                          {formValidationErrors.workTypeError && (
                            <span className="emsg">
                              {formValidationErrors.workTypeError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {eventType === "workAnniversary" && workType !== "" && (
                    <div className="col-md-12">
                      <div className="alert alert-info alertBox">
                        <span className="orangeColor">
                          * This voucher will be valid for '{" "}
                          {moment(specialDate).format("DD MMMM ")}' only
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="group-comb">
                      <label style={{ paddingLeft: "0.4em" }}>
                        Coupon Type
                      </label>
                      <div>
                        <SelectPrimary
                          onChange={(e) => setCouponType(e.target.value)}
                          className=" newInputSelect"
                          id="exampleFormControlSelect1"
                        >
                          <option value="Percentage">Percentage</option>
                          <option value="Amount">Amount</option>
                        </SelectPrimary>
                      </div>
                    </div>
                  </div>
                  {couponType !== "Free Shiping" && (
                    <div className="col-md-6">
                      <div className="group-comb">
                        <label>
                          {couponType === "Percentage"
                            ? "Discount value %"
                            : "Discount value"}
                        </label>
                        <div>
                          <input
                            type="number"
                            value={discountValue}
                            onKeyPress={(e) => {
                              var charCode =
                                typeof e.which == "undefined"
                                  ? e.keyCode
                                  : e.which;
                              var charStr = String.fromCharCode(charCode);

                              if (!charStr.match(/^[0-9]+$/))
                                e.preventDefault();
                            }}
                            onChange={(e) => {
                              e.preventDefault();
                              if (e.target.value.match(/^[0-9]*$/)) {
                                if (couponType === "Percentage") {
                                  if (Math.abs(e.target.value) <= 99) {
                                    setDiscountValue(
                                      parseInt(Math.abs(e.target.value)) + ""
                                    );
                                  } else {
                                    setDiscountValue("");
                                  }
                                } else {
                                  setDiscountValue(
                                    Math.abs(e.target.value) + ""
                                  );
                                }
                              }
                            }}
                            className="form-control newInputSelect"
                            id="exampleFormControlInpu1"
                            placeholder={
                              couponType === "Percentage" ? "20 %" : "$ 1000"
                            }
                            style={
                              couponType !== "Percentage" &&
                              discountValue !== ""
                                ? {
                                    paddingLeft: "10px",
                                    marginTop: "9px",
                                    height: "9vh",
                                  }
                                : { marginTop: "8.3px", height: "6.9vh" }
                            }
                            min={0}
                            max={couponType === "Percentage" ? 99 : 999999999}
                          />
                          {discountValue !== "" ? (
                            couponType === "Percentage" ? (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "48px",
                                  left: `${
                                    Math.pow((discountValue + "").length, 3) +
                                    40
                                  }px`,
                                }}
                              >
                                %
                              </span>
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "60px",
                                  left: "16px",
                                }}
                              >
                                $
                              </span>
                            )
                          ) : (
                            ""
                          )}
                          {formValidationErrors.discountError && (
                            <span className="emsg">
                              {formValidationErrors.discountError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <div class="alert alert-info alertBox" role="alert">
                      <span className="orangeColor">
                        * Coupon will be applicable for <b>Entire cart</b>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Section label="Applies To">
                      <CustomSelectPrimary
                        label="Applies To"
                        options={[
                          {
                            label: "Selected Products",
                            value: "Selected Products",
                          },
                          { label: "Entire Cart", value: "Entire Cart" },
                        ]}
                        value={appliesTo}
                        setValue={setAppliesTo}
                      />
                      <div>
                        {appliesTo === "Selected Products" && (
                          <MultipleSelectPrimary
                            label="Pick Products"
                            inputLabel="Select Products"
                            options={options}
                            selected={selected}
                            setSelected={setSelected}
                          />
                        )}
                        {formValidationErrors.selectError && (
                          <span className="emsg">
                            {formValidationErrors.selectError}
                          </span>
                        )}
                      </div>
                    </Section>
                  </div>

                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className="orangeColor" style={{ fontSize: "15px" }}>
                      Notification Text
                    </span>
                    <div>
                      <input
                        type="text"
                        value={notificationText}
                        onChange={(e) => setNotificationText(e.target.value)}
                        className="form-control"
                        // style={{ height: "20vh", borderRadius: "25px" }}
                      />
                      {formValidationErrors.notificationTextError && (
                        <span className="emsg">
                          {formValidationErrors.notificationTextError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <span style={{ color: "#FFA382", fontSize: "15px" }}>
                          Status
                        </span>
                      </div>

                      <div className="col-md-6 switch-col">
                        <label className="switch switch-demo">
                          <input
                            type="checkbox"
                            checked={activeStatus}
                            onChange={() => setActiveStatus(!activeStatus)}
                          />
                          <span className="slider round"></span>
                          <span
                            className="slider-text"
                            style={
                              activeStatus
                                ? { paddingLeft: "81px", color: "#FFA382" }
                                : { paddingLeft: "81px", color: "#7C7C7C" }
                            }
                          >
                            {activeStatus ? "Enabled" : "Disabled"}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-end border-0">
            <button
              type="button"
              onClick={() => addCoupon()}
              className="btn btn-cannaby"
              style={{ backgroundColor: "#FFA382" }}
              disabled={inProgress}
            >
              {inProgress ? "Creating..." : "Create Coupon"}
            </button>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <div>
              <img height="60" alt="" src={Success} />
            </div>

            <p className="popup-heading" style={{ marginTop: "20px" }}>
              Created successfully <br />
              <span
                style={{
                  color: "#747474",
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}
              >
                Coupon is created{" "}
              </span>
            </p>
            <button
              className="btn btn-cannaby"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div>
              {" "}
              <img alt="" src={Failure} />
            </div>
            <p style={{ marginTop: "20px" }}>Something Went Wrong</p>
            <button
              type="button"
              onClick={() => {
                closeTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              ok
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
