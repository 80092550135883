import React from "react";
import { colors } from "../../Style.style";

function ToggleSwitch({ status = false, onChange }) {
  return (
    <div className=" switch-col">
      <label className="switch switch-demo">
        <input
          type="checkbox"
          checked={status}
          onChange={() => onChange(!status)}
        />
        <span className="slider round"></span>
        <span
          className="slider-text"
          style={
            status
              ? { paddingLeft: "81px", color: colors.accent }
              : { paddingLeft: "81px", color: "gray" }
          }
        >
          {status ? "Enabled" : "Disabled"}
        </span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
