import React, { forwardRef } from "react";
import Popup from "reactjs-popup";
import Cross from "../../assets/cross.png";
import Failure from "../../assets/failure.png";
import Success from "../../assets/Success1.png";

import styled from "@emotion/styled";

const PopUpDiv = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const PopUp = forwardRef(({ closeTooltip, status, Message, action }, ref) => {
  return (
    <Popup ref={ref} id="latestPopup" className="myPopup" position="center">
      <div style={{ height: "13rem" }}>
        <div style={{ textAlign: "right", marginTop: "-10px" }}>
          <img
            onClick={() => {
              closeTooltip();
            }}
            src={Cross}
          />
        </div>
        <PopUpDiv>
          <div>
            <img height="60" src={status ? Success : Failure} />
          </div>
          <div className="mt-3 mb-3">
            <span
              style={{
                marginTop: "20px",
                color: "#192746",
                fontSize: "18px",
              }}
            >
              {Message}
            </span>
            <p style={{ color: "#747474" }}>{action} </p>
          </div>
          <button
            type="button"
            onClick={() => {
              closeTooltip();
            }}
            className="btn btn-primary border-0"
            style={{
              backgroundColor: "#FFA382",
              marginTop: "0",
              width: "20vh",
              alignSelf: "center",
            }}
          >
            Close
          </button>
        </PopUpDiv>
      </div>
    </Popup>
  );
});
export default PopUp;
