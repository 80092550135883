import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import CountryCodes from "../../countryCodes.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircularProgress from "@mui/material/CircularProgress";

export default function PhoneNumberInputAnik({
  phone,
  setPhone,
  disabled,
  label,
  _Remove = () => {},
  ...props
}) {
  const [countryCode, setCountryCode] = useState(CountryCodes[38]?.dial_code);
  const [localPhone, setLocalPhone] = useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState(null);

  function extractPhoneAndCountryCode() {
    //(+880)1122344555
    let value = String(phone).trim();
    let regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(value);
    if (matches && matches[1]?.length) {
      const check = matches[1].includes("+");
      setCountryCode(check ? `${matches[1]}` : `+${matches[1]}`);
      setLocalPhone(value.substring(matches[0].length));
    } else {
      setLocalPhone(value);
    }
  }
  useEffect(() => {
    resetFilterItems();
    extractPhoneAndCountryCode();
  }, [phone]);
  React.useEffect(() => {
    if (!loading) {
      if (filteredItems) {
        if (searchInput !== "") {
          setFilteredItems(
            filteredItems.filter((item) => {
              if (item.dial_code.includes(searchInput.toLowerCase())) {
                return { ...item };
              }
            })
          );
        } else {
          resetFilterItems();
        }
      }
    }
  }, [searchInput]);
  const resetFilterItems = () => {
    setFilteredItems(CountryCodes);
  };
  const handleChangeEvent = (e) => {
    switch (e.target.dataset.name) {
      case "countryCode":
        setPhone(`(${e.target.value})${localPhone}`);
        break;
      case "localPhone":
        setPhone(`(${countryCode})${String(e.target.value).substring(0, 15)}`);
        break;
      default:
        break;
    }
  };
  return (
    <Container>
      <label>{label ?? "Phone"}</label>
      <div className="input-container">
        <div className="country_code_select">
          <div
            className="select-btn"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {countryCode && `${countryCode}`}
            <span>
              <ArrowDropDownIcon />
            </span>
          </div>
          {!loading ? (
            <div
              className={`dropdown-container ${!dropdownOpen && "collapsed"}`}
            >
              <div className="search-product">
                <input
                  type="text"
                  placeholder="Search.."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              <ul className={`select-options`}>
                {filteredItems
                  ? filteredItems.map((item, index) => (
                      <li
                        data-name="countryCode"
                        value={item.dial_code}
                        key={`${item?.dial_code}-${index}`}
                        onClick={(e) => {
                          handleChangeEvent(e);
                          _Remove();
                        }}
                      >
                        {item.dial_code}
                      </li>
                    ))
                  : CountryCodes.map((item, index) => (
                      <li
                        data-name="countryCode"
                        value={item.dial_code}
                        key={`${item?.dial_code}-${index}`}
                        onClick={(e) => {
                          handleChangeEvent(e);
                          _Remove();
                        }}
                      >
                        {item.dial_code}
                      </li>
                    ))}
              </ul>
            </div>
          ) : (
            <div
              className={`loading-container dropdown-container ${
                !dropdownOpen && "collapsed"
              }`}
            >
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="v-divider"></div>
        <input
          type="text"
          value={localPhone}
          data-name="localPhone"
          onChange={(e) => {
            handleChangeEvent(e);
            _Remove();
          }}
          disabled={disabled}
          {...props}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .input-container {
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    background-color: white;
    /* align-items: center; */
    .country_code_select {
      position: relative;
      width: 110px;
      flex-shrink: 0;
      .select-btn {
        position: relative;
        display: block;
        width: 100%;
        height: 50px;
        /* border: 1px solid #e2e8f0; */
        background: transparent;
        font-size: 0.938rem;
        box-sizing: border-box;
        padding: 12px 16px;
        border-radius: 5px;
        cursor: pointer;
        span {
          position: absolute;
          right: 1px;
          top: 50%;
          transform: translateY(-50%);
          svg {
            font-size: 2.5rem;
            color: #747474;
          }
        }
      }
      .loading-container {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .dropdown-container {
        position: absolute;
        left: 0px;
        top: 60px;
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        z-index: 3;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        transition: max-height 0.3s ease-out;
        /* width */
        &::-webkit-scrollbar {
          width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #b4b4b4a9;
          border-radius: 5px;
          cursor: pointer;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #b4b4b4d5;
          border-radius: 5px;
        }
        .search-product {
          box-sizing: border-box;
          padding: 5px;
          input {
            display: block;
            width: 100%;
            outline: none;
            border: 1px solid #e2e8f0;
            background: transparent;
            font-size: 0.938rem;
            box-sizing: border-box;
            padding: 12px 16px;
            border-radius: 5px;
          }
        }
        .select-options {
          list-style-type: none;
          padding: 0;
          margin-bottom: 0px;
          li {
            width: 100%;
            padding: 12px 16px;
            background-color: white;
            transition: background-color 0.2s ease-out;
            cursor: pointer;
            &:hover {
              background-color: #f7f7f7;
            }
          }
          li.selected {
            background-color: ${(props) => props.theme.palette?.primary?.main};
            color: white;
          }
        }
      }
      select {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border: none;
        outline: none;
        cursor: pointer;
        appearance: none;
        padding-left: 16px;
      }
      svg {
        position: absolute;
        z-index: 2;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
    button {
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: white;
      color: #747474;
      cursor: pointer;
      span {
        color: #ffa382;
      }
    }
    .v-divider {
      width: 1px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        height: 70%;
        width: 100%;
        transform: translateY(-50%);
        background-color: #dbdbdb;
      }
    }
    input {
      width: 1px;
      flex: 1;
      border: none;
      outline: none;
      padding: 7px 15px;
      box-sizing: border-box;
    }
    .dropdown-container.collapsed {
      max-height: 0px;
    }
  }
`;
