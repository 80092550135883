import React, { useRef } from "react";
import "../Discounts/discounts.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import moment from "moment";
import Popup from "reactjs-popup";
import CustomTable from "../../components/CustomTable";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function SpecialCoupon() {
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [outlets, setOutlets] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [outletID, setOutletID] = useState("");

  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  var perPage = 10;
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  //clientSide search
  useEffect(
    () => {
      if (searchTerm?.trim().length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.couponCode
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase())
        );

        if (filtered.length === 0) {
          console.log("here is the error");
          // openNotFoundPopUp();
        }

        setCoupons(filtered);
      } else {
        setCoupons(unfiltered);
        setTotalPage(Math.ceil(unfiltered.length / perPage));
        setCurrentPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  useEffect(
    () => {
      GetAllOutlets();
      if (parsed) {
        GetAllCoupons({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => console.log(e));
  };
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  const ref = useRef();
  const openTooltip = (couponId) => {
    if (!!couponId) {
      setCouponId(couponId);
      ref.current.open();
    }
  };
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllCoupons();
    } else {
      ref.current.close();
    }
  };
  const [couponId, setCouponId] = useState(null);
  const deleteCoupon = () => {
    url.delete(`/specialEvents/?couponId=${couponId}`).then(async (res) => {
      if (res.data.Message === "Success") {
        closeTooltip(true);
      } else {
        openNotFoundPopUp();
      }
    });
  };

  const GetAllCoupons = () => {
    try {
      if (!adminID[0].isSubOutlet) {
        url
          .get(`/specialEvents/all/?consumerId=${adminID[0].consumerId}`)
          .then(async (res) => {
            console.log(res);
            if (res.data.toSend.Message === "Success") {
              console.log(
                res.data.toSend.data?.paginationData,
                "Response from apis"
              );
              let modified = res.data.toSend.data?.data?.reverse() ?? [];
              setUnfiltered(modified);
              setCoupons(modified);
              setPaginationData({
                ...paginationData,
                ...res.data.toSend.data?.paginationData,
              });
              setLoading(false);
            } else {
              console.log("Inelse clause");
              openNotFoundPopUp();
            }
          })
          .catch((e) => console.log(e));
      } else {
        url
          .get(
            `/specialEvents/outlet/?consumerId=${adminID[0].consumerId}&outletChainID=${adminID[0].outletChainID}`
          )
          .then(async (res) => {
            console.log(res);
            if (res.data.toSend.Message === "Success") {
              console.log(
                res.data.toSend.data?.paginationData,
                "Response from apis"
              );
              let modified = res.data.toSend.data?.data?.reverse() ?? [];
              setUnfiltered(modified);
              setCoupons(modified);
              setPaginationData({
                ...paginationData,
                ...res.data.toSend.data?.paginationData,
              });
              setLoading(false);
            } else {
              console.log("Inelse clause");
              openNotFoundPopUp();
            }
          });
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  const GetCouponById = async (outletId) => {
    try {
      setOutletID(outletId);
      const getCoup = await url.get(
        `/specialEvents/outlet/?consumerId=${
          adminID[0].consumerId
        }&${`&outletChainID=${outletId}`}`
      );
      if (getCoup.data.toSend?.Message === "Success") {
        let modified = getCoup.data.toSend.data?.data?.reverse() ?? [];
        setCoupons(modified);
        setUnfiltered(modified);
        setPaginationData({
          ...paginationData,
          ...getCoup.data.toSend.data?.paginationData,
        });
      } else {
        console.log(getCoup.data.toSend, "Failed");
        setCoupons([]);
      }
    } catch (e) {
      console.log("Error in getting coupon by id", e);
    }
  };
  const editCoupon = (data) => {
    localStorage.setItem("specialcouponEdit", JSON.stringify(data));
    history.push("/Edit-SpecialEvent");
  };

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/Special-Event${queryString}`);
    setQueryData({ ...query });
  };
  const checkEvent = (eventType, eventDate) => {
    if (eventType === "platformAnniversary") {
      return "Valid on user's joining date";
    } else if (eventType === "Birthday") {
      return "Valid on user's birthday";
    } else {
      return moment(eventDate).format("D MMM YYYY, h:mm A");
    }
  };
  function backgroundColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "rgb(251, 255, 194)";
        break;
      case "New":
        result = "rgba(108, 149, 255, 0.1)";
        break;
      case "Disabled":
        result = "rgba(255, 109, 109, 0.08)";
        break;
      case "Delivered":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Enabled":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Dispatched":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "#FFC700";
        break;
      case "New":
        result = "blue";
        break;
      case "Disabled":
        result = "#FF6D6D";
        break;
      case "Enabled":
        result = "#0CD600";
        break;
      case "Delivered":
        result = "#0CD600";
        break;
      case "Dispatched":
        result = "#0CD600";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  const Buttonfunction = () => {
    history.push("/Add-SpecialEvent");
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <CustomTable
          title="Special coupon"
          HeaderData={[
            {
              ColumnName: "Event coupon",
              style: {
                textAlign: "left",
                marginLeft: "6px",
              },
            },
            {
              ColumnName: "Day",
              style: {
                textAlign: "left",
              },
            },
            {
              ColumnName: "Value",
              style: {
                textAlign: "left",
              },
            },
            {
              ColumnName: "Valid on",
              style: {
                textAlign: "left",
              },
            },
            {
              ColumnName: "Usage",
              style: {
                textAlign: "center",
              },
            },
            {
              ColumnName: "Status",
              style: {
                textAlign: "center",
              },
            },
            {
              ColumnName: "Action",
              style: {
                textAlign: "center",
              },
            },
          ]}
          RowsStyle={{ justifyContent: "space-around" }}
          outlets={outlets}
          Searchlabel="Search coupon"
          GetDataByOutlet={GetCouponById}
          search={setSearchTerm}
          Buttonfunction={Buttonfunction}
          ButtonTitle="Add Coupon"
          button={true}
          outletID={outletID}
          Alloutlets={true}
        >
          <tbody>
            {coupons.length === 0 ? (
              <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  No record found
                </td>
              </tr>
            ) : (
              coupons
                .slice((currentPage - 1) * perPage, currentPage * perPage)
                .map((coupon, i) => {
                  //   console.log(coupon, 'coupon:');
                  return (
                    <tr key={i} style={{ justifyContent: "space-around" }}>
                      <td style={{ textAlign: "left", marginLeft: "6px" }}>
                        {coupon.couponCode}
                      </td>
                      {coupon.eventType !== "platformAnniversary" ? (
                        <td style={{ textAlign: "left" }}>
                          {coupon.eventType === "workAnniversary"
                            ? coupon.workType + " " + "Day"
                            : coupon.eventType}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left" }}>
                          Platform Anniversary
                        </td>
                      )}
                      <td style={{ textAlign: "left" }}>
                        {coupon.couponType === "Free Shiping"
                          ? "Free Shipping"
                          : coupon.couponType === "Percentage"
                          ? `${coupon.discountValue ?? 0} %`
                          : `$ ${coupon.discountValue ?? 0}`}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {!!coupon.neverExpires
                          ? "Never Expires"
                          : checkEvent(coupon.eventType, coupon.eventDate)}
                      </td>
                      <td className="usage">
                        <span>{coupon.rules?.TotalUsageLimit || "N/A"}</span>
                      </td>

                      <td className={checkColor(coupon.status)}>
                        {/* {coupon.status} */}
                        <div
                          id="coupons"
                          className="newClass"
                          style={{
                            backgroundColor: `${backgroundColor(
                              coupon.status ? "Enabled" : "Disabled"
                            )}`,
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                              fontSize: "13px",
                              paddingTop: "4px",
                              color: checkColor(
                                coupon.status ? "Enabled" : "Disabled"
                              ),
                            }}
                          >
                            {coupon.status ? "Enabled" : "Disabled"}
                          </p>
                        </div>
                      </td>
                      <td style={{ textAlign: "center", paddingTop: "10px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1em",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            className="fas fa-pen orangeColor"
                            onClick={() => {
                              editCoupon(coupon);
                            }}
                          ></i>
                          {/* onClick={() => moveToEdit(product)} */}
                          <i
                            className="fa fa-trash-alt"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              openTooltip(coupon.couponId);
                            }}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </CustomTable>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                shape="rounded"
                className={classes.root}
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage ?? 1}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this coupon ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteCoupon();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Coupons not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
