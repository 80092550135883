/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { useHistory, useLocation } from "react-router-dom";
import storage from "../../config/firebase";
import url from "../../config/axios";

import getCroppedImg from "../../helpers/cropImage";
import isEmail from "email-validator";
import CA from "../../assets/CA.svg";
import US from "../../assets/US.svg";

import data from "../../helpers/countries.json";
import HelpIcon from "@mui/icons-material/Help";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DefaultUserImageSrc from "../../assets/defaultUser.jpg";
import ProfilePopup from "./ProfilePopup";

function AppbarInfo() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);

  const [image, setImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [cropImage, setCropImage] = useState("");
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [selectCountry, setSelectCountry] = useState([]);
  const [phoneData, setPhoneData] = useState([
    {
      phoneCode: "+1",
      flag: US,
      phoneCountry: "US",
      selected: false,
    },
    {
      phoneCode: "+1",
      flag: CA,
      phoneCountry: "CA",
      selected: false,
    },
  ]);
  const history = useHistory();
  const [validationMessages, setvalidationMessages] = React.useState({});
  const [error, setError] = useState("");
  const admin = JSON.parse(localStorage.getItem("Admin")) || null;

  useEffect(() => {
    getAdminDetails();
    getCountryCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validateData = async () => {
    try {
      var re = /^[0-9\b]+$/;
      let data = { ...validationMessages };
      //validate email
      if (email.trim().length === 0) {
        data = { ...data, email: "Email is required" };
      } else if (!isEmail.validate(email)) {
        // console.log(!isEmail.validate(email), !testEmail, "CAHA");
        data = { ...data, email: "Email is not valid" };
      } else {
        let { email, ...others } = data;
        data = { ...others };
      }

      //Validate Phone
      if (phone.split(")")[1] === "") {
        data = { ...data, phone: "Phone is not valid" };
      } else {
        let { phone, ...others } = data;
        data = { ...others };
      }

      //Validate Name
      if (name.split(" ")[0].length <= 3) {
        data = { ...data, name: "Name is not valid" };
      } else {
        let { name, ...others } = data;
        data = { ...others };
      }
      console.log(data, "Validations are here");
      setvalidationMessages({ ...data });

      return data;
    } catch (e) {
      console.log(e, "ERRROR");
    }
  };
  const removeErrors = (REMOVE) => {
    let data = { ...validationMessages };
    data[REMOVE] = "";
    setvalidationMessages(data);
  };
  const getCountryCode = () => {
    let x = selectCountry;
    data.map((e) => {
      return x.push({
        value: e.dial_code,
        label: e.flag + e.dial_code,
        flag: e.flag,
        code: e.code,
        dial_code: e.dial_code,
      });
    });
    setSelectCountry(x);
    // console.log(x)
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          cropImage,
          croppedAreaPixels,
          0
        );
        localStorage.setItem("ImageSet", croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setCropImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const updateAdmin = async (blob) => {
    try {
      const error = await validateData();
      if (Object.keys(error).length === 0) {
        let body = {
          adminName: name,
          phone: phone,
        };
        if (blob) {
          body["adminImage"] = blob;
        }
        console.log(admin[0]);
        if (admin[0].isSubOutlet) {
          url
            .put(`/oc/?outletChainID=${admin[0].outletChainID}`, body)
            .then((res) => {
              if (res.data.Message === "Success") {
                console.log(res.data, "This is the response");
                setName(res.data.data[0].adminName);
                setEmail(res.data.data[0].adminEmail);
                setPhone(res.data.data[0].phone);
                setImage(res.data.data.adminImage);
                setEditAdmin(false);
                setShowProfile(false);
                localStorage.setItem("Admin", JSON.stringify(res.data.data));
              } else if (res.data.Message === "Failure") {
                setError(res.data.data);
              }
            })
            .catch((e) => console.log(e));
        } else {
          console.log(admin[0].adminID, "This is the adminID");
          url
            .put(`/oca/?adminID=${admin[0].adminID}`, body)
            .then((res) => {
              if (res.data.Message === "Success") {
                console.log(res, "Response");
                setName(res.data?.data[0].adminName);
                setEmail(res.data.data[0].adminEmail);

                setPhone(res.data.data[0].phone);

                setImage(res.data.data[0].adminImage);
                setEditAdmin(false);
                setShowProfile(false);
                localStorage.setItem("Admin", JSON.stringify(res.data.data));
              } else if (res.data.Message === "Failure") {
                console.log(res.data.data, "Errors data");
                setError(res.data.data);
              }
            })
            .catch((e) => console.log(e, "errors"));
        }
      }
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  const updateLocalStorage = async (data) => {
    try {
      const prevData = await JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(prevData)) {
        let { media } = prevData[0];
        prevData[0] = { ...data, media };
        // console.log("Prev data is: ", prevData);
        await localStorage.setItem("Admin", JSON.stringify(prevData));
        // window.location.reload();
      }
    } catch (e) {}
  };

  const fileUpdate = () => {
    try {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      if (file !== "") {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const progress = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
          },
          (error) => {
            console.log(error);
          },
          () => {
            storage
              .ref("images")
              .child(file.name)
              .getDownloadURL()
              .then(async (blob) => {
                await updateAdmin(blob);
              });
          }
        );
      } else {
        validateData();
        updateAdmin();
      }
    } catch (e) {
      validateData();
      updateAdmin();
    }
  };

  const getAdminDetails = () => {
    if (admin[0].isSubOutlet) {
      url
        .get(`/oc/id/?outletChainID=${admin[0].outletChainID}`)
        .then((res) => {
          console.log(res, "Sole chain Details");
          if (res.data.Message === "Success") {
            setName(res.data.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhone(res.data.data[0].phone);
            setImage(res.data.data[0].adminImage);
            setEditImage(res.data.data[0].adminImage);
            // setPhoneInput({
            //   countryCode: "+" + res.data.data[0].countryCode,
            //   number: res.data.data[0].phone,
            // });
            // let newArr = phoneData.map((item, i) => {
            //   if (admin[0]?.phoneCountry === item.phoneCountry) {
            //     return { ...item, selected: true };
            //   } else {
            //     return { ...item, selected: false };
            //   }
            // });
            // setPhoneData(newArr);
          }
        })
        .catch((e) => console.log(e));
    } else {
      url
        .get(`/oca/?adminID=${admin[0].adminID}`)
        .then((res) => {
          console.log(res, "Multi admin Details");
          if (res.data.Message === "Success") {
            setName(res.data.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhone(res.data.data[0].phone);

            // setPhoneInput({
            //   countryCode: "+" + res.data.data[0].countryCode,
            //   number: res.data.data[0].phone,
            // });
            // let newArr = phoneData.map((item, i) => {
            //   if (admin[0]?.phoneCountry === item.phoneCountry) {
            //     return { ...item, selected: true };
            //   } else {
            //     return { ...item, selected: false };
            //   }
            // });
            // setPhoneData(newArr);
            setImage(res.data.data[0].adminImage);
            setEditImage(res.data.data[0].adminImage);
          }
        })
        .catch((e) => console.log(e));
    }

    setvalidationMessages({
      name: "",
      email: "",
      phone: "",
    });
  };
  const updateFieldChanged = (event) => {
    // setPhoneCode(event.target.value.split(",")[0])
    // setPhoneCountry(event.target.value.split(",")[1])
    let newArr = phoneData.map((item, i) => {
      if (event.target.value.split(",")[1] === item.phoneCountry) {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    setPhoneData(newArr);
  };
  return (
    <Wrapper>
      <button>
        <HelpIcon />
      </button>
      {admin[0]?.isSubOutlet ? (
        <button onClick={() => history.push("/chat")}>
          <EmailIcon />
        </button>
      ) : null}

      <button onClick={() => history.push("/my-notifications")}>
        <NotificationsIcon />
      </button>
      <div className="profile" onClick={() => setShowProfile(true)}>
        <img src={image ? image : DefaultUserImageSrc} />
        <div className="info">
          <h5>{name}</h5>
          {admin[0]?.isSubOutlet ? "Sole Chain Admin" : "Multi Chain Admin"}
        </div>
      </div>
      {/* Show profile popup */}
      {showProfile && (
        <ProfilePopup
          show={showProfile}
          setShow={setShowProfile}
          admin={admin}
          editAdmin={editAdmin}
          imageSelector={imageSelector}
          editImage={editImage}
          cropProperties={{
            cropDialogOpen: open,
            setCropDialogOpen: setOpen,
            showCrop,
            showCroppedImage,
            onCropComplete,
            editImage,
            cropImage,
          }}
          error={error}
          fileUpdate={fileUpdate}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          validationMessages={validationMessages}
          setShowProfile={setShowProfile}
          removeErrors={removeErrors}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      font-size: 1.5rem;
      color: #a4b0cd;
      &:hover {
        color: #8b96af;
      }
    }
  }
  .profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    img {
      height: 42px;
      width: 42px;
      object-fit: cover;
      border-radius: 50%;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      line-height: 0px;
      padding-bottom: 11px;
      h5 {
        color: #192746;
        font-size: 0.875rem;
      }
      p {
        color: #747474;
      }
    }
  }
`;

export default AppbarInfo;
