import React, { useState } from "react";
import CustomSearch from "../../atoms/CustomSearch/index";
import PopupMenu from "../../atoms/SortfilterPopup/PopupMenu";
import DateRangePopup from "../../atoms/SortfilterPopup/DateRangePopup";
import { IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TuneIcon from "@mui/icons-material/Tune";
// Props for this component ****
// title=> Page title (not optional)
// outlets=> Arrray of outlets for selecting outlets (optional)
// outletId=>For the selected outlet ony if outlets array is being passed
// search=> Search function (Not optional)
// button= > or Button on the right either to navigate somewhere(Optional)
// ButtonTitle=> Only when button is passed
// Rowsstyle=> Styling the row's header, passing styled object (optional)
// HeaderData=> passign the columns name and stylee in this form {ColumnName:String, style:{}} not optinal
// Search label=>Passing the search label for search input (not optional)
// Buttonfunction => Passing the function on button click

const newSpace = {
  margin: "0",
  paddingTop: "2rem",
  height: "100%",
  background: "#fff",
  borderRadius: "5px",
  flexWrap: "wrap",
  width: "100%",
  borderRadius: "15px",
};
const CustomTable = (props) => {
  const [sortPopupMenuAnchor, setSortPopupMenuAnchor] = useState(null);
  const [filterPopupMenuAnchor, setFilterPopupMenuAnchor] = useState(null);
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const handleOpenSortPopupMenu = (event) => {
    setSortPopupMenuAnchor(event.currentTarget);
  };
  const handleOpenFilterPopupMenu = (event) => {
    setFilterPopupMenuAnchor(event.currentTarget);
  };
  return (
    <div className="col-md-12">
      <div className="d-flex " style={newSpace}>
        <div
          style={{
            height: "25px",
            marginLeft: "-2px",
            backgroundColor: "#FFA382",
            marginTop: "5px",
          }}
        >
          <span style={{ color: "#FFA382" }}>.</span>
        </div>
        <div
          style={{
            color: "#FFA382",
            fontSize: "20px",
            fontWeight: "600",
            marginLeft: "8px",
            width: "36%",
          }}
        >
          {props.title}
        </div>

        <div
          className="d-flex justify-content-end"
          style={{ gap: "10px", marginLeft: "80px", width: "50%" }}
        >
          {!admin[0].isSubOutlet && props.outlets && (
            <div className=" d-flex flex-column " style={{ width: "40%" }}>
              <select
                className="newInputSelect w-100"
                style={{
                  height: "35px",
                  border: "1px solid #E2E8F0",
                }}
                onChange={(e) => props.GetDataByOutlet(e.target.value)}
                id="exampleFormControlSelect1"
              >
                <option value="">
                  {" "}
                  {props.Alloutlets ? "All outlets" : "Select an outlet"}
                </option>
                {props.outlets.length !== 0 &&
                  props?.outlets.map((outlet, i) => {
                    return (
                      <option value={outlet.outletChainID} key={i}>
                        {outlet.outletName}
                      </option>
                    );
                  })}
              </select>
              {props.outletID === "" && !props.Alloutlets ? (
                <p className="pl-0" style={{ color: "red" }}>
                  Please select outlet
                </p>
              ) : null}
            </div>
          )}
          <div>
            <CustomSearch
              width={props.width ? props.width : "100%"}
              placeholder={props.Searchlabel}
              onChange={(e) =>
                props.search ? props.search(e.target.value) : null
              }
            />
          </div>
          {props.button && (
            <div>
              <button
                className="btn   globalButton"
                onClick={props.Buttonfunction}
              >
                <i className="fas fa-plus-circle"></i>
                {props.ButtonTitle}
              </button>
            </div>
          )}
          {props.sort && (
            <div className="d-flex">
              <div>
                <IconButton onClick={handleOpenFilterPopupMenu}>
                  <FilterAltIcon />
                </IconButton>
                <DateRangePopup
                  label="Purchased On"
                  anchorEl={filterPopupMenuAnchor}
                  setAnchorEl={setFilterPopupMenuAnchor}
                  fromDate={props.fromDate}
                  setFromDate={props.setFromDate}
                  toDate={props.toDate}
                  setToDate={props.setToDate}
                  sortByTime={props.sortByTime}
                  setSortByTime={props.setSortByTime}
                  customFormat="YYYY-MM-DD"
                />
              </div>
              <div>
                <IconButton onClick={handleOpenSortPopupMenu}>
                  <TuneIcon />
                </IconButton>
                <PopupMenu
                  anchorEl={sortPopupMenuAnchor}
                  setAnchorEl={setSortPopupMenuAnchor}
                  menuItems={[
                    {
                      label: "Sort A to Z",
                      onClick: () => props.setSortByAlpha(1),
                    },
                    {
                      label: "Sort Z to A",
                      onClick: () => props.setSortByAlpha(-1),
                    },
                    {
                      label: "Price: Low to High",
                      onClick: () => props.setSortByPrice(1),
                    },
                    {
                      label: "Price: High to Low",
                      onClick: () => props.setSortByPrice(-1),
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>

        <div className="col-md-12">
          <table className="MyNewTable">
            <thead>
              <tr style={props?.RowsStyle ? props?.RowsStyle : null}>
                {props?.HeaderData.map((item, index) => {
                  return (
                    <th scope="col" style={item.style}>
                      {item.ColumnName}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {props.children}
          </table>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default CustomTable;
