import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Fragment } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import debounce from "../../helpers/debounce";
import url from "../../config/axios";
import Chip from "@mui/material/Chip";
import moment from "moment";
import Box from "@mui/material/Box";
//here disabled is a key value pair of the productId we can't choose
//previousChosen is an array of previosulyChosen products
export const ProductPicker = ({
  promotion,
  single = true,
  handleClose,
  previouslyChoosen = [],
  disabled = {},
  handleDone,
  outletIdCat,
}) => {
  console.log(promotion);
  const getKeyValuePairFromArray = (arr = []) => {
    if (Array.isArray(arr)) {
      let pair = {};
      arr.forEach((item) => {
        if (item.productID) {
          pair[item.productID] = item;
        }
      });
      return pair;
    }
    return {};
  };

  //its an hashmap
  const [pickedProducts, setPickedProducts] = useState(
    getKeyValuePairFromArray(previouslyChoosen)
  );
  const handlePush = (dataToPush) => {
    if (promotion === "DEALS") {
      if (single && dataToPush.dealId) {
        setPickedProducts({
          [dataToPush.dealId]: dataToPush,
        });
      } else {
        setPickedProducts({
          ...pickedProducts,
          [dataToPush.dealId]: dataToPush,
        });
      }
    } else if (promotion === "PRODUCTS") {
      if (single && dataToPush.productID) {
        setPickedProducts({
          [dataToPush.productID]: dataToPush,
        });
      } else {
        setPickedProducts({
          ...pickedProducts,
          [dataToPush.productID]: dataToPush,
        });
      }
    } else if (promotion === "MANUFACTURERS") {
      if (single && dataToPush.brandID) {
        setPickedProducts({
          [dataToPush.brandID]: dataToPush,
        });
      } else {
        setPickedProducts({
          ...pickedProducts,
          [dataToPush.brandID]: dataToPush,
        });
      }
    } else if (promotion === "CATEGORIES") {
      if (single && dataToPush.categoryID) {
        setPickedProducts({
          [dataToPush.categoryID]: dataToPush,
        });
      } else {
        setPickedProducts({
          ...pickedProducts,
          [dataToPush.categoryID]: dataToPush,
        });
      }
    }
  };

  const handleRemoveByKey = (key) => {
    let data = { ...pickedProducts };
    delete data[key];
    setPickedProducts(data);
  };

  const [inventory, setInventory] = useState([]);
  const [unfiltered, setUnfiltered] = useState([]);
  const [deals, setDeals] = useState([]);
  const [brands, setBrands] = useState([]);
  const [category, setCategories] = useState([]);
  const [placeholder, setPlaceHolder] = useState("");
  const [title, settitle] = useState("");
  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  const initialPaginationData = {
    page: 1,
    limit: 500,
    latestFirst: 1,
    search: "",
    outletChainID: outletIdCat,
    consumerId: admin[0]?.consumerId,
  };
  console.log("my outlet id: ", outletIdCat);
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });

  //   const [notFound, setNotfound] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    setQueryData({ ...query });
  };
  useEffect(
    () => {
      if (!loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 500,
    totalPages: 0,
    total: 0,
  });
  //call to fetch data after parsing
  useEffect(
    () => {
      if (promotion === "DEALS") {
        GetAllDeals({ ...queryData });
        setPlaceHolder("Search Deals");
        settitle("Choose Deals");
      } else if (promotion === "PRODUCTS") {
        GetAllProducts({ ...queryData });
        setPlaceHolder("Search Products");
        settitle("Choose Products");
      } else if (promotion === "MANUFACTURERS") {
        GetAllBrands({ ...queryData });
        setPlaceHolder("Search Manufacturers");
        settitle("Choose Manufacturers");
      } else if (promotion === "CATEGORIES") {
        GetAllCategories({ ...queryData });
        setPlaceHolder("Search Categories");
        settitle("Choose Categories");
      }
    },
    //eslint-disable-next-line
    [queryData]
  );
  let num = 0;
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const GetAllBrands = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/brands/paginated`, {
        params: {
          outletChainID: outletIdCat,
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          console.log("Brands are: ", res.data?.data?.brands);
          let countInventoryMap = [];
          res.data.data.brands.map((item) => {
            if (item.countInventory) {
              // category should not visible if countInventory is 0
              console.log("My Outlet ID: ", outletIdCat);
              item.countInventory.map((count) => {
                if (!admin[0].isSubOutlet) {
                  if (count.count > 0 && count.outletChainID === outletIdCat) {
                    countInventoryMap.push(item);
                  }
                } else {
                  if (
                    count.count > 0 &&
                    count.outletChainID === admin[0].outletChainID
                  ) {
                    countInventoryMap.push(item);
                  }
                }
              });
            }
          });
          setBrands(countInventoryMap);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e?.response);
      });
  };
  const GetAllDeals = () => {
    console.log(outletIdCat, "Outletid ");
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v1/deals/paginated-for-admin`, {
        params: {
          outletChainID: outletIdCat,
          ...queryParams,
        },
      })
      .then(async (res) => {
        console.log(res.data.data.data, "Deals");
        if (res.data.Message === "Success") {
          let modified = res.data.data?.data.reverse() ?? [];
          console.log(modified);
          setUnfiltered(modified);
          setInventory(modified);
          setDeals(modified);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
          setLoading(false);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const GetAllCategories = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/categories/paginated`, {
        params: {
          outletChainID: outletIdCat,
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          let countInventoryMap = [];
          res.data.data.categories.map((item) => {
            if (item.countInventory) {
              // category should not visible if countInventory is 0
              console.log("My Outlet ID: ", outletIdCat);
              item.countInventory.map((count) => {
                if (!admin[0].isSubOutlet) {
                  if (count.count > 0 && count.outletChainID === outletIdCat) {
                    countInventoryMap.push(item);
                  }
                } else {
                  if (
                    count.count > 0 &&
                    count.outletChainID === admin[0].outletChainID
                  ) {
                    countInventoryMap.push(item);
                  }
                }
              });
            }
          });
          setCategories(countInventoryMap);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e?.response);
      });
  };

  const GetAllProducts = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/inventories/paginated`, {
        params: {
          outletChainID: outletIdCat,
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (!res.data?.data?.inventories?.length) {
            // setNotfound(true);
          }
          console.log(res.data?.data?.inventories, "Data");
          setInventory(res.data?.data?.inventories);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
          //   console.log("Finished");
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e?.response);
      });
  };
  return (
    <Dialog
      open={true}
      fullScreen={true}
      onClose={() => {
        // if (!inProgress) {
        //   handleClose();
        // }
      }}
    >
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1em 1.5em",
            height: "auto",
            // backgroundColor: "green",
            alignItems: "center",
          }}
        >
          <h3>{title}</h3>
          <CloseIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => {
              handleClose(false);
            }}
          />
        </div>
        <div
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            className="row"
            style={{
              width: "100%",
              paddingBottom: "1em",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <div className="col-md-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control table-search"
                  placeholder={placeholder}
                  aria-label="Input group example"
                  aria-describedby="btnGroupAddon"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="input-group-prepend">
                  <div
                    className="input-group-text table-search-icon"
                    id="btnGroupAddon"
                  >
                    <i className="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-8">
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                }}>
                                {Object.keys(pickedProducts).length ? (
                                    <Fragment>
                                        {Object.keys(pickedProducts).map((value, i) => (
                                            <Chip
                                                key={i}
                                                label={`${pickedProducts[value].product.name}`}
                                                variant="outlined"
                                                onDelete={() => handleRemoveByKey(value)}
                                            />
                                        ))}
                                    </Fragment>
                                ) : (
                                    "No product(s) selected "
                                )}
                            </Box>
                        </div> */}
          </div>
          {loading ? (
            <CircularProgress />
          ) : (
            <div style={{ width: "100%" }}>
              {promotion === "DEALS" ? (
                deals && deals.length ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="Available Products">
                      <TableHead style={{ backgroundColor: "#F8FAFC" }}>
                        <TableRow>
                          <TableCell style={{ color: "#747474" }}>
                            Name
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell style={{ color: "#747474" }}>
                            Status
                          </TableCell>
                          <TableCell
                            style={{ color: "#747474" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {deals.map((element, i) => {
                          console.log(element.dealId);
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {element.name}
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell>
                                {element.status ? "enabled" : "disabled"}
                              </TableCell>
                              <TableCell align="center">
                                <button
                                  style={{
                                    padding: "1em 4em",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    borderRadius: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "1em",
                                    backgroundColor: "white",
                                    color: "#FFA382",
                                    border: "1px solid #FFA382",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                  onClick={() =>
                                    pickedProducts[element.dealId]
                                      ? handleRemoveByKey(element.dealId)
                                      : handlePush(element)
                                  }
                                >
                                  {pickedProducts[element.dealId]
                                    ? "Remove"
                                    : "Choose"}
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    className="row"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    No deals found
                  </div>
                )
              ) : null}

              {promotion === "PRODUCTS" ? (
                inventory && inventory.length ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="Available Products">
                      <TableHead style={{ backgroundColor: "#F8FAFC" }}>
                        <TableRow>
                          <TableCell style={{ color: "#747474" }}>
                            Name
                          </TableCell>
                          <TableCell style={{ color: "#747474" }}>
                            Brand
                          </TableCell>
                          <TableCell style={{ color: "#747474" }}>
                            Supplier
                          </TableCell>
                          <TableCell
                            style={{ color: "#747474" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inventory.map((element, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {element.product.image ? (
                                <div style={{ display: "flex" }}>
                                  <img
                                    alt="yo1"
                                    src={element.product.image}
                                    className="img-fluid "
                                    width="40"
                                  />
                                  {element.product.name}
                                </div>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <div
                                    className="image-thumbnail-div"
                                    style={{
                                      backgroundColor: assignColor(),
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    <span>
                                      {element.product?.name?.substring(0, 1)}
                                    </span>
                                  </div>
                                  <span
                                    style={{
                                      paddingTop: "10px",
                                      color: "#1E293B",
                                    }}
                                  >
                                    {element.product.name}
                                  </span>
                                </div>
                              )}
                            </TableCell>
                            <TableCell>
                              {element?.product?.brandName ?? "Not provided"}
                            </TableCell>
                            <TableCell>
                              {element?.product?.extras?.supplier ??
                                "Not Provided"}
                            </TableCell>
                            <TableCell align="center">
                              <button
                                style={{
                                  padding: "1em 4em",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  borderRadius: "10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "1em",
                                  backgroundColor: "white",
                                  color: "#FFA382",
                                  border: "1px solid #FFA382",
                                }}
                                onClick={() =>
                                  pickedProducts[element?.productID]
                                    ? handleRemoveByKey(element.productID)
                                    : handlePush(element)
                                }
                              >
                                {pickedProducts[element?.productID]
                                  ? "Remove"
                                  : "Choose"}
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    className="row"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    No products found
                  </div>
                )
              ) : null}

              {promotion === "MANUFACTURERS" ? (
                brands && brands.length ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="Available Products">
                      <TableHead style={{ backgroundColor: "#F8FAFC" }}>
                        <TableRow>
                          <TableCell style={{ color: "#747474" }}>
                            Name
                          </TableCell>
                          <TableCell style={{ color: "#747474" }}>
                            Products
                          </TableCell>
                          <TableCell style={{ color: "#747474" }}>
                            Last Modified
                          </TableCell>
                          <TableCell
                            style={{ color: "#747474" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {brands.map((element, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {element.image ? (
                                <div>
                                  <img
                                    alt="yo1"
                                    src={element.image}
                                    className="img-fluid "
                                    width="40"
                                  />
                                  {element.name}
                                </div>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <div
                                    className="image-thumbnail-div"
                                    style={{
                                      backgroundColor: assignColor(),
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    <span>
                                      {element?.name?.substring(0, 1)}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      paddingTop: "10px",
                                      color: "#1E293B",
                                    }}
                                  >
                                    {element.name}
                                  </div>
                                </div>
                              )}
                            </TableCell>
                            <TableCell>
                              {element.countInventory?.length
                                ? element.countInventory[0].count
                                : 0}
                            </TableCell>
                            <TableCell>
                              {moment(element.updatedAt).format(
                                "MMMM Do YYYY, h:mm A"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <button
                                style={{
                                  padding: "1em 4em",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  borderRadius: "10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "1em",
                                  backgroundColor: "white",
                                  color: "#FFA382",
                                  border: "1px solid #FFA382",
                                }}
                                onClick={() => {
                                  console.log(element?.brandID);
                                  pickedProducts[element?.brandID]
                                    ? handleRemoveByKey(element.brandID)
                                    : handlePush(element);
                                }}
                              >
                                {pickedProducts[element?.brandID]
                                  ? "Remove"
                                  : "Choose"}
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    className="row"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    No manufacturers found
                  </div>
                )
              ) : null}

              {promotion === "CATEGORIES" ? (
                category && category.length ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="Available Products">
                      <TableHead style={{ backgroundColor: "#F8FAFC" }}>
                        <TableRow>
                          <TableCell style={{ color: "#747474" }}>
                            Name
                          </TableCell>
                          <TableCell style={{ color: "#747474" }}>
                            Products
                          </TableCell>

                          <TableCell
                            style={{ color: "#747474" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {category.map((element, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {element.image ? (
                                <div style={{ display: "flex" }}>
                                  <img
                                    alt="yo1"
                                    src={element.image}
                                    className="img-fluid "
                                    width="40"
                                  />
                                  {element.name}
                                </div>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <div
                                    className="image-thumbnail-div"
                                    style={{
                                      backgroundColor: assignColor(),
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    <span>
                                      {element?.name?.substring(0, 1)}
                                    </span>
                                  </div>
                                  <span
                                    style={{
                                      paddingTop: "10px",
                                      color: "#1E293B",
                                    }}
                                  >
                                    {element.name}
                                  </span>
                                </div>
                              )}
                            </TableCell>
                            <TableCell>
                              {element?.countInventory[0]?.count}
                            </TableCell>

                            <TableCell align="center">
                              <button
                                style={{
                                  padding: "1em 4em",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  borderRadius: "10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "1em",
                                  backgroundColor: "white",
                                  color: "#FFA382",
                                  border: "1px solid #FFA382",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                onClick={() =>
                                  pickedProducts[element?.categoryID]
                                    ? handleRemoveByKey(element.categoryID)
                                    : handlePush(element)
                                }
                              >
                                {pickedProducts[element?.categoryID]
                                  ? "Remove"
                                  : "Choose"}
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    className="row"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    No categories found
                  </div>
                )
              ) : null}
            </div>
          )}
        </div>

        {!loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "1em 1.5em",
              height: "auto",
              alignItems: "center",
            }}
          >
            {/* <div className="pagination">
              {paginationData.totalPages !== 0 &&
                paginationData.currentPage <= paginationData.totalPages && (
                  <Fragment>
                    <span>
                      {`${
                        paginationData.perPage *
                          (paginationData.currentPage - 1) +
                        1
                      } - ${
                        paginationData.perPage *
                          (paginationData.currentPage - 1) +
                        inventory.length
                      } of ${paginationData.total ?? 0}`}
                    </span>
                    <div
                      className={`btn ${
                        paginationData.currentPage <= 1 ? "" : "cannaby-light"
                      }`}
                      onClick={() => {
                        if (!(paginationData.currentPage <= 1)) {
                          handleChange("page", paginationData.currentPage - 1);
                        }
                      }}
                      role="button"
                    >
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    <div
                      onClick={() => {
                        if (
                          !(
                            paginationData.currentPage >=
                            paginationData.totalPages
                          )
                        ) {
                          handleChange("page", paginationData.currentPage + 1);
                        }
                      }}
                      className={`btn ${
                        paginationData.currentPage >= paginationData.totalPages
                          ? ""
                          : "cannaby-light"
                      }`}
                      role="button"
                    >
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </Fragment>
                )}
            </div> */}
            <button
              style={{
                padding: "1em 4em",
                cursor: "pointer",
                border: "none",
                fontWeight: "bold",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1em",
                backgroundColor: "lightgreen",
              }}
              onClick={() => {
                console.log("Picked ", pickedProducts);
                handleDone(pickedProducts);
              }}
            >
              Done
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
};
