/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import "./order.css";
import "./orderDetails.css";
import url from "../../config/axios";
import PropTypes from "prop-types";
import moment from "moment";
import React, { useState, useRef, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import blank from "../../assets/defaultImage.jpg";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import newUser from "../../assets/newUser.svg";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SimplePopup from "../../atoms/Dialogs/SimplePopup";
export default function OrderDetail() {
  const history = useHistory();
  const [order, setOrder] = useState({});
  const admin = JSON.parse(localStorage.getItem("Admin"));
  console.log(order.status, "Order status");
  const [orderStatus, setOrderStatus] = useState(order.status);
  const [disable, setDisable] = useState(
    order?.status === "Completed" ? true : false
  );
  const ref = useRef();
  console.log(order);
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (message.success) {
      history.goBack();
    } else {
      ref.current.close();
    }
  };
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "Order Updated Successfully",
    heading: "Successfully Updated",
  });
  const [progress, setProgress] = useState(true);
  useEffect(() => {
    fetchOrderDetail();
  }, []);

  const fetchOrderDetail = () => {
    const orderID = JSON.parse(localStorage.getItem("orderID"));
    const ouletId = localStorage.getItem("OrderOutletID");
    const redirectUser = localStorage.getItem("UserRedirect");
    console.log(redirectUser, "Redirect User");
    if (admin[0].isSubOutlet) {
      const orderID = JSON.parse(localStorage.getItem("orderID"));
      url
        .get(`/v1/orders/single?orderID=${orderID}&outletChainID=${ouletId}`)
        .then((res) => {
          setOrder(res?.data?.data?.order);
          setOrderStatus(res?.data?.data?.order?.status);
          setProgress(false);
        })
        .catch((e) => {
          setProgress(false);
        });
    } else {
      url
        .get(`/v1/orders/single?orderID=${orderID}&outletChainID=${ouletId}`)
        .then((res) => {
          setOrder(res?.data?.data?.order);
          setOrderStatus(res?.data?.data?.order?.status);

          setProgress(false);
        })
        .catch((e) => {
          setProgress(false);
        });
    }
  };
  // useEffect(() => {
  //   GetCustomerOrder();
  //   setOrderStatus(order.status);
  //   GetLoyaltyStandards();
  //   GetLoyaltyPoints();
  // }, []);
  // let weekdays = [
  //   "sunday",
  //   "monday",
  //   "tuesday",
  //   "wednesday",
  //   "thursday",
  //   "friday",
  //   "saturday",
  // ];
  // let date = new Date(order.createdAt);
  // console.log(weekdays[date.getDay()], "Day");
  // console.log(date.getHours(), "Hour");

  // const GetLoyaltyStandards = async () => {
  //   await url
  //     .get(`/loyalty?consumerId=${admin[0].consumerId}`)
  //     .then(async (res) => {
  //       // console.log("RESPONSE", res);
  //       if (res.data.Message === "Success") {
  //         setLoyaltyPoints(
  //           res.data.data[weekdays[date.getDay()]][date.getHours()]
  //         );
  //         await setPointsOnFirstOrder(res.data.data.PointsOnFirstOrder);
  //         // console.log(loyaltyPoints, "POINTS");
  //       } else {
  //         // alert(res.data.data);
  //       }
  //     })
  //     .catch((e) => console.error(e));
  // };

  // const GetLoyaltyPoints = async () => {
  //   await url
  //     .get(
  //       `/loyaltyPoints/id?userID=${order.customer.customerID}&outletID=${order.outletID}`
  //     )
  //     .then(async (res) => {
  //       // console.log("LOTY", res);
  //       if (res.data.Message === "Success") {
  //         setCustomerPoints(res.data.data.points);
  //       } else {
  //         let body = {
  //           userID: order.customer.customerID,
  //           points: 0,
  //         };
  //         await url
  //           .post(`/loyaltyPoints?outletID=${admin[0].outletChainID}`, body)
  //           .then(async (res) => {
  //             if (res.data.Message === "Success") {
  //               console.log(res, "LOYALTY POINTS CREATED");
  //             } else {
  //               // alert(res.data.data);
  //             }
  //           });
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  // const GetCustomerOrder = async () => {
  //   await url
  //     .get(`/order/customer/?customerID=${order.customer.customerID}`)
  //     .then((res) => {
  //       // console.log("ORDERS", res);
  //       if (res.data.Message === "Success") {
  //         // console.log(checkData(res.data.data), "HELLO");
  //         if (!checkData(res.data.data)) {
  //           setSingleOrder(true);
  //         }
  //       } else {
  //         // alert(res.data.data);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  // const checkData = (value) => {
  //   let x = false;
  //   value.forEach((item) => {
  //     if (item.status === "Dispatched") {
  //       x = true;
  //     }
  //   });

  //   return x;
  // };

  const updateStatus = async () => {
    url
      .put(`/order/?orderID=${order.orderID}`, { status: orderStatus })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOrderStatus(res.data.data.status);
          setMessage({
            text: "Order updated successfully",
            status: true,
            display: true,
            success: true,
            heading: "Successfully Updated",
          });
          openTooltip();
        } else {
          // console.log(res.data.data);
          closeTooltip();
        }
      })
      .catch((e) => {
        console.error(e);
        closeTooltip();
      });
  };

  const steps = ["New", "Pending", "Complete"];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 5,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#FFA382",
      },
    },
    [`&.${stepConnectorClasses.disabled}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "white",
        borderTop: "2px dotted #eaeaf0",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#FFA382",
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      borderWidth: 0.2,
      borderRadius: 1,
      backgroundColor: theme.palette.mode === "dark" ? " #FFA382" : "#eaeaf0",
      border: 0,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 15,
    height: 15,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#FFA382",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#FFA382",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      ></ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const subTotalPrice = parseFloat(
    !isNaN(order?.totalPrice) ? order.totalPrice : 0
  ).toFixed(2);
  // const discountPrice = parseFloat(
  //   !isNaN(order?.discount) ? order.discount : 0
  // ).toFixed(2);
  const finalPrice = parseFloat(
    !isNaN(order?.finalPrice) ? order.finalPrice : 0
  ).toFixed(2);
  const LoyaltyPointsDiscount = parseFloat(
    !isNaN(order.LoyaltyPointsDiscount) ? order.LoyaltyPointsDiscount : 0
  ).toFixed(2);
  const couponDiscount = parseFloat(
    !isNaN(order?.couponDiscount) ? order.couponDiscount : 0
  ).toFixed(2);

  return (
    <Fragment>
      {progress ? (
        "Loading..."
      ) : (
        <div className="row">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-12">
                <div className="card-new usr-m-b" id="orderDetaills">
                  <div className="row">
                    <div
                      style={{
                        height: "20px",
                        marginLeft: "-15px",
                        backgroundColor: "#FFA382",
                        marginTop: "10px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-4 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          paddingTop: "4px",
                        }}
                      >
                        Order Details
                      </div>
                    </div>

                    <div
                      className="col-md-7"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <select
                        className="form-control"
                        style={{ width: "50%" }}
                        id="exampleFormControlSelect1"
                        value={orderStatus}
                        onChange={(e) => setOrderStatus(e.target.value)}
                        disabled={order.status === "Completed" ? true : false}
                      >
                        <option value="New">New</option>
                        <option value="Pending">Pending</option>
                        <option value="Completed">Completed</option>
                      </select>
                      <button
                        className="btn"
                        style={{
                          marginLeft: "15px",
                          marginRight: "-33px",
                          backgroundColor: "#FFA382",
                          color: "white",
                        }}
                        onClick={() => {
                          updateStatus();
                        }}
                        disabled={order.status === "Completed" ? true : false}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "5px",
                      borderBottom: "2px solid #F1F5F9",
                    }}
                  ></div>
                  <div
                    className="card-history-user order-detail"
                    id="orderDetail"
                  >
                    {/* 3 boxes */}
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid #DEE8FF",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "  #FAFCFF",
                          height: "13vh",
                          textAlign: "center",
                          width: "33.3%",
                          borderRadius: "5px",
                        }}
                      >
                        <h6 style={{ marginTop: "8px" }}>Placed on</h6>
                        <p style={{ marginTop: "3px", fontSize: "12px" }}>
                          {moment(order.createdAt).format(
                            "D MMM YYYY,  h:mm A"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid #DEE8FF",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "  #FAFCFF",
                          height: "13vh",
                          textAlign: "center",
                          width: "180px",
                          borderRadius: "5px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <h6 style={{ marginTop: "8px" }}>Payment Status</h6>
                        <p style={{ fontSize: "12px" }}>{orderStatus}</p>
                      </div>
                      <div
                        style={{
                          border: "1px solid #DEE8FF",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "  #FAFCFF",
                          height: "13vh",
                          textAlign: "center",
                          width: "33.3%",
                          borderRadius: "5px",
                        }}
                      >
                        <h6 style={{ marginTop: "8px" }}>
                          Seller ({order?.customer?.outletName})
                        </h6>
                        <p style={{ fontSize: "12px" }}>{admin[0].address}</p>
                      </div>
                    </div>
                    <div class="border-top my-3"></div>
                    {order.products?.length >= 0 &&
                      order.products.map((single) => {
                        if (+single.quantity <= 0) {
                          return null;
                        }
                        return (
                          <div
                            className="table-mod custom-scrollbar order-data"
                            style={{ borderBottom: "2px solid #F1F5F9 " }}
                          >
                            <div className="row-cust-ac ">
                              <div className="cust-row-ac-img">
                                <img
                                  alt=""
                                  src={blank}
                                  style={{
                                    // paddingRight: "10px",
                                    borderRadius: "10px",
                                  }}
                                  width={60}
                                  height={60}
                                />
                              </div>
                              {/* {
                            console.log("Single is: ", single)
                          } */}
                              <div
                                className="cust-row-ac-body"
                                style={{ color: "#192746" }}
                              >
                                {single?.name ?? single?.product?.name ?? "N/A"}
                              </div>
                              <div className="cust-row-ac-desc">
                                <span style={{ fontSize: "11px" }}>
                                  Brand :
                                  <span className="ac-green">
                                    {" "}
                                    {single?.brandName ??
                                      single?.product?.brandName ??
                                      "N/A"}
                                  </span>
                                </span>
                                <br /> <br />
                              </div>
                              <div
                                className="cust-row-ac-price"
                                style={{ justifyContent: "right" }}
                              >
                                {single?.purchaseQuantity ?? single?.quantity} x
                                $
                                {" " +
                                  parseFloat(
                                    single.discountPrice !== 0
                                      ? single.discountPrice
                                      : single.price
                                      ? single.price
                                      : single?.variant?.price ?? 0
                                  ).toFixed(2) +
                                  " "}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    <br />
                    <div className="row">
                      <div className="col-lg-6"></div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between">
                          <span style={{ color: "#8C8C8C" }}>Subtotal</span>
                          <span style={{ color: "#8C8C8C" }}>
                            $&nbsp;{subTotalPrice}
                          </span>
                        </div>

                        <div class="border-top my-3"></div>
                        <div className="d-flex justify-content-between">
                          <span style={{ color: "#8C8C8C" }}>Discount</span>
                          <span style={{ color: "#8C8C8C" }}>
                            $&nbsp;{LoyaltyPointsDiscount}
                          </span>
                        </div>
                        <div class="border-top my-3"></div>
                        <div className="d-flex justify-content-between">
                          <span style={{ color: "#8C8C8C" }}>
                            Coupon discount
                          </span>
                          <span style={{ color: "#8C8C8C" }}>
                            $&nbsp;{couponDiscount}
                          </span>
                        </div>
                        {order?.additionalCharges?.map((x) => {
                          return (
                            <>
                              <div class="border-top my-3"></div>
                              <div className="d-flex justify-content-between">
                                <span style={{ color: "#8C8C8C" }}>
                                  {x?.name}
                                </span>
                                <span style={{ color: "#8C8C8C" }}>
                                  {x?.amount}{" "}
                                  {x?.chargeType === "PERCENTAGE" ? "%" : "$"}
                                </span>
                              </div>
                            </>
                          );
                        })}
                        <div class="border-top my-3"></div>
                        <div className="d-flex justify-content-between">
                          <span style={{ color: "#192746", fontWeight: "600" }}>
                            Total{" "}
                            {/* <span style={{ color: "#8C8C8C", fontSize: "10px" }}>
                          (incl. VAT)
                        </span> */}
                          </span>

                          <span style={{ color: "#192746", fontWeight: "600" }}>
                            $ &nbsp;{finalPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <div className="card-new usr-m-b">
                  <div className="">
                    <Stepper
                      alternativeLabel
                      activeStep={
                        orderStatus === "New"
                          ? 0
                          : orderStatus === "Pending"
                          ? 1
                          : 2
                      }
                      connector={<ColorlibConnector />}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card-new usr-m-b" id="userBilling">
                  <div
                    className="row d-flex"
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      borderBottom: "2px solid rgba(241, 245, 249, 1)",
                      paddingBottom: "6px",
                    }}
                  >
                    <div
                      style={{
                        height: "21px",
                        marginLeft: "-15px",
                        backgroundColor: "#FFA382",
                        marginTop: "8px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <p
                      style={{
                        fontSize: "18px",
                        marginTop: "4px",
                        marginLeft: "5px",
                        color: "#FFA382",
                      }}
                    >
                      Billing Details
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: "8px",
                    }}
                  >
                    {/*  */}
                    <div style={{ color: "#192746", paddingTop: "7px" }}>
                      <img
                        style={{ paddingRight: "15px", color: "#FFA382" }}
                        src={newUser}
                      />
                      {order?.customer?.name}
                    </div>
                    <div style={{ color: "#192746", paddingTop: "9px" }}>
                      <i
                        className="fa fa-phone-alt"
                        style={{ paddingRight: "15px", color: "#FFA382" }}
                      ></i>
                      {/* +1-245-698-2215 */}
                      {order?.customer?.phone ?? "-"}
                    </div>
                    <div style={{ color: "#192746", paddingTop: "7px" }}>
                      <i
                        className="fa fa-envelope"
                        style={{ paddingRight: "15px", color: "#FFA382" }}
                      ></i>
                      {order.customer?.email}
                    </div>
                    {/*  */}
                  </div>
                  {/* <div>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "4px",
                    color: "#FFA382",
                    paddingTop: "5px",
                  }}>
                  Address
                </p>
                <span style={{ color: "#192746" }}>
                  {order?.customer?.Address?.length
                    ? order?.customer?.Address[0]
                    : "-"}
                </span> */}
                  {/* 36 Meryl Street, Broadway, California 90011 */}
                  {
                    // !!order?.customer?.Address?.length && (
                    //   <div>
                    //     <u style={{ color: "#FFA382" }}>Copy Address</u>
                    //   </div>
                    // )
                  }
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <SimplePopup ref={ref} message={message} close={closeTooltip} />
        </div>
      )}
    </Fragment>
  );
}
